import apiDealerZoneService from "@/services/api/modules/dealerZonesService";

export default {
  setDealerZone({ commit }, zone) {
    commit("SET_DEALER_ZONE", zone);
  },
  async getDealerZones({ commit }, region_types) {
    const { data } = await apiDealerZoneService.getZones(null, null, null, region_types);

    var zones = [];
    let dealer_zones = data.data;
    for (var i = 0; i < dealer_zones.length; i++) {
      zones.push({
        text: dealer_zones[i].name,
        value: dealer_zones[i].id,
      });
    }

    commit("SET_DEALER_ZONES", zones);
  },
};
