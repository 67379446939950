export default {
  SET_SURVEY_CS_ONLINE_SUMMARY: (state, data) => {
    state.survey_cs_online_summary = data;
  },
  SET_SURVEY_CS_ONLINE_DATA: (state, data) => {
    state.survey_cs_online_data = data;
  },

  SET_SURVEY_DCSI_SUMMARY: (state, data) => {
    state.survey_dcsi_summary = data;
  },
  SET_SURVEY_DCSI_ONLINE_DATA: (state, data) => {
    state.survey_dcsi_online_data = data;
  },

  SET_SURVEY_DSSI_SUMMARY: (state, data) => {
    state.survey_dssi_summary = data;
  },
  SET_SURVEY_DSSI_ONLINE_DATA: (state, data) => {
    state.survey_dssi_online_data = data;
  },

  SET_FACTORS: (state, data) => {
    state.survey_factors = data;
  },
  SET_FACTORS_SCORE: (state, data) => {
    state.survey_factors_score = data;
  },

};
