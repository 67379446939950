import api from "@/services/api/baseService";

class apiNewsService {
  list(page, per_page, q, dealer) {
    return api
      .get("/infobip", {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
        params: {
          page,
          per_page,
          q,
          dealer,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  create(infobip_account) {
    return api
      .post("/infobip", infobip_account, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  edit(id) {
    return api
      .get(`/infobip/${id}`, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  update(id, infobip_account) {
    return api
      .put(`/infobip/${id}`, infobip_account, {
        _method: "PUT",
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  delete(id) {
    return api
      .delete(`/infobip/${id}`, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  getActiveDealerInfobipAccount() {
    return api
      .get(`/infobip/active`, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  getInfobipSenderDetails(payload) {
    return api
    .get(`/infobip/sender`, {
      headers: {
        // Authorization: `Bearer ${localStorage.token}`,
      },
      params: { ...payload }
    })
    .then((response) => response)
    .catch((error) => error.response);
  }
}

export default new apiNewsService();
