<template>
  <keep-alive>
    <template v-if="check(code)">
      <slot></slot>
    </template>
    <template v-else>
      <slot name="unpermitted"></slot>
    </template>
  </keep-alive>
</template>

<script>
import $auth from "@/core/Auth/auth";
import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";
import isObject from "lodash/isObject";
import { mapGetters } from "vuex";

export default {
  name: "Can",

  props: ["code"],

  data: () => {
    return {
      role2: $auth.getUser().role,
    };
  },

  computed: {
    ...mapGetters({
      authRole: 'auth/GET_ROLE',
      authRoles: 'auth/GET_ROLES'
    }),
    role: function () {

      if (this.authRoles) {
        return this.authRoles;
      }

      return $auth.getUser().role
    }
  },

  methods: {
    check(roles) {
      if (undefined == roles || roles.length < 1) {
        return true;
      }
      if (!this.role) {
        return false;
      }
      if (this.viewable != undefined && !this.viewable) {
        return $auth.isNotSuperAdmin();
      }

      if (isObject(roles)) {
        let user_roles = this.role.map(a => a.slug);

        let isFound = roles.some(ai => user_roles.includes(ai));

        return isFound;
      }

      return roles === false || includes(this.roles || [], roles);
    },
  },
};
</script>
