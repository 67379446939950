export default [
  {
    name: "dashboard",
    meta: {
      title: "Dashboard",
      sort: 0,
      authenticatable: true,
      description: "Dashboard",
      permission: false,
      icon: "mdi-chart-line",
      roles: ["admin", "dealer"],
    },
  },
  {
    name: "download-reports",
    meta: {
      title: "Download",
      sort: 0,
      authenticatable: true,
      description: "Download Reports",
      permission: false,
      icon: "mdi-table-arrow-down",
      roles: ["admin", "dealer"],
    },
  },
];
