<template>
<div>
    <v-row>
      <v-col cols="12">
        <v-autocomplete
          append-icon="mdi-chevron-down"
          :items="preferredCommunications"
          item-text="text"
          item-value="value"
          label="Preferred Communications"
          outlined
          clearable
          return-object
          hide-details
          clear-icon="mdi-close-circle-outline"
          background-color="selects"
          prepend-inner-icon="mdi-cellphone-link"
          v-model="selectedCommunication"
          @change="emitCommunication"
        >
          <template v-slot:no-data>
            <v-card-text class="pa-2">
              {{ "No data found" }}
            </v-card-text>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
</div>
</template>
<script>
export default {
  props: {
    preferred_communication: {
      type: String,
    },
  },
  mounted() {
    if (this.preferred_communication) {
      this.selectedCommunication = this.preferred_communication;
    }
  },
  data() {
    return {
      preferredCommunications: [
        {
          text: "Email",
          value: "email"
        },
        {
          text: "Mobile",
          value: "mobile"
        },
        {
          text: "Post (letter)",
          value: "post"
        },
        {
          text: "Tel No.",
          value: "telephone"
        },
      ],
      selectedCommunication: ""
    };
  },
  methods: {
    emitCommunication() {
      this.$emit("change:communication", this.selectedCommunication);
    },
  }
}
</script>