export default {
  SET_SURVEY_RESULT_CDC: (state, data) => {
    state.survey_result_cdc = data;
  },
  SET_IMMEDIATE_MEASURES: (state, data) => {
    state.immediate_measures = data;
  },
  SET_FACTORS: (state, data) => {
    state.factors = data;
  },
  SET_ATTRIBUTES: (state, data) => {
    state.attributes = data;
  },
  SET_ROOT_CAUSES: (state, data) => {
    state.root_causes = data;
  },
  SET_DEALER_PREVENTIVE_MEASURES: (state, data) => {
    state.dealer_preventive_measures = data;
  },
};
