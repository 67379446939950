<template v-slot:activator="{ on, attrs }">
  <div v-bind="attrs" v-on="on" no-gutter>
    <v-select
      append-icon="mdi-chevron-down"
      :items="items"
      item-text="text"
      item-value="value"
      label="Dealer Zone"
      outlined
      clearable
      hide-details
      clear-icon="mdi-close-circle-outline"
      background-color="selects"
      v-model="dealerZone"
      @change="updateDealerZone"
    ></v-select>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      dealerZone: null,
    };
  },
  computed: {
    ...mapGetters({
      items: "dealerZone/GET_DEALER_ZONES",
    }),
  },
  methods: {
    ...mapActions({
      setDealerZone: "dealerZone/setDealerZone",
      getDealerZones: "dealerZone/getDealerZones",
    }),
    updateDealerZone() {
      this.setDealerZone(this.dealerZone);
    },
  },
  mounted() {
    this.getDealerZones();
  },
};
</script>

<style></style>
