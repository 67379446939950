<template>
  <svg
    :height="height"
    :width="width"
    id="project-manager"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 380.06 346.61"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="218.95"
        y1="101.08"
        x2="217.41"
        y2="152.43"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.01" />
        <stop offset="0.13" stop-opacity="0.69" />
        <stop offset="1" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-2"
        x1="210.7"
        y1="76.75"
        x2="169.89"
        y2="158.13"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.01" />
        <stop offset="1" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-3"
        x1="253.47"
        y1="242.77"
        x2="247.56"
        y2="146.24"
        xlink:href="#linear-gradient-2"
      />
    </defs>
    <title>Product Manager</title>
    <path
      d="M22.35,203.35v90.34C-1.95,267.19,5.25,233.5,22.35,203.35Z"
      transform="translate(-6.71 -1.8)"
      :fill="iconColor"
      opacity="0.18"
      style="isolation: isolate"
    />
    <path
      d="M384.08,193.36c-5.63,14.26-19.75,22.9-30.74,33.58-22,21.36-32.54,52.41-51.09,76.44a100.73,100.73,0,0,1-14.85,15.71c-21.56,18.14-50.66,24.24-78.67,27.48a262.74,262.74,0,0,1-87.32-4.19A245.6,245.6,0,0,1,44.6,311.06l121.34-68.48,28.51-16.09,1.45-.82V9.56L127.53,39.48a119,119,0,0,1,14.57-16.7C162.48,3.61,195.37-6,218.69,9.49c14,9.24,21.69,25.28,33.35,37.3,23,23.74,58.74,29.61,86.3,47.9A111.22,111.22,0,0,1,385,162.94C387.16,173,387.87,183.79,384.08,193.36Z"
      transform="translate(-6.71 -1.8)"
      :fill="iconColor"
      opacity="0.18"
      style="isolation: isolate"
    />
    <path
      d="M302.25,303.38a100.73,100.73,0,0,1-14.85,15.71c-21.56,18.14-50.66,24.24-78.67,27.48a262.74,262.74,0,0,1-87.32-4.19,22.73,22.73,0,0,1-2.86-10.87c0-25.52,45.86-46.21,102.43-46.21C254.09,285.3,283.53,292.38,302.25,303.38Z"
      transform="translate(-6.71 -1.8)"
      :fill="iconColor"
      opacity="0.18"
      style="isolation: isolate"
    />
    <path
      d="M222.54,86.22a16.47,16.47,0,0,0-15.86,9.43c-3.11,6.93-1.09,15.47-4.71,22.15-1.71,3.15-4.5,5.54-7.16,7.94a1.46,1.46,0,0,0-.5.68c-.14.5.26,1,.64,1.33a26.28,26.28,0,0,0,6.65,4,17.51,17.51,0,0,0,6.94,2.16,18,18,0,0,0,4.37-.63c2.42-.57,3.86,2.33,6.28,1.72a50.59,50.59,0,0,1,7.87-1c2-.13,3.7-3.65,5.68-3.89,4.13-.5,7.74-2.92,11.18-5.27-2.82-1.65-4.34-4.84-5.36-8a45.55,45.55,0,0,1-2.23-14c0-3,.3-6.23-.88-9a9.41,9.41,0,0,0-5.51-5.13C227.51,87.8,225.16,86.42,222.54,86.22Z"
      transform="translate(-6.71 -1.8)"
      fill="#ff979a"
    />
    <path
      d="M232.75,107.15a50.4,50.4,0,0,0-.39-6.39,7.86,7.86,0,0,0-3.19-5.35c-2.45-1.55-5.64-.92-8.4,0a16.58,16.58,0,0,0-3.93,1.72c-3.11,2-4.77,5.7-5.59,9.33s-1,7.38-2,11-3,7.13-6.41,8.7a35.07,35.07,0,0,0-3.85,1.6,1,1,0,0,0-.59.73,1.06,1.06,0,0,0,.3.72c2.08,2.6,5.72,3.28,9,3.56,3.48.3,7,.38,10.47.45,2.77.06,5.64.11,8.21-.94s4.61-3.07,7.24-3.85c1.4-.42,3.81-.57,4.24-2.32.38-1.52-1.32-4.06-1.85-5.42A45.14,45.14,0,0,1,232.75,107.15Z"
      transform="translate(-6.71 -1.8)"
      fill="url(#linear-gradient)"
    />
    <path
      d="M236.88,322.82a11.61,11.61,0,0,0,4.23,3.47,23.58,23.58,0,0,0,10.34,2.11c1.45,0,3-.08,4.09-1.06a3.85,3.85,0,0,0,1-3.56,7.27,7.27,0,0,0-1.84-3.34c-3.59-4-9.1-5.39-14.22-6.14-2.1-.31-4.18-.77-5.09,1.57C234.58,318,235.64,321.08,236.88,322.82Z"
      transform="translate(-6.71 -1.8)"
      fill="#3f3d56"
    />
    <path
      d="M188.94,319a6.62,6.62,0,0,0-1.88,3.08c-.4,2.2,1.56,4.29,3.74,4.78s4.45-.21,6.52-1.08a43.56,43.56,0,0,0,8.55-4.8,7.51,7.51,0,0,0,1.89-1.75c1.16-1.69.89-3.94.51-5.95a1.44,1.44,0,0,0-1.93-1.35c-2,.11-4.08.28-6.12.29a12.84,12.84,0,0,0-3.45.07C193.8,313.08,191,316.78,188.94,319Z"
      transform="translate(-6.71 -1.8)"
      fill="#3f3d56"
    />
    <polygon
      points="189.19 7.76 189.19 223.88 187.74 224.7 159.23 240.78 37.89 309.27 15.64 321.82 15.64 83.72 120.82 37.69 189.19 7.76"
      :fill="iconColor"
      opacity="0.46"
      style="isolation: isolate"
    />
    <polygon
      points="124.77 120.74 106.7 129.46 106.7 100.19 124.77 91.47 124.77 120.74"
      :fill="iconColor"
      opacity="0.56"
      style="isolation: isolate"
    />
    <polygon
      points="154.92 155.59 136.86 164.31 136.86 135.04 154.92 126.32 154.92 155.59"
      :fill="iconColor"
      opacity="0.56"
      style="isolation: isolate"
    />
    <path
      d="M195.39,176.36c-.21,13.44-1,26.91,0,40.31.23,3.29.4,7-.94,9.82l-28.51,16.09a1.89,1.89,0,0,1-2-.19,2,2,0,0,1-.37-1.3c-.47-9.07,1-18.11,2.06-27.13,1.57-13.77,2.07-27.81-.64-41.4a32,32,0,0,0-3.4-10c-2.16-3.7-5.45-6.58-8.31-9.78-4.15-4.66-7.41-10-10.65-15.38-2.26-3.73-14.27-18.56-9.38-22,7.63-5.43,10.38,10.42,11.61,14,1.39,4.06,4.14,7.49,7.07,10.62,7,7.51,15.3,13.73,23.67,19.73a12,12,0,0,0,2.8-5c1.48-4,3-8.26,2.23-12.48a33.31,33.31,0,0,0-2.43-6.69c-2.6-6.29-3.66-13.41-1.77-20s7.05-12.32,13.69-13.8a2.54,2.54,0,0,1,1.59,0c.39.19,2.23-.21,4.15-.62v64.39C195.62,169.24,195.44,172.84,195.39,176.36Z"
      transform="translate(-6.71 -1.8)"
      :fill="iconColor"
      opacity="0.46"
      style="isolation: isolate"
    />
    <path
      d="M210.07,113.89c-.14.94-1.36,2.41-.9,3.25,1,1.73,2.25,2.7,3.21,4.43.72,1.28,2-.19,2.44,1.22a39,39,0,0,1,1.34,7.87c0,1.39-.15,2.9.65,4a3.66,3.66,0,0,0,2.48,1.41,7.28,7.28,0,0,0,2.89-.29,4,4,0,0,0,2.77-1.9,5.11,5.11,0,0,0,.25-2.37,8.88,8.88,0,0,1,.27-3,3.84,3.84,0,0,1,1.83-2.35,7.55,7.55,0,0,1,2-.52,9.26,9.26,0,0,0,5.45-3.06c1.77-2.18,2.15-5.15,2.23-7.95a49.23,49.23,0,0,0-1.11-11.73c-1-4.46-2.58-8.21-6.45-10.63-4.35-2.73-10.39-1.72-14.33,1.58s-6,8.46-6.44,13.58C208.42,109.88,208.28,112.18,210.07,113.89Z"
      transform="translate(-6.71 -1.8)"
      fill="#ffdad2"
    />
    <path
      d="M214.61,116.43a8.41,8.41,0,0,0-1-1.66,2,2,0,0,0-1.17-.82,1.47,1.47,0,0,0-1.45,1,3.72,3.72,0,0,0-.13,1.85,6.65,6.65,0,0,0,.77,2.66,3,3,0,0,0,2.2,1.52,1.34,1.34,0,0,1,.6.11,1,1,0,0,1,.41.74c.18.71.15,1.74-.58,1.84a1.31,1.31,0,0,1-1-.52,21.46,21.46,0,0,1-3.27-3.66,22.12,22.12,0,0,1-2.5-6.69c-.29-1.13-.54-2.44.18-3.36a3.46,3.46,0,0,1,1.26-.9c1.55-.74,5.66-2.59,6.05.14C215.28,111.08,213.45,114,214.61,116.43Z"
      transform="translate(-6.71 -1.8)"
      fill="#ff979a"
    />
    <path
      d="M226.75,101.27a20.39,20.39,0,0,1-9.55,7,14.49,14.49,0,0,1-4,1,10.75,10.75,0,0,0-2.72.29,6.93,6.93,0,0,0-2.69,2.1c-.91-2.28-1.83-4.64-1.73-7.09a15.62,15.62,0,0,1,1.19-4.77c1.23-3.24,2.83-6.52,5.63-8.57a13.61,13.61,0,0,1,7.52-2.36,28.33,28.33,0,0,1,5.81.49c2.06.37,2.44.64,2.78,2.76A12.8,12.8,0,0,1,226.75,101.27Z"
      transform="translate(-6.71 -1.8)"
      fill="#ff979a"
    />
    <path
      d="M242.54,159.46a15.44,15.44,0,0,0-2.31,5.12c-.1.5-.21,1-.31,1.49q-1.3,6.23-2.58,12.44c-.5,2.36-1.12,4.92-3.05,6.36a9.17,9.17,0,0,1-3.62,1.39c-1.17.24-2.36.45-3.55.62a52.15,52.15,0,0,1-18-.64c-1.44-.31-3-.77-3.85-2s-.71-2.84-.84-4.31c-.55-6.34-6.38-12.28-4.16-18.25.64-1.69,1.84-3.68,1.73-5.28a2.24,2.24,0,0,0-.77-1.6,4.41,4.41,0,0,0-2-.86c-5.35-1.32-10.77-2.61-15.74-5a77,77,0,0,1-12.43-8L156.29,130a4.12,4.12,0,0,1-2-2.55,1.37,1.37,0,0,1,0-.35,4.31,4.31,0,0,1,.73-2l.06-.12c.31-.54.63-1.09,1-1.62,1.28-2,2.86-3.8,5.11-4.29,3.29-.71,6.31,1.72,8.92,3.85,6.88,5.59,14.33,11.87,23,13.42a82.85,82.85,0,0,0,15.19.82,2.73,2.73,0,0,0,1.74-.4c.95-.78.51-2.35,1.14-3.4s2.35-1.21,3.73-1.14a50.06,50.06,0,0,0,11.46-.83c-.23-.91,1.14-.49,1.92,0a3.09,3.09,0,0,1,1,2.55,4.92,4.92,0,0,0,.54,2.73,4.56,4.56,0,0,0,2.79,1.42,21,21,0,0,1,8.58,4.33,14.21,14.21,0,0,1,3.4,4.38,12,12,0,0,1,1.18,3.9C246.18,154.21,244.27,156.72,242.54,159.46Z"
      transform="translate(-6.71 -1.8)"
      :fill="iconColor"
    />
    <path
      d="M202.24,156.24l-.25.18a2.24,2.24,0,0,0-.77-1.6,4.41,4.41,0,0,0-2-.86c-5.35-1.32-10.77-2.61-15.74-5a77,77,0,0,1-12.43-8L156.29,130a4.12,4.12,0,0,1-2-2.55,1.37,1.37,0,0,1,0-.35,13.42,13.42,0,0,1,.73-2l.06-.12a1.89,1.89,0,0,1,1-1.62c1.43-.76,5.2,2.21,13.81,10.14,14.89,13.69,29.61,11.47,32.35,15.74S202.24,156.24,202.24,156.24Z"
      transform="translate(-6.71 -1.8)"
      fill="url(#linear-gradient-2)"
    />
    <path
      d="M275.81,186.84a46.12,46.12,0,0,1-1.63,6.21c0,.08-.05.17-.08.26a4.54,4.54,0,0,1-1.21,2.13,4.48,4.48,0,0,1-3.46.63,23.45,23.45,0,0,1-13.93-6.85,46.35,46.35,0,0,1-4-4.85c-4.29-5.77-8.69-11.78-11.61-18.3a38.16,38.16,0,0,1-3.24-11.19c0-.33-.07-.69-.1-1.08-.17-2.5,0-6,2.13-7.32,1.67-1.07,4-.57,5.91.44a9.29,9.29,0,0,1,2.37,1.72c2.28,2.34,3.4,5.55,4.51,8.63,2.08,5.73,4.44,11.43,8.08,16.3s8.69,8.94,14.61,10.33a2.93,2.93,0,0,1,1.2.47A2.6,2.6,0,0,1,275.81,186.84Z"
      transform="translate(-6.71 -1.8)"
      :fill="iconColor"
    />
    <path
      d="M270.31,193.13c0,1.14,1.36,2.58,2.37,2.05.58-.3,1.43-1.91,1.42-1.87a4.54,4.54,0,0,1-1.21,2.13,4.48,4.48,0,0,1-3.46.63,23.45,23.45,0,0,1-13.93-6.85,46.35,46.35,0,0,1-4-4.85c-4.29-5.77-8.69-11.78-11.61-18.3q-1.3,6.23-2.58,12.44c-.5,2.36-1.12,4.92-3.05,6.36a9.17,9.17,0,0,1-3.62,1.39c-1.17.24-2.36.45-3.55.62a3.67,3.67,0,0,1-.74-1.41c-.72-2.68,1.74-5,3.33-7.29,2.26-3.2,3-7.21,3.3-11.12s.26-7.85,1.18-11.65a2.78,2.78,0,0,1,.61-1.31,1.7,1.7,0,0,1,1.78-.3,4.86,4.86,0,0,1,1.6.86,24.56,24.56,0,0,1,4.36,4.8c1.77,2.47,3.29,5.19,5,7.7,4.65,6.85,7.08,12.65,13.24,18.18,2.45,2.2,6.65,4.14,9.49,5.67C270.64,191.21,270.28,192.44,270.31,193.13Z"
      transform="translate(-6.71 -1.8)"
      fill="url(#linear-gradient-3)"
    />
    <path
      d="M160.11,119c-1.77-.1-3.1-1.57-4.23-2.94l-4.59-5.58a7.58,7.58,0,0,0-2.53-2.26.63.63,0,0,0-.46-.07.66.66,0,0,0-.31.43,6.6,6.6,0,0,0-.32,2.45,1.07,1.07,0,0,1-.11.73,1,1,0,0,1-.91.21l-6.26-.66a6,6,0,0,1-3-.57,4.07,4.07,0,0,0-3.47.2c-1.2.88-1.26,2.66-.9,4.11a11.78,11.78,0,0,0,10.24,8.74c2.56.2,5.13-.46,7.68-.14,1.64.21,3.3.82,4.9.39C157.9,123.45,159.12,120.83,160.11,119Z"
      transform="translate(-6.71 -1.8)"
      fill="#ffdad2"
    />
    <path
      d="M209.62,298.68c-.51,2.58-1.4,5.64-4.19,6.09a4.25,4.25,0,0,1-3.48-1.14c-1.94-1.75-2.41-4.29-2.81-6.73-6.13-37.27-5.82-76.27,6.62-111.93,9,0,18.27-.09,27.22-.11a2.69,2.69,0,0,1,3.26,2.44c11.18,37.17,16.73,77,8.38,114.89-.72,3.28-2.3,7.23-5.78,7.27a4.84,4.84,0,0,1-3.55-1.65c-1.8-2-2.16-4.59-2.47-7.12-2.34-19.36-5.07-57.14-11.24-75.64C220.25,225.81,213.2,280.61,209.62,298.68Z"
      transform="translate(-6.71 -1.8)"
      fill="#3f3d56"
    />
    <path
      d="M166.16,265.62"
      transform="translate(-6.71 -1.8)"
      fill="none"
      stroke="#000"
      stroke-miterlimit="10"
      stroke-width="1.44"
    />
    <path
      d="M286,182.27a.44.44,0,0,1,.3.11.39.39,0,0,1,0,.32c-.35,1.53-2.64,1.9-3.11,3.39a.31.31,0,0,0,0,.23.41.41,0,0,0,.29.18c2.1.45,4.23-.42,6.33-.87a31.34,31.34,0,0,1,5-.55,15.74,15.74,0,0,1,3.07,0,1.31,1.31,0,0,1,.76.29,1,1,0,0,1,.15,1,2.66,2.66,0,0,1-.69.87,41.36,41.36,0,0,1-6.76,5.25,12.8,12.8,0,0,1-11.32,1.8c-2.35-.51-4.66-1.25-7-1.54a1.51,1.51,0,0,1-.59-.15,1.14,1.14,0,0,1-.42-.5,3.75,3.75,0,0,1-.1-3.06c.78-2,2-2.77,4-3.31,2.25-.63,4.21-2,6.38-2.82A9.07,9.07,0,0,1,286,182.27Z"
      transform="translate(-6.71 -1.8)"
      fill="#ffdad2"
    />
    <path
      d="M235.79,315.72a6,6,0,0,0,.41,2,5.24,5.24,0,0,0,2.72,2.34,16.89,16.89,0,0,0,7,1.57,2.75,2.75,0,0,0,2.28-.78,2,2,0,0,0,.36-1,4.8,4.8,0,0,0-1.49-3.61c-.91-1-2-1.78-2.92-2.77a9.27,9.27,0,0,1-2.14-4.09,2.79,2.79,0,0,0-.37-1.06,2.15,2.15,0,0,0-.84-.61c-1.37-.63-4.78-1.48-5.63.24-.4.8.17,2.7.26,3.58Z"
      transform="translate(-6.71 -1.8)"
      fill="#ffdad2"
    />
    <path
      d="M196.52,312.85c-.88,1-1.86,2.22-1.54,3.51a2.74,2.74,0,0,0,2.66,1.84,8,8,0,0,0,3.33-.87l3.62-1.53a4.89,4.89,0,0,0,2.64-1.92,4.77,4.77,0,0,0,.4-2l.4-7.21c.05-1,0-2.17-.75-2.79-2.07-1.64-5.35.41-6,2.49A21.75,21.75,0,0,1,196.52,312.85Z"
      transform="translate(-6.71 -1.8)"
      fill="#ffdad2"
    />
    <path
      d="M74.7,173.49c5.35-1.91,8.75-7.33,10.1-12.85s1.08-11.27,1.41-16.94c.16-2.74.57-5.69,2.5-7.63"
      transform="translate(-6.71 -1.8)"
      fill="none"
      stroke="#fff"
      stroke-miterlimit="10"
      stroke-width="0.91"
    />
    <path
      d="M72.07,182l22.17-9.59c6.71-2.91,13.69-5.87,21-5.65"
      transform="translate(-6.71 -1.8)"
      fill="none"
      stroke="#fff"
      stroke-miterlimit="10"
      stroke-width="0.91"
    />
    <path
      d="M71.7,189.78c6.71-2.67,14.71-4.33,20.89-.57,4,2.42,6.42,6.64,9.58,10.07s8,6.24,12.31,4.53"
      transform="translate(-6.71 -1.8)"
      fill="none"
      stroke="#fff"
      stroke-miterlimit="10"
      stroke-width="0.91"
    />
    <path
      d="M106.06,126.93A28.86,28.86,0,0,1,113,116.2"
      transform="translate(-6.71 -1.8)"
      fill="none"
      stroke="#fff"
      stroke-miterlimit="10"
      stroke-width="0.91"
    />
    <path
      d="M132.13,154.81a56.64,56.64,0,0,0,12.65-8.36"
      transform="translate(-6.71 -1.8)"
      fill="none"
      stroke="#fff"
      stroke-miterlimit="10"
      stroke-width="0.91"
    />
    <polygon
      points="127.33 209.05 109.27 217.78 109.27 188.5 127.33 179.78 127.33 209.05"
      :fill="iconColor"
      opacity="0.56"
      style="isolation: isolate"
    />
    <polygon
      points="125.79 171.06 107.73 179.78 107.73 150.5 125.79 141.78 125.79 171.06"
      :fill="iconColor"
      opacity="0.56"
      style="isolation: isolate"
    />
    <polygon
      points="71.28 199.8 41.5 214.18 41.5 165.92 71.28 151.54 71.28 199.8"
      :fill="iconColor"
      opacity="0.56"
      style="isolation: isolate"
    />
    <polygon
      points="99.61 141.79 81.55 150.51 81.55 121.24 99.61 112.52 99.61 141.79"
      :fill="iconColor"
      opacity="0.56"
      style="isolation: isolate"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 300,
    },
    height: {
      type: [Number, String],
      default: 300,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>

<style scoped>
svg {
  max-width: 100%;
}
</style>
