import apiInventoriesService from "@/services/api/modules/inventoriesService";

export default {
  async list({ commit, dispatch }, page = {}) {
    commit("SET_INVENTORIES", []);
    const { status, data } = await apiInventoriesService.list(
      page.page,
      page.per_page,
      page.q,
      page.model
    );
    const errorMessage = data.errors ? Object.values(data.errors).join(" ") : data.message;

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: errorMessage,
        },
        {
          root: true,
        }
      );
    } else {
      let responseData = Object.keys(page).length === 0 ? data.data : data;

      commit("SET_INVENTORIES", responseData);
    }
  },
  async getInventory({ commit, dispatch }, id) {
    commit("SET_INVENTORY", null);
    const { status, data } = await apiInventoriesService.getInventory(id);
    const errorMessage = data.errors ? Object.values(data.errors).join(" ") : data.message;

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: errorMessage,
          timeout: 10000,
        },
        {
          root: true,
        }
      );
    } else {
      commit("SET_INVENTORY", data.data);
    }
  },
  async uploadInventory({ commit, dispatch }, payload) {
    const { status, data } = await apiInventoriesService.upload(payload);
    const message = data.errors ? Object.values(data.errors).join(" ") : data.message;
    dispatch(
      "snackbar/show",
      {
        text: message,
        timeout: 10000,
      },
      {
        root: true,
      }
    );
  },
  async save({ commit, dispatch }, payload) {
    if (payload.id) {
      var { status, data } = await apiInventoriesService.update(payload.data, payload.id);
    } else {
      var { status, data } = await apiInventoriesService.create(payload.data);
    }
    const message = data.errors ? Object.values(data.errors).join(" ") : data.message;
    dispatch(
      "snackbar/show",
      {
        text: message,
      },
      {
        root: true,
      }
    );
    if (status == 200) {
      commit("SET_INVENTORY", data.data[0]);
    }
  },
  async updateInventory({ commit, dispatch }, payload) {
    var { status, data } = await apiInventoriesService.updateInventory(payload);

    const message = data.errors ? Object.values(data.errors).join(" ") : data.message;
    dispatch(
      "snackbar/show",
      {
        text: message,
      },
      {
        root: true,
      }
    );
  },
};
