import moment from "moment";

export function basename(str, sep) {
  return str.substr(str.lastIndexOf(sep) + 1);
}

export function strip_extension(str) {
  return str.substr(0, str.lastIndexOf("."));
}

export function format_price(price) {
  const amount = price ? parseFloat(price) : 0;
  return amount.toLocaleString("en-US", { style: "currency", currency: "PHP" });
}

export function format_date(date, format = "YYYY-MM-DD HH:mm:ss") {
  if (!date) {
    return "";
  }

  return moment(date).format(format);
}

export function convertJsonToCSV(data) {
  const array = [Object.keys(data[0])].concat(data);

  return array
    .map((it) => {
      return Object.values(it).toString();
    })
    .join("\n");
}
