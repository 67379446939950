export default {
  GET_SURVEY: (state) => state.surveys,
  GET_CDC: (state) => state.cdc,
  GET_EMAIL_NOTIFICATION: (state) => state.notifications,
  GET_SURVEY_RESULTS: (state) => state.results,
  GET_SURVEY_RESULT: (state) => state.result,
  GET_SURVEY_CDCS: (state) => state.cdcs,
  GET_SURVEY_CDC: (state) => state.cdc,
  GET_TEXTBLAST_LOGS: (state) => state.textblast_logs,
  GET_SURVEY_CATEGORIES: (state) => state.categories,
  GET_FACTORS_BY_CATEGORY: (state) => state.factors,
};
