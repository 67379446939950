export default [
  {
    name: "quotations",
    meta: {
      title: "Quotations",
      sort: 6,
      authenticatable: true,
      description: "Quotations",
      icon: "mdi-newspaper-variant-outline",
      permission: false,
      roles: ["admin", "dealer"],
    },
  },
];
