export default [
  {
    path: "/admin/dealer-parts/masterlist",
    name: "admin.dealerParts.masterlist",
    redirect: { name: "dealerParts.masterlist.index" },
    component: () => import("@/App.vue"),
    meta: {
      title: "Master List",
      sort: 13,
      authenticatable: false,
      icon: "mdi-format-list-bulleted",
      roles: ["admin_parts"]
    },
    children: [
      {
        path: "/admin/dealer-parts/masterlist",
        name: "dealerParts.masterlist.index",
        component: () => import("../Index.vue"),
        meta: {
          title: "Master List",
          description: "Master List",
          sort: 0,
          authenticatable: true,
          permission: false,
          icon: "mdi-format-list-bulleted",
          roles: ["admin_parts"]
        },
      },
      {
        path: "/admin/dealer-parts/masterlist/upload",
        name: "dealerParts.masterlist.upload",
        component: () => import("../Upload.vue"),
        meta: {
          title: "Master List",
          description: "Master List",
          sort: 0,
          authenticatable: true,
          permission: false,
          icon: "mdi-format-list-bulleted",
          roles: ["admin_parts"]
        },
      }
    ],
  },
];
