import apiNotificationService from "@/services/api/modules/notificationService";

export default {
  async getNotifications({ commit }, payload = {}) {
    commit("SET_NOTIFICATIONS", []);
    const { status, data } = await apiNotificationService.list({
      // page: payload.page,
      // per_page: payload.per_page,
      ...payload
    });

    if (status !== 200) {
      const errorMessage = data.errors ? Object.values(data.errors).join(" ") : data.message;
      dispatch(
        "snackbar/show",
        {
          text: errorMessage,
          timeout: 10000,
        },
        {
          root: true,
        }
      );
    } else {
      let responseData = Object.keys(payload).length === 0 ? data.data : data;
      commit("SET_NOTIFICATIONS", responseData);
    }
  },
  async getUnopenedNotifications({ commit }, payload = {}) {
    commit("SET_UNOPENED_NOTIFICATIONS", []);
    const { status, data } = await apiNotificationService.list({
      page: payload.page,
      per_page: payload.per_page,
      only_unopened: 1,
      sort: 'DESC'
    });

    if (status !== 200) {
      const errorMessage = data.errors ? Object.values(data.errors).join(" ") : data.message;
      dispatch(
        "snackbar/show",
        {
          text: errorMessage,
          timeout: 10000,
        },
        {
          root: true,
        }
      );
    } else {
      let responseData = Object.keys(payload).length === 0 ? data.data : data;
      commit("SET_UNOPENED_NOTIFICATIONS", responseData);
    }
  },
  resetUnopenedNotifications({ commit }) {
    commit("SET_UNOPENED_NOTIFICATIONS", []);
  },
  async bulkOpenNotifications({ commit, state, dispatch }) {
    if (state.unopenedNotifications.length == 0) {
      return;
    }

    let notificationIds = state.unopenedNotifications.map(item => {
      return item.id
    });

    const { status, data } = await apiNotificationService.bulkOpen({
      ids: notificationIds
    })

    if (status !== 200) {
      const errorMessage = data.errors ? Object.values(data.errors).join(" ") : data.message;
      dispatch(
        "snackbar/show",
        {
          text: errorMessage,
          timeout: 10000,
        },
        {
          root: true,
        }
      );
    }

    commit("SET_UNOPENED_NOTIFICATIONS", []);
  }
};
