export default [
  {
    name: "survey",
    meta: {
      title: "Surveys",
      sort: 0,
      authenticatable: true,
      description: "Surveys",
      permission: false,
      icon: "mdi-clipboard-text-outline",
      roles: ["admin", "admin_survey", "dealer_survey"],
    },
  },
  {
    name: "cdcs",
    meta: {
      title: "CDCs",
      sort: 0,
      authenticatable: true,
      description: "CDCs",
      permission: false,
      icon: "mdi-alert",
      roles: ["admin", "admin_survey", "dealer_survey"],
    },
  },
  {
    name: "text-blast.index",
    meta: {
      title: "Text Blasts",
      sort: 1,
      authenticatable: true,
      description: "Text Blasts",
      icon: "mdi-email-outline",
      roles: ["admin", "admin_survey", "dealer_survey"],
    },
  },
  {
    name: "email_notifications",
    meta: {
      title: "Logs",
      sort: 0,
      authenticatable: true,
      description: "Logs",
      permission: false,
      icon: "mdi-email",
      roles: ["admin", "admin_survey"],
    },
  },
  {
    name: "infobip-account.index",
    meta: {
      title: "Infobip",
      sort: 0,
      authenticatable: true,
      description: "Infobip",
      permission: false,
      icon: "mdi-message-settings",
      roles: ["admin", "admin_survey"],
    },
  },
  {
    name: "survey-reports.cs-dashboard",
    meta: {
      title: "CS Dashboard",
      sort: 0,
      authenticatable: true,
      description: "CS Dashboard",
      permission: false,
      icon: "mdi-chart-bar",
      roles: ["admin", "admin_survey", "dealer_survey"],
    },
    children: [
      {
        name: "survey-reports.cs-dashboard",
        meta: {
          title: "Dashboard",
          sort: 0,
          authenticatable: true,
          description: "CS Dashboard",
          permission: false,
          icon: "",
          roles: ["admin", "admin_survey", "dealer_survey"],
        },
      },
      {
        name: "survey-reports.survey-ranking",
        meta: {
          title: "Ranking",
          description: "Survey Ranking",
          sort: 0,
          authenticatable: true,
          permission: false,
          icon: "",
          roles: ["admin", "admin_survey"],
        }
      },
      {
        name: "survey-reports.survey-summary",
        meta: {
          title: "Summary",
          description: "Survey Summary",
          sort: 0,
          authenticatable: true,
          permission: false,
          icon: "",
          roles: ["admin", "admin_survey", "dealer_survey"],
        }
      },
      {
        name: "survey-reports.cdc-summary",
        meta: {
          title: "CDCs",
          description: "CDC Summary",
          sort: 0,
          authenticatable: true,
          permission: false,
          icon: "",
          roles: ["admin", "admin_survey", "dealer_survey"],
        }
      },
      {
        name: "survey-reports.dealer-action-plan",
        meta: {
          title: "Dealer Action Plan",
          description: "Dealer Action Plan",
          sort: 0,
          authenticatable: true,
          permission: false,
          icon: "",
          roles: ["admin", "admin_survey", "dealer_survey"],
        }
      },
    ]
  },
  {
    name: "survey-reports.dcsi.dashboard",
    meta: {
      title: "DCSI Dashboard",
      sort: 0,
      authenticatable: true,
      description: "D-CSI Dashboard",
      permission: false,
      icon: "mdi-chart-line",
      roles: ["admin", "admin_survey", "dealer_survey"],
    },
    children: [
      {
        name: "survey-reports.dcsi.dashboard",
        meta: {
          title: "Dashboard",
          description: "DCSI Dashboard",
          sort: 0,
          authenticatable: true,
          permission: false,
          icon: "inquiries",
          roles: ["admin", "admin_survey", "dealer_survey"],
        }
      },
      {
        name: "survey-reports.dcsi.dealer-scores",
        meta: {
          title: "Ranking",
          description: "Survey Ranking",
          sort: 0,
          authenticatable: true,
          permission: false,
          icon: "inquiries",
          roles: ["admin", "admin_survey"],
        }
      },
      {
        name: "survey-reports.dcsi.factor-scores",
        meta: {
          title: "Summary",
          description: "Survey Summary",
          sort: 0,
          authenticatable: true,
          permission: false,
          icon: "inquiries",
          roles: ["admin", "admin_survey", "dealer_survey"],
        }
      }
    ]
  },
  {
    name: "survey-reports.dssi.dashboard",
    meta: {
      title: "DSSI Dashboard",
      sort: 0,
      authenticatable: true,
      description: "D-SSI Dashboard",
      permission: false,
      icon: "mdi-chart-timeline",
      roles: ["admin", "admin_survey", "dealer_survey"],
    },
    children: [
      {
        name: "survey-reports.dssi.dashboard",
        meta: {
          title: "Dashboard",
          description: "DSSI Dashboard",
          sort: 0,
          authenticatable: true,
          permission: false,
          icon: "",
          roles: ["admin", "admin_survey", "dealer_survey"],
        }
      },
      {
        name: "survey-reports.dssi.dealer-scores",
        meta: {
          title: "Ranking",
          description: "Survey Ranking",
          sort: 0,
          authenticatable: true,
          permission: false,
          icon: "",
          roles: ["admin", "admin_survey"],
        }
      },
      {
        name: "survey-reports.dssi.factor-scores",
        meta: {
          title: "Summary",
          description: "Survey Summary",
          sort: 0,
          authenticatable: true,
          permission: false,
          icon: "",
          roles: ["admin", "admin_survey", "dealer_survey"],
        }
      },
      {
        name: "survey-reports.dssi.cdc-summary",
        meta: {
          title: "CDCs",
          description: "CDC Summary",
          sort: 0,
          authenticatable: true,
          permission: false,
          icon: "",
          roles: ["admin", "admin_survey", "dealer_survey"],
        }
      },
      {
        name: "survey-reports.dssi.dealer-action-plan",
        meta: {
          title: "Dealer Action Plan",
          description: "Dealer Action Plan",
          sort: 0,
          authenticatable: true,
          permission: false,
          icon: "inquiries",
          roles: ["admin", "admin_survey", "dealer_survey"],
        }
      },
    ]
  },
];
