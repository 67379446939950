export default [
  {
    name: "dealers-account-maintenance.dealers.index",
    meta: {
      title: "Dealers",
      sort: 2,
      authenticatable: true,
      description: "Manage dealer's account",
      icon: "mdi-domain",
      permission: false,
      roles: ["admin", "dealer"],
    },
    children: [
      {
        path: "/admin/dealers-account-maintenance/sales-consultants",
        name: "dealers-account-maintenance.sales-consultants.index",
        meta: {
          title: "Sales Consultants",
          sort: 0,
          authenticatable: true,
          description: "Sales Consultants",
          permission: false,
          icon: "mdi-account-multiple-outline",
          roles: ["dealer"],
        },
      },
      {
        name: "dealers-account-maintenance.dealers.index",
        meta: {
          title: "Dealers",
          sort: 0,
          authenticatable: true,
          description: "Dealers",
          permission: false,
          icon: "mdi-domain",
          roles: ["admin"],
        },
      },
      {
        name: "dealer-groups",
        meta: {
          title: "Groups",
          sort: 0,
          authenticatable: true,
          description: "Groups",
          permission: false,
          icon: "mdi-select-group",
          roles: ["admin"],
        },
      },
      {
        name: "dealer-zones",
        meta: {
          title: "Zones",
          sort: 0,
          authenticatable: true,
          description: "Zones",
          permission: false,
          icon: "mdi-map-outline",
          roles: ["admin"],
        },
      },
    ],
  },
];
