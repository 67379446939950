<template v-slot:activator="{ on, attrs }">
  <div no-gutter>
    <p class="mb-2">Select type of report</p>
    <v-select
      append-icon="mdi-chevron-down"
      :items="reportList"
      item-text="text"
      item-value="value"
      label="Report"
      outlined
      clearable
      clear-icon="mdi-close-circle-outline"
      background-color="selects"
      v-model="reportType"
      @change="updateReport"
      :rules="rules"
    ></v-select>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      rules: [(value) => !!value || 'Report type is required'],
      reportType: null,
      reportList: [
        {
          text: "Leads Progress",
          value: "leads",
        },
        {
          text: "Reservation",
          value: "reservations",
        },
        {
          text: "Inquiries",
          value: "inquiries",
        },
        {
          text: "Test Drives",
          value: "test_drives",
        },
        {
          text: "Quotations",
          value: "quotations",
        },
        {
          text: "Brand Assets Usage",
          value: "brand_assets_usage",
        },
        {
          text: "Brand Assets Files",
          value: "brand_assets",
        },
        {
          text: "User Activity",
          value: "user_activities",
        },
        {
          text: "Sales Consultants",
          value: "sales_consultants",
        },
      ],
    };
  },
  methods: {
    ...mapActions("reportType", ["setReportType"]),
    updateReport() {
      this.setReportType(this.reportType);
    },
  },
};
</script>

<style></style>
