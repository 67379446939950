import store from '../../store/index'
import axios from 'axios'
import { getCookie, removeCookie } from '@/core/cookie'

const page = {
  restricted: {
    message: 'Unable to visit page.'
  }
}

export default async function myProfile(to, from, next) {
  const token = getCookie('XSRF-TOKEN')

  return new Promise((resolve) => {
    axios({
      method: 'GET',
      baseURL: process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_VERSION + "/",
      timeout: 250000,
      url: "/validate/me",
      headers: {
        'XSRF-TOKEN': token,
      },
      withCredentials: true
    })
      .then((response) => {
        resolve(response);
        const role = response.data.role[0];
        const roles = response.data.role;

        store.commit('auth/SET_ROLE', role);
        store.commit('auth/SET_ROLES', response.data.role);
        store.commit('auth/SET_USER_TYPE', role.slug);

        if (to.meta.roles) {
          let user_roles = roles.map(a => a.slug);
          let haveRouteAccessRole = to.meta.roles.some(ai => user_roles.includes(ai));

          if (haveRouteAccessRole) {
            next();
          } else {
            console.error(page.restricted.message)
            next({ name: "error.403", query: { from: window.location.pathname } });
          }
        }

        next();
      })
      .catch(error => {
        if (error.response.status === 401) {
          removeCookie("laravel_session");
          removeCookie("XSRF-TOKEN");

          localStorage.removeItem("token");
          localStorage.removeItem("user");

          next({ name: "auth.login" });
        } else {
          next({ name: "error.403", query: { from: window.location.pathname } });
        }
      });
  });
}
