<template v-slot:activator="{ on, attrs }">
  <div v-bind="attrs" v-on="on" no-gutter>
    <v-select
      append-icon="mdi-chevron-down"
      :items="dealerList"
      item-text="text"
      item-value="value"
      label="Dealers"
      outlined
      clearable
      hide-details
      multiple
      clear-icon="mdi-close-circle-outline"
      background-color="selects"
      v-model="dealer"
      @change="updateDealer"
      :disabled="isDealerDisable"
    >
    
      <template v-slot:prepend-item>
        <v-list-item ripple @mousedown.prevent @click="toggle">
          <v-list-item-action>
            <v-icon :color="dealer.length > 0 ? 'indigo darken-4' : ''">
              {{ icon }}
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title> SELECT ALL </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-2"></v-divider>
      </template>
      <template v-slot:selection="{ item, index }">
        <span v-if="index === 0">{{ item.text }},&nbsp;</span>
        <span v-if="index === 1" class="grey--text text-caption">
          (+{{ dealer.length - 1 }} others)
        </span>
      </template>
    </v-select>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { map } from "lodash";
export default {
  props: {
    disableDealer: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    }
  },
  async mounted() {
    this.isDealerDisable = this.disableDealer;
    await this.getDealers();
  },
  watch: {
    disableDealer: {
      immediate: true,
      handler(val) {
        this.isDealerDisable = val;
      },
    },
  },
  data() {
    return {
      dealer: [],
      isDealerDisable: false,
    };
  },
  computed: {
    ...mapGetters({
      dealerList: "dealer/GET_DEALERS",
    }),
    allDealersSelected() {
      return this.dealer.length === this.dealerList.length;
    },
    icon() {
      if (this.allDealersSelected) return "mdi-close-box";
      if (this.someDealersSelected) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    someDealersSelected() {
      return this.dealer.length > 0 && !this.allDealersSelected;
    },
  },
  methods: {
    ...mapActions({
      getDealers: "dealer/getDealers",
      setDealer: "dealer/setDealer",
    }),
    async updateDealer() {
      await this.setDealer(this.dealer);
      this.$emit("filter:dealer");
      this.$emit("update:dealer");
    },
    toggle() {
      this.$nextTick(async () => {
        if (this.allDealersSelected) {
          this.dealer = [];
        } else {
          this.dealer = this.dealerList.slice();
        }
        await this.setDealer(map(this.dealer, 'value'));
        this.$emit("filter:dealer");
        this.$emit("update:dealer");
      });
    },
  },
};
</script>

<style></style>
