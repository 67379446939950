<template>
  <v-app-bar
    app
    class="workspace elevation-1"
    :hide-on-scroll="$vuetify.breakpoint.mdAndUp"
    :clipped-left="sidebar.clipped"
    v-if="appbar.model"
    :height="$vuetify.breakpoint.mdAndUp ? 83 : null"
  >
    <v-badge
      bordered
      bottom
      class="dt-badge"
      color="dark"
      content="k"
      offset-x="20"
      offset-y="20"
      tile
      transition="fade-transition"
      v-model="$store.getters['shortkey/ctrlIsPressed']"
    >
      <v-app-bar-nav-icon
        color="muted"
        @click="toggle({ model: !sidebar.model })"
        v-shortkey.once="['ctrl', 'k']"
        @shortkey="toggle({ model: !sidebar.model })"
      ></v-app-bar-nav-icon>
    </v-badge>

    <slot>
      <v-spacer></v-spacer>
      <div class="mr-3">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-badge
              bordered
              color="error"
              offset-x="22"
              offset-y="20"
              :value="unopenedNotifications.length"
              :content="unopenedNotifications.length"
            >
              <v-btn
                depressed
                v-bind="attrs"
                v-on="on"
                icon
                dense
                style="border: none; box-shadow: none"
                :loading="notificationLoading"
                @click="bulkOpenNotifications"
              >
                <v-icon>mdi-bell</v-icon>
              </v-btn>
            </v-badge>
          </template>
          <v-list>
            <template v-if="notifications.length">
              <div style="height: 200px; overflow-y: scroll">
                <p class="px-5 mt-2 mb-0"><strong>Notifications</strong></p>
                <v-list-item
                  v-for="(notification, index) in notifications"
                  :key="index"
                  :attrs="[notification.title ? 'two-line' : 'one-line']"
                >
                  <v-list-item-content
                    style="width: 250px"
                    class="cursor-pointer pb-0"
                    @click="openNotification(notification.link)"
                  >
                    <v-list-item-title>
                      <strong><small>{{ notification.title }}</small></strong>
                    </v-list-item-title>
                    <v-list-item-subtitle class="mb-3">
                      <small>{{ notification.message }}</small> <br />
                      <small style="font-size: 12px;">{{ notification.created_at }}</small>
                    </v-list-item-subtitle>
                    <v-divider></v-divider>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </template>
            <template v-else>
              <div class="text-center pa-5">
                <div class="text-center">
                  <v-icon color="red" right>mdi-alert-box</v-icon>
                </div>
                <strong>
                  <small>Notification box is empty</small>
                </strong>
              </div>
            </template>
            <v-list-item>
              <v-list-item-content>
                <v-btn
                  class="ma-1"
                  color="grey"
                  text
                  small
                  :to="{ name: 'notifications' }"
                >
                  <small>View all notifications</small>
                </v-btn>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <v-menu
        class="justify-end d-flex"
        min-width="200px"
        transition="slide-y-transition"
        offset-y
        nudge-bottom
      >
        <template v-slot:activator="{ on: menu }">
          <div v-on="{ ...menu }" role="button">
            <div class="d-flex justify-space-between align-center">
              <div>
                <can :code="['admin', 'admin_parts', 'admin_survey']">
                  <h4 v-if="user !== null">
                    {{ user.first_name + " " + user.last_name }}
                  </h4>
                </can>
                <can :code="['dealer', 'dealer_parts', 'dealer_survey']">
                  <!-- <span>{{ user.dealer ? user.dealer.name : "" }}</span> -->
                  <h4 v-if="user !== null">
                    {{
                      user.dealer
                        ? user.dealer.name
                        : user.first_name + " " + user.last_name
                    }}
                  </h4>
                </can>
              </div>
              <div class="text-center">
                <v-icon small right>mdi-chevron-down</v-icon>
              </div>
            </div>
          </div>
        </template>

        <v-list max-width="250">
          <v-list-item :to="{ name: 'auth.profile' }" exact>
            <v-list-item-action>
              <v-icon small class="text--muted">mdi-account-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-text="trans('Profile')"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- <v-list-item :to="{ name: 'settings.general' }" exact>
            <v-list-item-action>
              <v-icon small>mdi-tune</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-text="trans('Settings')"></v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->

          <v-list-item exact @click="logout">
            <v-list-item-action>
              <v-icon small>mdi-power</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-text="trans('Logout')"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </slot>
  </v-app-bar>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import * as helpers from "@/core/helpers";

export default {
  name: "Appbar",
  data() {
    return {
      user: null,
      notifications: [],
      notificationLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      appbar: "appbar/appbar",
      sidebar: "sidebar/sidebar",
      err: "auth/GET_ERR",
      unopenedNotifications: "notifications/GET_UNOPENED_NOTIFICATIONS",
    }),
  },
  created() {
    if (!localStorage.user) {
      this.$router.push({ name: "auth.login" });
    } else {
      this.user = JSON.parse(localStorage.user);
    }

    this.getNotifications();
  },
  methods: {
    ...mapActions({
      toggle: "sidebar/toggle",
      snackbarShow: "snackbar/show",
      authLogout: "auth/logout",
      getUnopenedNotifications: "notifications/getUnopenedNotifications",
      resetUnopenedNotifications: "notifications/resetUnopenedNotifications",
      bulkOpenNotifications: "notifications/bulkOpenNotifications",
    }),
    formatDate(date) {
      return helpers.format_date(date);
    },
    openNotification(link) {
      if (link) {
        location.href = link;
        return false;
      }
    },
    async logout() {
      await this.authLogout();

      if (this.err.message) {
        this.snackbarShow({
          text: this.err.message,
        });
      } else {
        this.$router.push({ name: "auth.login" });
      }
    },
    async getNotifications() {
      this.notificationLoading = true;

      if (this.unopenedNotifications.length == 0) {
        this.getUnopenedNotifications().then(() => {
          this.notifications = this.unopenedNotifications;
          this.notificationLoading = false;
        });
      } else {
        this.notificationLoading = false;
      }
    },
  },
};
</script>
