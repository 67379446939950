import api from "@/services/api/baseService";

class apiDealerGroupsService {
  getGroups(page, per_page, q, region_types = [], zones = []) {
    console.log('zones', zones);
    return api
      .get("/dealer/groups", {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
        params: {
          per_page,
          page,
          q,
          region_types,
          zones,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  getGroup(id) {
    return api
      .get(`/dealer/groups/${id}`, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  create(data) {
    return api
      .post(`/dealer/groups`, data, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  update(id, data) {
    return api
      .put(`/dealer/groups/${id}`, data, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  delete(id) {
    return api
      .delete(`/dealer/groups/${id}`, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
}

export default new apiDealerGroupsService();
