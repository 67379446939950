export default {
  SET_INFOBIP_ACCOUNTS: (state, data) => {
    state.infobip_accounts = data;
  },
  SET_INFOBIP_ACCOUNT: (state, data) => {
    state.infobip_account = data;
  },
  SET_ACTIVE_INFOBIP_ACCOUNT: (state, data) => {
    state.active_infobip_account = data;
  },
};
