import api from "@/services/api/baseService";

class apiSalesConsultantsService {
  list(payload) {
    return api
      .get("/sales-consultants", {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
        params: payload,
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  getSalesConsultant(id) {
    return api
      .get(`/sales-consultants/${id}`, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
}

export default new apiSalesConsultantsService();
