import api from "@/services/api/baseService";

class apiSurveySmsBlastService {
  list(payload) {
    return api
      .get("/survey-sms-blasts", {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
        params: { ...payload },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  getSurveySmsBlast(id) {
    return api
      .get(`/survey-sms-blasts/${id}`, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  create(payload) {
    return api
      .post("/survey-sms-blasts", payload, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  update(payload, id) {
    return api
      .put(`/survey-sms-blasts/${id}`, payload, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  updateSurveySmsBlast(payload) {
    return api
      .post("/survey-sms-blasts/update-quantity", payload, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  upload(payload) {
    return api
      .post(`/survey-sms-blasts/upload`, payload, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  send(payload, id) {
    return api
      .post(`/survey-sms-blasts/${id}/send`, payload, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  resend(payload, id) {
    return api
      .post(`/survey-sms-blasts/${id}/resend`, payload, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  forceSend(payload, id) {
    return api
      .post(`/survey-sms-blasts/${id}/force-send`, payload, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
}

export default new apiSurveySmsBlastService();
