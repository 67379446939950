export default {
  SET_RESERVATIONS: (state, data) => {
    state.reservations = data;
  },
  SET_RESERVATION: (state, data) => {
    state.reservation = data;
  },
  SET_RESERVATION_STATUSES: (state, data) => {
    state.statuses = data;
  },
  SET_RESERVATION_ACTIONS: (state, data) => {
    state.actions = data;
  },
};
