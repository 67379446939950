import api from "@/services/api/baseService";

class apiDealerSalesConsultantService {
  getSalesConsultants(page, per_page, q, dealer_id) {
    return api
      .get("/sales-consultants", {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
        params: {
          per_page,
          page,
          q,
          dealer_id,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  getSalesConsultant(id) {
    return api
      .get(`/sales-consultants/${id}`, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  create(data) {
    return api
      .post(`/sales-consultants`, data, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  update(id, data) {
    return api
      .put(`/sales-consultants/${id}`, data, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  delete(id) {
    return api
      .delete(`/sales-consultants/${id}`, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  uploadSalesConsultants(salesConsultants) {
    return api
      .post(`/sales-consultants/upload`, salesConsultants, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  resendEmailVerification(data) {
    return api
      .post('/sales-consultants/resend/email-verification', data, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
}

export default new apiDealerSalesConsultantService();
