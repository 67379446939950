import surveyCSOnlineSummaryService from "@/services/api/modules/surveyCSOnlineSummaryService";

export default {
  //cs online
  async getCSOnlineGraphData({ commit, dispatch }, payload) {
    commit("SET_SURVEY_CS_ONLINE_SUMMARY", []);
    const { status, data } = await surveyCSOnlineSummaryService.getCSOnlineGraphData(
      payload.slug,
      payload.filters
    );

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
        },
        {
          root: true,
        }
      );
    } else {
      commit("SET_SURVEY_CS_ONLINE_SUMMARY", data.data);
    }
  },

  async downloadCSOnlineSummary({ commit, dispatch }, payload) {
    const { status, data } = await surveyCSOnlineSummaryService.downloadCSOnlineSummary(
      payload.filters
    );
    const errorMessage = data.errors
      ? Object.values(data.errors).join(" ")
      : "Failed to download cs online summary.";
    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: errorMessage,
        },
        {
          root: true,
        }
      );
    } else {
      const url = data.data.url;
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", data.data.filename);
      document.body.appendChild(link);
      link.click();
    }
  },

  //dcsi
  async getDCSIGraphData({ commit, dispatch }, payload) {
    commit("SET_SURVEY_DCSI_SUMMARY", []);
    const { status, data } = await surveyCSOnlineSummaryService.getDCSIGraphData(
      payload.slug,
      payload.filters
    );

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
        },
        {
          root: true,
        }
      );
    } else {
      commit("SET_SURVEY_DCSI_SUMMARY", data.data);
    }
  },

  async downloadDCSISummary({ commit, dispatch }, payload) {
    const { status, data } = await surveyCSOnlineSummaryService.downloadDCSISummary(
      payload.filters
    );
    const errorMessage = data.errors
      ? Object.values(data.errors).join(" ")
      : "Failed to download dcsi summary.";
    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: errorMessage,
        },
        {
          root: true,
        }
      );
    } else {
      const url = data.data.url;
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", data.data.filename);
      document.body.appendChild(link);
      link.click();
    }
  },

  //dssi
  async getDSSIGraphData({ commit, dispatch }, payload) {
    commit("SET_SURVEY_DSSI_SUMMARY", []);
    const { status, data } = await surveyCSOnlineSummaryService.getDSSIGraphData(
      payload.slug,
      payload.filters
    );

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
        },
        {
          root: true,
        }
      );
    } else {
      commit("SET_SURVEY_DSSI_SUMMARY", data.data);
    }
  },

  async downloadDSSISummary({ commit, dispatch }, payload) {
    const { status, data } = await surveyCSOnlineSummaryService.downloadDSSISummary(
      payload.filters
    );
    const errorMessage = data.errors
      ? Object.values(data.errors).join(" ")
      : "Failed to download dssi summary.";
    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: errorMessage,
        },
        {
          root: true,
        }
      );
    } else {
      const url = data.data.url;
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", data.data.filename);
      document.body.appendChild(link);
      link.click();
    }
  },

  async importCsSurvey({ commit, dispatch }, file) {
    const { status, data } = await surveyCSOnlineSummaryService.importCsSurvey(file);

    dispatch(
      "snackbar/show",
      {
        text: data.message,
        timeout: 10000,
      },
      {
        root: true,
      }
    );

    return data;
  },

  async importCsSurveyFactor({ commit, dispatch }, file) {
    const { status, data } = await surveyCSOnlineSummaryService.importCsSurveyFactor(file);

    dispatch(
      "snackbar/show",
      {
        text: data.message,
        timeout: 10000,
      },
      {
        root: true,
      }
    );

    return data;
  },


  async importDCSISurvey({ commit, dispatch }, file) {
    const { status, data } = await surveyCSOnlineSummaryService.importDCSISurvey(file);

    dispatch(
      "snackbar/show",
      {
        text: data.message,
        timeout: 10000,
      },
      {
        root: true,
      }
    );

    return data;
  },

  async importDCSISurveyFactor({ commit, dispatch }, file) {
    const { status, data } = await surveyCSOnlineSummaryService.importDcsiSurveyFactor(file);

    dispatch(
      "snackbar/show",
      {
        text: data.message,
        timeout: 10000,
      },
      {
        root: true,
      }
    );

    return data;
  },

  async importDSSISurvey({ commit, dispatch }, file) {
    const { status, data } = await surveyCSOnlineSummaryService.importDSSISurvey(file);

    dispatch(
      "snackbar/show",
      {
        text: data.message,
        timeout: 10000,
      },
      {
        root: true,
      }
    );

    return data;
  },

  async importDSSISurveyFactor({ commit, dispatch }, file) {
    const { status, data } = await surveyCSOnlineSummaryService.importDssiSurveyFactor(file);

    dispatch(
      "snackbar/show",
      {
        text: data.message,
        timeout: 10000,
      },
      {
        root: true,
      }
    );

    return data;
  },

  async getCSOnlineData({ commit, dispatch }) {
    commit("SET_SURVEY_CS_ONLINE_DATA", []);
    const { status, data } = await surveyCSOnlineSummaryService.fetchTargetScoreData("csonline");

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
        },
        {
          root: true,
        }
      );
    } else {
      commit("SET_SURVEY_CS_ONLINE_DATA", data.data);
    }
  },

  async getDcsiOnlineData({ commit, dispatch }) {
    commit("SET_SURVEY_DCSI_ONLINE_DATA", []);
    const { status, data } = await surveyCSOnlineSummaryService.fetchTargetScoreData("dcsi");

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
        },
        {
          root: true,
        }
      );
    } else {
      commit("SET_SURVEY_DCSI_ONLINE_DATA", data.data);
    }
  },

  async getDssiOnlineData({ commit, dispatch }) {
    commit("SET_SURVEY_DSSI_ONLINE_DATA", []);
    const { status, data } = await surveyCSOnlineSummaryService.fetchTargetScoreData("dssi");

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
        },
        {
          root: true,
        }
      );
    } else {
      commit("SET_SURVEY_DSSI_ONLINE_DATA", data.data);
    }
  },


  async getFactorData({ commit, dispatch }) {
    commit("SET_FACTORS", []);
    const { status, data } = await surveyCSOnlineSummaryService.fetchFactor("service");

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
        },
        {
          root: true,
        }
      );
    } else {
      commit("SET_FACTORS", data.data);
    }
  },

  async getFactorDssiData({ commit, dispatch }) {
    commit("SET_FACTORS", []);
    const { status, data } = await surveyCSOnlineSummaryService.fetchFactor("sales");

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
        },
        {
          root: true,
        }
      );
    } else {
      commit("SET_FACTORS", data.data);
    }
  },

  async getFactorScore({ commit, dispatch }) {
    commit("SET_FACTORS_SCORE", []);
    const { status, data } = await surveyCSOnlineSummaryService.fetchFactorScore("csonline");

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
        },
        {
          root: true,
        }
      );
    } else {
      commit("SET_FACTORS_SCORE", data.data);
    }
  },

  async getFactorScoreDcsi({ commit, dispatch }) {
    commit("SET_FACTORS_SCORE", []);
    const { status, data } = await surveyCSOnlineSummaryService.fetchFactorScore("dcsi");

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
        },
        {
          root: true,
        }
      );
    } else {
      commit("SET_FACTORS_SCORE", data.data);
    }
  },

  async getFactorScoreDssi({ commit, dispatch }) {
    commit("SET_FACTORS_SCORE", []);
    const { status, data } = await surveyCSOnlineSummaryService.fetchFactorScore("dssi");

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
        },
        {
          root: true,
        }
      );
    } else {
      commit("SET_FACTORS_SCORE", data.data);
    }
  },


};
