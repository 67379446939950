<template>
  <div>
    <date-range-picker
      ref="picker"
      :opens="opens"
      :locale-data="{ firstDay: 1, format: 'mm/dd/yyyy' }"
      :minDate="minDate"
      :maxDate="maxDate"
      :singleDatePicker="singleDatePicker"
      :timePicker="timePicker"
      :timePicker24Hour="timePicker24Hour"
      :showWeekNumbers="showWeekNumbers"
      :showDropdowns="showDropdowns"
      :autoApply="singleDatePicker ? false : autoApply"
      v-model="dateRange"
      :ranges="showRanges ? undefined : false"
      @update="emitDateRangeChanged"
      :linkedCalendars="linkedCalendars"
      :always-show-calendars="false"
      :alwaysShowCalendars="alwaysShowCalendars"
      :append-to-body="appendToBody"
      :closeOnEsc="closeOnEsc"
    >
    <!--    input slot (new slot syntax)-->
    <template #input="picker">
      <span class="daterange-label" v-show="dateRange.startDate && dateRange.endDate">
         {{ formatDate(picker.startDate, "MM/DD/YYYY") }} - {{formatDate(picker.endDate, "MM/DD/YYYY") }}
      </span>
      <span class="daterange-label placeholder" v-show="!dateRange.startDate || !dateRange.endDate">
        {{ placeholder }}
      </span>
    </template>

    <!--    footer slot-->
    <div slot="footer" slot-scope="data" class="slot">
      <div class="btn-reset-wrapper">
        <a @click="resetDate" class="btn-reset">Clear</a>
      </div>
    </div>
    </date-range-picker>
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker';
import * as helpers from "@/core/helpers";
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

export default {
  components: { 
    DateRangePicker,
  },
  props: {
    maxDate: {
      required: false,
      default: "",
    },
    minDate: {
      required: false,
      default: ""
    },
    opens: {
      required: false,
      default: "center"
    },
    singleDatePicker: {
      type: Boolean,
      default: false,
    },
    selectedDate: {
      type: Object,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: "Filter by date"
    },
    showRanges: {
      type: Boolean,
      default: true,
    },
    showWeekNumbers: {
      type: Boolean,
      default: true,
    },
    timePicker: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      dateRange: {
        startDate: "",
        endDate: "",
      },
      single_range_picker: false,
      show_ranges: true,
      // timePicker: false,
      timePicker24Hour: false,
      showDropdowns: true,
      autoApply: true,
      linkedCalendars: true,
      alwaysShowCalendars: true,
      appendToBody: false,
      closeOnEsc: true,
    };
  },
  mounted() {
    if (this.selectedDate) {
      this.dateRange = this.selectedDate;
    } else {
      // this.dateRange = {
      //   startDate: new Date(),
      //   endDate: new Date()
      // }
    }
  },
  methods: {
    resetDate() {
      this.dateRange = {
        startDate: "",
        endDate: "",
      };

      this.$refs.picker.togglePicker(false);
      this.$emit("update:daterange", null);
    },
    formatDate(item, format) {
      return helpers.format_date(item, format);
    },
    emitDateRangeChanged() {
      const dates = [
        this.formatDate(this.dateRange.startDate, "YYYY-MM-DD"),
        this.formatDate(this.dateRange.endDate, "YYYY-MM-DD"),
      ];
      this.$emit("update:daterange", dates);
    }
  }
}
</script>
<style scoped>
.daterange-label {
  font-size: 16px;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.87);
}
.daterange-label.placeholder {
  color: rgba(0, 0, 0, 0.57) !important;
}
.btn-reset-wrapper {
  height: 40px;
  text-align: right;
  padding: 10px 0px;
}
.btn-reset {
  margin: 10px;
  font-size: 14px;
  font-weight: 500;
  padding: 5px 10px;
  background-color: #6c757d;
  color: #fff;
  border-radius: 3px;
}
</style>