import api from "@/services/api/baseService";

class apiNewsService {
  getImmediateMeasures() {
    return api
      .get("/surveys-cdcs/options/immediate-measures", {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }

  getFactors() {
    return api
      .get("/surveys-cdcs/options/factors", {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }

  getAttributes(factor_id) {
    return api
      .get("/surveys-cdcs/options/attributes", {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
        params: {
          factor_id,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }

  getRootCauses(attribute_id) {
    return api
      .get("/surveys-cdcs/options/root-cause", {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
        params: {
          attribute_id,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  getDealerPreventiveMeasures(attribute_id) {
    return api
      .get("/surveys-cdcs/options/dealer-preventive-measures", {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
        params: {
          attribute_id,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }

  update(id, survey_result_cdc) {
    return api
      .put(`/surveys-cdcs/${id}`, survey_result_cdc, {
        _method: "PUT",
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }

  getFactorsCount(payload) {
    return api
      .get("/surveys-cdcs/factors/count", {
        params: { ...payload },
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }

  getCSFactorsCount(payload) {
    return api
      .get("/survey-csonline-cdcs/factors/count", {
        params: { ...payload },
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }

  getDSSIFactorsCount(payload) {
    return api
      .get("/survey-dssi-cdcs/factors/count", {
        params: { ...payload },
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
}

export default new apiNewsService();
