<template v-slot:activator="{ on, attrs }">
  <div v-bind="attrs" v-on="on" no-gutter>
    <v-select
      append-icon="mdi-chevron-down"
      :items="items"
      item-text="text"
      item-value="value"
      label="Dealer Group"
      outlined
      clearable
      hide-details
      clear-icon="mdi-close-circle-outline"
      background-color="selects"
      v-model="dealerGroup"
      @change="updateDealerGroup"
    ></v-select>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      items: "dealerGroup/GET_DEALER_GROUPS",
      dealerGroup: "dealerGroup/GET_DEALER_GROUP"
    }),
  },
  methods: {
    ...mapActions({
      setDealerGroup: "dealerGroup/setDealerGroup",
      getDealerGroups: "dealerGroup/getDealerGroups",
    }),
    updateDealerGroup() {
      this.setDealerGroup(this.dealerGroup);
    },
  },
  mounted() {
    this.getDealerGroups();
  },
};
</script>

<style></style>
