export default {
  SET_QUOTATIONS: (state, data) => {
    state.quotations = data;
  },
  SET_QUOTATION: (state, data) => {
    state.quotation = data;
  },
  SET_QUOTATION_STATUSES: (state, data) => {
    state.statuses = data;
  },
  SET_QUOTATION_ACTIONS: (state, data) => {
    state.actions = data;
  },
};
