import apiInquiryService from "@/services/api/modules/inquiryService";

export default {
  async getInquiries({ commit, dispatch }, param = {}) {
    commit("SET_INQUIRIES", []);
    const { status, data } = await apiInquiryService.getInquiries(
      param.page,
      param.per_page,
      param.filters
    );
    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
          timeout: 10000,
        },
        {
          root: true,
        }
      );
    } else {
      let responseData = Object.keys(param).length === 0 ? data.data : data;

      commit("SET_INQUIRIES", responseData);
    }
  },

  async updateInquiry({ commit, dispatch }, request) {
    const { status, data } = await apiInquiryService.updateInquiry(request);
    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
          timeout: 10000,
        },
        {
          root: true,
        }
      );
    } //end if

    return data;
  },

  async uploadInquiries({ commit, dispatch }, file) {
    const { status, data } = await apiInquiryService.upload(file);
    const message = data.errors ? Object.values(data.errors).join(" ") : data.message;

    dispatch(
      "snackbar/show",
      {
        text: message,
        timeout: 10000,
      },
      {
        root: true,
      }
    );

    return data.success;
  },
};
