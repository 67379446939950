import axios from "axios";
import { getCookie, removeCookie, moveLocalStorageTokenToCookie } from "@/core/cookie";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

axios.defaults.withCredentials = true;

let api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_VERSION + "/",
  timeout: 250000,
  withCredentials: true
});

api.interceptors.request.use(function (config) {
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.code === "ECONNABORTED") {
      throw error;
    }

    if (error.response.status == 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");

      removeCookie("laravel_session");
      removeCookie("XSRF-TOKEN");

      throw error;
    }

    throw error;
  }
);

export default api;
