import api from "@/services/api/baseService";

class apiNewsService {
  list(page, per_page, q) {
    return api
      .get("/news", {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
        params: {
          page,
          per_page,
          q,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  create(news) {
    return api
      .post("/news", news, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  edit(id) {
    return api
      .get(`/news/${id}`, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  update(id, news) {
    return api
      .post(`/news/${id}`, news, {
        _method: "PUT",
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  delete(id) {
    return api
      .delete(`/news/${id}`, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
}

export default new apiNewsService();
