export default [
  {
    path: "leads",
    name: "leads",
    component: () => import("../Leads.vue"),
    meta: {
      title: "Leads",
      description: "Leads",
      sort: 0,
      authenticatable: true,
      permission: false,
      icon: "leads",
      roles: ["admin", "dealer"]
    },
  },
  {
    path: "/admin/leads/upload",
    name: "leads.upload",
    component: () => import("../Upload.vue"),
    meta: {
      title: "Upload Leads",
      description: "Lead Inventory",
      sort: 0,
      authenticatable: true,
      permission: false,
      icon: "leads",
      roles: ["admin"]
    },
  },
];
