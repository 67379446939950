import api from "@/services/api/baseService";

class apiReservationsService {
  list(params) {
    return api
      .get("/reservation", {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
        params: params,
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  getReservation(id) {
    return api
      .get(`/reservations/${id}`, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  create(reservation) {
    return api
      .post("/reservation", reservation, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  update(reservation, id) {
    return api
      .put(`/reservation/${id}`, reservation, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  upload(reservation) {
    return api
      .post(`/reservation`, reservation, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  }
  download() {
    return api
      .get(`/reservation-download`, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
}

export default new apiReservationsService();
