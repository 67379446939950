import api from "@/services/api/baseService";

class apiQuotationsService {
  list(page, per_page, q, model, status, action) {
    return api
      .get("/quotation", {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
        params: {
          page,
          per_page,
          q,
          model,
          status,
          action,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  getQuotation(id) {
    return api
      .get(`/quotation/${id}`, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  update(quotation, id) {
    return api
      .put(`/quotation/${id}`, quotation, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  upload(fileDocument, id) {
    return api
      .post(`/quotation/${id}/upload`, fileDocument, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
}

export default new apiQuotationsService();
