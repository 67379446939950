import api from "@/services/api/baseService";

class apiLeadsService {
  list(page, per_page, q, status, dealer) {
    return api
      .get("/leads", {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
        params: {
          page,
          per_page,
          q,
          status,
          dealer,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  update(lead, id) {
    return api
      .put(`/leads/${id}`, lead, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  upload(leads) {
    return api
      .post(`/leads`, leads, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  }
  download() {
    return api
      .get(`/leads-download`, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
}

export default new apiLeadsService();
