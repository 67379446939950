import api from "@/services/api/baseService";

class apiSurveyCSOnlineSummaryService {
  //csonline
  getCSOnlineGraphData(slug, filters) {
    return api
      .get("/survey-csonline-touchpoints/" + slug, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
        params: {
          ...filters,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }

  downloadCSOnlineSummary(filters) {
    return api.get(`/survey-csonline-touchpoints/export/summary`, {
      headers: {
        // Authorization: `Bearer ${localStorage.token}`,
      },
      params: {
        ...filters,
      },
    });
  }

  //dcsi
  getDCSIGraphData(slug, filters) {
    return api
      .get("/survey-dcsi-touchpoints/" + slug, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
        params: {
          ...filters,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }

  downloadDCSISummary(filters) {
    return api.get(`/survey-dcsi-touchpoints/export/summary`, {
      headers: {
        // Authorization: `Bearer ${localStorage.token}`,
      },
      params: {
        ...filters,
      },
    });
  }

  //dssi
  getDSSIGraphData(slug, filters) {
    return api
      .get("/survey-dssi-touchpoints/" + slug, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
        params: {
          ...filters,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }

  downloadDSSISummary(filters) {
    return api.get(`/survey-dssi-touchpoints/export/summary`, {
      headers: {
        // Authorization: `Bearer ${localStorage.token}`,
      },
      params: {
        ...filters,
      },
    });
  }

  importCsSurvey(file) {
    return api({
      method: "post",
      url: "/surveys-cs-online/trend/upload",
      headers: {
        // Authorization: `Bearer ${localStorage.token}`,
      },
      data: file,
    })
      .then((response) => response)
      .catch((error) => error.response);
  }

  importCsSurveyFactor(file) {
    return api({
      method: "post",
      url: "/surveys-cs-online/factors/upload",
      headers: {
        // Authorization: `Bearer ${localStorage.token}`,
      },
      data: file,
    })
      .then((response) => response)
      .catch((error) => error.response);
  }

  importDCSISurvey(file) {
    return api({
      method: "post",
      url: "/surveys-dcsi-online/trend/upload",
      headers: {
        // Authorization: `Bearer ${localStorage.token}`,
      },
      data: file,
    })
      .then((response) => response)
      .catch((error) => error.response);
  }

  importDcsiSurveyFactor(file) {
    return api({
      method: "post",
      url: "/surveys-dcsi-online/factors/upload",
      headers: {
        // Authorization: `Bearer ${localStorage.token}`,
      },
      data: file,
    })
      .then((response) => response)
      .catch((error) => error.response);
  }

  importDSSISurvey(file) {
    return api({
      method: "post",
      url: "/surveys-dssi-online/trend/upload",
      headers: {
        // Authorization: `Bearer ${localStorage.token}`,
      },
      data: file,
    })
      .then((response) => response)
      .catch((error) => error.response);
  }

  importDssiSurveyFactor(file) {
    return api({
      method: "post",
      url: "/surveys-dssi-online/factors/upload",
      headers: {
        // Authorization: `Bearer ${localStorage.token}`,
      },
      data: file,
    })
      .then((response) => response)
      .catch((error) => error.response);
  }

  fetchTargetScoreData(type) {
    return api({
      method: "get",
      url: `/surveys-target-score?type=${type}&target=trend`,
      headers: {
        // Authorization: `Bearer ${localStorage.token}`,
      },
    })
      .then((response) => response)
      .catch((error) => error.response);
  }

  fetchFactor(category) {
    return api({
      method: "get",
      url: `/survey-factors?category=${category}`,
      headers: {
        // Authorization: `Bearer ${localStorage.token}`,
      },
    })
      .then((response) => response)
      .catch((error) => error.response);
  }

  fetchFactorScore(type) {
    return api({
      method: "get",
      url: `/surveys-target-score?type=${type}&target=factor`,
      headers: {
        // Authorization: `Bearer ${localStorage.token}`,
      },
    })
      .then((response) => response)
      .catch((error) => error.response);
  }

}

export default new apiSurveyCSOnlineSummaryService();
