export default {
  SET_SMS_BLASTS: (state, data) => {
    state.smsTextBlasts = data;
  },
  SET_SMS_BLAST: (state, data) => {
    state.smsTextBlast = data;
  },
  SET_SMS_BLAST_UPLOAD: (state, data) => {
    state.smsBlastUpload = data;
  }
};
