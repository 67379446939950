import apiDealerPartService from "@/services/api/modules/dealerPartService";
import { DUMMY_GET_INQUIRIES_API_RESPONSE } from "../../statics";

export default {
  
  async getInquiries({ commit, dispatch }, params = {}) {
    commit("SET_INQUIRIES", []);
    const { status, data } = await apiDealerPartService.getInquiries(
      params.page,
      params.per_page,
      params.filters,
    );

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
          timeout: 10000,
        },
        {
          root: true,
        }
      );

      /**
       * only for testing
       */
      commit("SET_INQUIRIES", DUMMY_GET_INQUIRIES_API_RESPONSE);
    } else {
      
      let responseData = Object.keys(params).length === 0 ? data.data : data;

      commit("SET_INQUIRIES", responseData);
    }
  },

  async uploadInquiries({ commit, dispatch }, file) {
    console.log(123)
    const { status, data } = await apiDealerPartService.uploadInquiries(file);
    const message = data.errors ? Object.values(data.errors).join(" ") : data.message;

    dispatch(
      "snackbar/show",
      {
        text: message,
        timeout: 10000,
      },
      {
        root: true,
      }
    );

    return data.success;
  },

  async getInquiry({ commit, dispatch }, id) {
    commit("SET_INQUIRY", []);
    const { status, data } = await apiDealerPartService.getInquiry(id);

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
          timeout: 10000,
        },
        {
          root: true,
        }
      );

      /**
       * only for testing
       */
      commit("SET_INQUIRY", DUMMY_GET_INQUIRIES_API_RESPONSE.data[0]);
    } else {
      
      commit("SET_INQUIRY", data.data);
    }
  },
  
  async getInquiryItems({ commit, dispatch }, id) {
    commit("SET_INQUIRY_ITEMS", []);
    const { status, data } = await apiDealerPartService.getInquiryItems(id);

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
          timeout: 10000,
        },
        {
          root: true,
        }
      );

    } else {
      
      commit("SET_INQUIRY_ITEMS", data.data);
    }
  },

  async updateInquiry({ commit, dispatch }, request) {
    const { status, data } = await apiDealerPartService.updateInquiry(request);
    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
          timeout: 10000,
        },
        {
          root: true,
        }
      );
    } //end if

    return data;
  },

  async getBaseInquiries({ commit, dispatch }, params = {}) {
    commit("SET_BASE_INQUIRIES", []);

    const { status, data }  = await apiDealerPartService.getBaseInquiries(
      params.page,
      params.per_page,
      params.filters,
    );

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
          timeout: 10000,
        },
        {
          root: true,
        }
      );

    } else {
      let responseData = Object.keys(params).length === 0 ? data.data : data;
      commit("SET_BASE_INQUIRIES", responseData);
    }
  },

  async getBaseInquryInquiries({ commit, dispatch }, params = {}) {
    commit("SET_BASE_INQUIRY_INQUIRIES", []);

    const { status, data }  = await apiDealerPartService.getBaseInquiryInquries(
      params.page,
      params.per_page,
      params.filters,
    );

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
          timeout: 10000,
        },
        {
          root: true,
        }
      );

    } else {
      let responseData = Object.keys(params).length === 0 ? data.data : data;
      commit("SET_BASE_INQUIRY_INQUIRIES", responseData);
    }
  },

  async getBaseInquiryItems({ commit, dispatch }, id) {
    commit("SET_INQUIRY_ITEMS", []);
    const { status, data } = await apiDealerPartService.getBaseInquiryItems(id);

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
          timeout: 10000,
        },
        {
          root: true,
        }
      );

    } else {
      
      commit("SET_INQUIRY_ITEMS", data.data);
    }
  },
  
};
