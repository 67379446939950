import apiDealerSalesConsultantService from "@/services/api/modules/dealerSalesConsultantService";

export default {
  async getSalesConsultants({ commit }, page = {}) {
    commit("SET_SALES_CONSULTANTS", []);
    const { status, data } = await apiDealerSalesConsultantService.getSalesConsultants(
      page.page,
      page.per_page,
      page.q,
      page.dealer_id
    );

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
          timeout: 10000,
        },
        {
          root: true,
        }
      );
    } else {
      let responseData = Object.keys(page).length === 0 ? data.data : data;

      commit("SET_SALES_CONSULTANTS", responseData);
    }
  },
  async getSalesConsultant({ commit }, id) {
    commit("SET_SALES_CONSULTANT", null);
    const { status, data } = await apiDealerSalesConsultantService.getSalesConsultant(id);

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
          timeout: 10000,
        },
        {
          root: true,
        }
      );
    } else {
      commit("SET_SALES_CONSULTANT", data.data);
    }
  },
  async save({ dispatch, commit }, details) {
    if (details.id) {
      var { status, data } = await apiDealerSalesConsultantService.update(details.id, details.data);
    } else {
      var { status, data } = await apiDealerSalesConsultantService.create(details.data);
    }

    dispatch(
      "snackbar/show",
      {
        text: data.message,
        timeout: 10000,
      },
      {
        root: true,
      }
    );
    if (status === 200) {
      commit("SET_SALES_CONSULTANT", data.data);
    }
  },
  async delete({ dispatch }, id) {
    const { status, data } = await apiDealerSalesConsultantService.delete(id);

    dispatch(
      "snackbar/show",
      {
        text: data.message,
        timeout: 10000,
      },
      {
        root: true,
      }
    );
    if (status === 200) {
      dispatch("getItems");
    }
  },

  async uploadSalesConsultant({ commit, dispatch }, payload) {
    const { status, data } = await apiDealerSalesConsultantService.uploadSalesConsultants(payload);
    const message = data.errors ? Object.values(data.errors).join(" ") : data.message;
    dispatch (
      "snackbar/show",
      {
        text: message,
        timeout: 10000,
      },
      {
        root: true,
      }
    );
  },

  async resendEmalVerificationSalesConsultant({ dispatch }, payload) {
    const { status, data } = await apiDealerSalesConsultantService.resendEmailVerification(payload);

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
          timeout: 10000,
        },
        {
          root: true,
        }
      );
    } else {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
          timeout: 10000,
        },
        {
          root: true,
        }
      );
    }
  }
};
