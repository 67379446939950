export default {
  SET_LEADS: (state, data) => {
    state.leads = data;
  },
  SET_LEAD: (state, data) => {
    state.lead = data;
  },
  SET_LEADS_STATUSES: (state, data) => {
    state.statuses = data;
  },
};
