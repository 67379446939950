export default [
    {
      path: "/admin/profile",
      name: "auth.profile",
      component: () => import("../Profile.vue"),
      meta: {
        title: "Profile",
        description: "Profile",
        sort: 0,
        authenticatable: true,
        permission: false
      },
    }
]