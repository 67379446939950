export default {
  SET_SURVEY: (state, data) => {
    state.surveys = data;
  },

  SET_SURVEY_CDCS: (state, data) => {
    state.cdcs = data;
  },

  SET_SURVEY_CDC: (state, data) => {
    state.cdc = data;
  },

  SET_EMAIL_NOTIFICATION: (state, data) => {
    state.notifications = data;
  },

  SET_SURVEY_RESULTS: (state, data) => {
    state.results = data;
  },

  SET_SURVEY_RESULT: (state, data) => {
    state.result = data;
  },

  SET_TEXTBLAST_LOGS: (state, data) => {
    state.textblast_logs = data;
  },

  SET_SURVEY_CATEGORIES: (state, data) => {
    state.categories = data;
  },
  SET_FACTORS_BY_CATEGORY: (state, data) => {
    state.factors = data;
  },
};
