import api from "@/services/api/baseService";

class apiTestDrivesService {
  getTestDrives(page, per_page, filters) {
    const { q, model, status, action } = filters;

    return api
      .get("/test-drive", {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
        params: {
          per_page,
          page,
          q,
          model,
          status,
          action,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }

  getTestDriveUnits(page, per_page, filters) {
    const { q, model, dealer } = filters;
    return api
      .get("/test-drive-unit", {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
        params: {
          per_page,
          page,
          q,
          model,
          dealer,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }

  importTestDrives(file) {
    return api({
      method: "post",
      url: "/test-drive",
      headers: {
        // Authorization: `Bearer ${localStorage.token}`,
      },
      data: file,
    })
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  }

  importTestDriveUnit(file) {
    return api({
      method: "post",
      url: "/test-drive-unit",
      headers: {
        // Authorization: `Bearer ${localStorage.token}`,
      },
      data: file,
    })
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  }

  getTestDriveDetails(id) {
    return api
      .get("/testDrives/" + id, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }

  updateTestDrive(request) {
    return api({
      method: "put",
      url: "/test-drive/" + request.id,
      headers: {
        // Authorization: `Bearer ${localStorage.token}`,
      },
      data: request,
    })
      .then((response) => response)
      .catch((error) => error.response);
  }

  download() {
    return api
      .get(`/test-drive-download`, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }

  updateTestDriveUnit(request) {
    return api({
      method: "put",
      url: "/test-drive-unit/" + request.id,
      headers: {
        // Authorization: `Bearer ${localStorage.token}`,
      },
      data: request,
    })
      .then((response) => response)
      .catch((error) => error.response);
  }

  updateTestDriveUnitQuantity(request) {
    return api({
      method: "post",
      url: "/test-drive-unit/update-quantity",
      headers: {
        // Authorization: `Bearer ${localStorage.token}`,
      },
      data: request,
    })
      .then((response) => response)
      .catch((error) => error.response);
  }
}

export default new apiTestDrivesService();
