import apiLeadsService from "@/services/api/modules/leadsService";
import apiStatusesService from "@/services/api/modules/statusesService";

export default {
  async list({ commit, dispatch }, page = {}) {
    commit("SET_LEADS", []);
    const { status, data } = await apiLeadsService.list(
      page.page,
      page.per_page,
      page.q,
      page.status,
      page.dealer,
    );
    const errorMessage = data.errors ? Object.values(data.errors).join(" ") : data.message;

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: errorMessage,
        },
        {
          root: true,
        }
      );
    } else {
      let responseData = Object.keys(page).length === 0 ? data.data : data;

      commit("SET_LEADS", responseData);
    }
  },
  async getLeadsStatuses({ commit, dispatch }) {
    commit("SET_LEADS_STATUSES", null);
    const { status, data } = await apiStatusesService.list("lead");

    if (status == 200) {
      commit("SET_LEADS_STATUSES", data.data);
    }
  },
  async update({ commit, dispatch }, payload) {
    const { status, data } = await apiLeadsService.update(payload.data, payload.id);
    const message = data.errors ? Object.values(data.errors).join(" ") : data.message;
    dispatch(
      "snackbar/show",
      {
        text: message,
      },
      {
        root: true,
      }
    );
  },
  async uploadLeads({ commit, dispatch }, payload) {
    const { status, data } = await apiLeadsService.upload(payload);
    const message = data.errors ? Object.values(data.errors).join(" ") : data.message;
    dispatch(
      "snackbar/show",
      {
        text: message,
      },
      {
        root: true,
      }
    );
  },
  async download({ commit, dispatch }) {
    const { status, data } = await apiLeadsService.download();
    const errorMessage = data.errors
      ? Object.values(data.errors).join(" ")
      : "Failed to download leads.";
    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: errorMessage,
        },
        {
          root: true,
        }
      );
    } else {
      const url = data.data.url;
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", data.data.filename);
      document.body.appendChild(link);
      link.click();
    }
  },
};
