import units from "../routes/children";

export default [
  {
    name: "survey",
    meta: {
      title: "Surveys",
      sort: 8,
      authenticatable: true,
      description: "Surveys",
      icon: "mdi-clipboard-text-outline",
      permission: false,
      roles: ["admin", "admin_survey", "dealer_survey"],
    },
    children: [].concat(units),
  },
];
