import api from "@/services/api/baseService";

class apiAddressesService {
  list() {
    return api
      .get("/regions", {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  getAllProvinces() {
    return api
      .get(`/provinces`, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  getProvinces(regionId) {
    return api
      .get(`/regions/${regionId}/provinces`, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  getAllCities() {
    return api
      .get(`/cities`, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  getCities(provinceId) {
    return api
      .get(`/provinces/${provinceId}/cities`, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  getAllBarangays() {
    return api
      .get(`/barangays`, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  getBarangays(cityId) {
    return api
      .get(`/cities/${cityId}/barangays`, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
}

export default new apiAddressesService();
