let children = null;

if (localStorage.user) {
  const user = JSON.parse(localStorage.user);
  if (user.is_admin) {
    children = [
      {
        name: "cars.index",
        meta: {
          title: "Cars",
          sort: 0,
          authenticatable: true,
          description: "Manage cars",
          icon: "mdi-car-outline",
          permission: false,
          roles: ["admin"],
        },
      },
      {
        name: "cars.models.index",
        meta: {
          title: "Models",
          sort: 0,
          authenticatable: true,
          description: "Manage cars",
          icon: "mdi-car-electric-outline",
          permission: false,
          roles: ["admin"],
        },
      },
      {
        name: "cars.colors.index",
        meta: {
          title: "Colors",
          sort: 0,
          authenticatable: true,
          description: "Manage cars",
          icon: "mdi-invert-colors",
          permission: false,
          roles: ["admin"],
        },
      },
      {
        name: "cars.years.index",
        meta: {
          title: "Years",
          sort: 0,
          authenticatable: true,
          description: "Manage cars",
          icon: "mdi-calendar-month-outline",
          permission: false,
          roles: ["admin"],
        },
      },
    ];
  }
}

export default [
  {
    name: "cars.index",
    meta: {
      title: "Cars",
      sort: 9,
      authenticatable: true,
      description: "Manage cars",
      icon: "mdi-car-outline",
      permission: false,
      roles: ["admin"],
    },
    children: children,
  },
];
