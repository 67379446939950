import apiDealerService from "@/services/api/modules/dealerService";

export default {
  setDealer({ commit }, type) {
    commit("SET_DEALER", type);
  },
  async getDealers({ commit }, param = {}) {
    const { data } = await apiDealerService.getDealers(
      null, 
      null, 
      null, 
      param.region_types,
      param.zones,
      param.groups
    );

    var dealers = [];
    let dealerList = data.data;
    for (var i = 0; i < dealerList.length; i++) {
      dealers.push({
        text: dealerList[i].name,
        value: dealerList[i].id,
      });
    }

    commit("SET_DEALERS", dealers);
  },
  async getDealers2({ commit }, params = {}) {
    const { data } = await apiDealerService.getDealers2(params);

    var dealers = [];
    let dealerList = data.data;
    for (var i = 0; i < dealerList.length; i++) {
      dealers.push({
        text: dealerList[i].name,
        value: dealerList[i].id,
      });
    }

    commit("SET_DEALERS", dealers);
  }
};
