import api from "@/services/api/baseService";

class apiInquiryService {
  getInquiries(page, per_page, filterData = {}) {
    const { status, action, q } = filterData;
    return api
      .get("/inquiries", {
        // headers: { Authorization: `Bearer ${localStorage.token}` },
        params: {
          page,
          per_page,
          status,
          action,
          q,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  updateInquiry(request) {
    return api
      .patch(`/inquiries/${request.id}`, request, {
        // headers: { Authorization: `Bearer ${localStorage.token}` },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  upload(param) {
    return api
      .post("/inquiries", param, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  }
}

export default new apiInquiryService();
