import api from "@/services/api/baseService";

class apiGraphService {
  graph(dealer_zone, dealer_group, dealer_id, date) {
    return api
      .get("/reports/graph", {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
        params: {
          dealer_zone,
          dealer_group,
          dealer_id,
          date,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
}

export default new apiGraphService();
