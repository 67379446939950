import api from "@/services/api/baseService";

class apiStatusesService {
  list(type) {
    return api
      .get("/statuses", {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
        params: {
          type,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
}

export default new apiStatusesService();
