import apiTestDrivesService from "@/services/api/modules/testDrivesService";
import apiDealersService from "@/services/api/modules/dealerService";
import apiModelService from "@/services/api/modules/modelsService";
import apiStatusesService from "@/services/api/modules/statusesService";
import apiActionsService from "@/services/api/modules/actionsService";

export default {
  async getTestDrives({ commit, dispatch }, page = {}) {
    commit("SET_TEST_DRIVES", []);
    const { status, data } = await apiTestDrivesService.getTestDrives(
      page.page,
      page.per_page,
      page.filters
    );

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
          timeout: 10000,
        },
        {
          root: true,
        }
      );
    } else {
      let responseData = Object.keys(page).length === 0 ? data.data : data;

      commit("SET_TEST_DRIVES", responseData);
    }
    return data;
  },

  async importTestDrives({ commit, dispatch }, file) {
    const { status, data } = await apiTestDrivesService.importTestDrives(file);

    dispatch(
      "snackbar/show",
      {
        text: data.message,
        timeout: 10000,
      },
      {
        root: true,
      }
    );

    return data;
  },

  async importTestDriveUnit({ commit, dispatch }, file) {
    const { status, data } = await apiTestDrivesService.importTestDriveUnit(file);

    dispatch(
      "snackbar/show",
      {
        text: data.message,
        timeout: 10000,
      },
      {
        root: true,
      }
    );

    return data;
  },

  async getTestDriveUnits({ commit, dispatch }, page = {}) {
    commit("SET_TEST_DRIVE_UNITS", []);

    const { status, data } = await apiTestDrivesService.getTestDriveUnits(
      page.page,
      page.per_page,
      page.filters
    );

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
          timeout: 10000,
        },
        {
          root: true,
        }
      );
    } else {
      commit("SET_TEST_DRIVE_UNITS", data.data);
    }
  },

  async getModels({ commit, dispatch }) {
    commit("SET_MODELS", []);

    const { status, data } = await apiModelService.list();

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
          timeout: 10000,
        },
        {
          root: true,
        }
      );
    } else {
      commit("SET_MODELS", data.data);
    }
  },

  async getActions({ commit, dispatch }) {
    commit("SET_ACTIONS", []);

    const { status, data } = await apiActionsService.list("testdrive");

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
          timeout: 10000,
        },
        {
          root: true,
        }
      );
    } else {
      commit("SET_ACTIONS", data.data);
    }
  },

  async getStatuses({ commit, dispatch }) {
    commit("SET_STATUSES", []);

    const { status, data } = await apiStatusesService.list("testdrive");

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
          timeout: 10000,
        },
        {
          root: true,
        }
      );
    } else {
      commit("SET_STATUSES", data.data);
    }
  },

  async getDealers({ commit, dispatch }) {
    commit("SET_DEALERS", []);

    const { status, data } = await apiDealersService.getDealers();

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
          timeout: 10000,
        },
        {
          root: true,
        }
      );
    } else {
      commit("SET_DEALERS", data);
    }
  },

  async updateTestDrive({ commit, dispatch }, request) {
    const { status, data } = await apiTestDrivesService.updateTestDrive(request);
    dispatch(
      "snackbar/show",
      {
        text: data.message,
        timeout: 10000,
      },
      {
        root: true,
      }
    );

    return data;
  },

  async downloadTestDrive({ commit, dispatch }) {
    const { status, data } = await apiTestDrivesService.download();
    const errorMessage = data.errors
      ? Object.values(data.errors).join(" ")
      : "Failed to download test drive.";
    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: errorMessage,
        },
        {
          root: true,
        }
      );
    } else {
      const url = data.data.url;
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", data.data.filename);
      document.body.appendChild(link);
      link.click();
    }
  },

  async updateTestDriveUnit({ commit, dispatch }, request) {
    const { status, data } = await apiTestDrivesService.updateTestDriveUnit(request);
    dispatch(
      "snackbar/show",
      {
        text: data.message,
        timeout: 10000,
      },
      {
        root: true,
      }
    );

    return data;
  },

  async updateTestDriveUnitQuantity({ commit, dispatch }, request) {
    const { status, data } = await apiTestDrivesService.updateTestDriveUnitQuantity(request);
    dispatch(
      "snackbar/show",
      {
        text: data.message,
        timeout: 10000,
      },
      {
        root: true,
      }
    );

    return data;
  },
};
