import apiAuthService from "@/services/api/modules/authService";
import { setCookie, removeCookie } from "@/core/cookie";

export default {
  async login({ commit }, auth) {
    commit("SET_ERR", null);
    const { status, data } = await apiAuthService.login(auth);

    if (status !== 200) {
      commit("SET_ERR", data.message);
    } else if (data.mfa) {
      return "mfa";
    } else {
      const { role, ...user } = data.user
      // localStorage.token = data.access_token;

      localStorage.user = JSON.stringify(user);
      const userRole = role ? role[0].slug : "";

      commit("SET_AUTH", {
        access_token: data.access_token,
        user: JSON.stringify(user)
      });
      commit("SET_USER_TYPE", userRole);

      commit("SET_ERR", null);
    }
  },
  async getUser({ commit, state }) {
    console.log({ state })
    const user = JSON.parse(localStorage.getItem("user"));
    const access_token = localStorage.getItem("auth");
    const userRole = user.role ? user.role[0].slug : "";
    
    commit("SET_USER_TYPE", userRole);
    commit("SET_AUTH", {
      access_token: access_token,
      user: user,
    });
  },
  async getUserType({ commit, state }) {
    const userRole = state.role ? state.role.slug : "";
    commit("SET_USER_TYPE", userRole);
  },
  async logout({ commit, dispatch }, auth) {
    commit("SET_ERR", null);
    const { status, data } = await apiAuthService.logout(auth);

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
        },
        {
          root: true,
        }
      );
    } else {
      localStorage.removeItem("token");
      localStorage.removeItem("user");

      removeCookie("laravel_session");
      removeCookie("XSRF-TOKEN");

      commit("SET_AUTH", {
        access_token: false,
        user: {},
      });

      commit("SET_USER_TYPE", "");
      commit("SET_ROLE", {});
      commit("SET_ROLES", []);
    }
  },
  async actionForgotPassword({ commit, dispatch }, auth) {
    commit("SET_ERR", null);
    const { status, data } = await apiAuthService.forgotPassword(auth);
    const message = data.errors ? Object.values(data.errors).join(" ") : data.message;
    if (status !== 200) {
      commit("SET_ERR", message);
    }
  },

  async actionResetPassword({ commit, dispatch }, payload) {
    commit("SET_ERR", null);
    const { status, data } = await apiAuthService.resetPassword(payload);
    const message = data.errors ? Object.values(data.errors).join(" ") : data.message;
    if (status !== 200) {
      commit("SET_ERR", message);
    }
  },

  async actionChangePassword({ commit, dispatch }, payload) {
    commit("SET_ERR", null);
    const { status, data } = await apiAuthService.changePassword(payload);
    const message = data.errors ? Object.values(data.errors).join(" ") : data.message;
    if (status !== 200) {
      commit("SET_ERR", message);
    }
  },
  async actionVerifyEmail({ commit, dispatch }, payload) {
    commit("SET_ERR", null);
    const { status, data } = await apiAuthService.verifyEmail(payload);
    const message = data.errors ? Object.values(data.errors).join(" ") : data.message;
    if (status !== 200) {
      commit("SET_ERR", message);
    }

    return data;
  },
  async actionSubmitOtp({ commit, dispatch }, payload) {
    commit("SET_ERR", null);
    const { status, data } = await apiAuthService.submitOtp(payload);
    const message = data.errors ? Object.values(data.errors).join(" ") : data.message;
    // if (status !== 200) {
    //   commit("SET_ERR", message);
    // }

    if (status !== 200) {
      commit("SET_ERR", message);
    } else {
      const { role, ...user } = data.user

      localStorage.user = JSON.stringify(user);
      const userRole = role ? role[0].slug : "";

      commit("SET_AUTH", {
        access_token: data.access_token,
        user: JSON.stringify(user)
      });
      commit("SET_USER_TYPE", userRole);

      commit("SET_ERR", null);
    }

    return data;
  },
  async actionResendOtp({ commit, dispatch }, payload) {
    commit("SET_ERR", null);
    const { status, data } = await apiAuthService.resendOtp(payload);
    const message = data.errors ? Object.values(data.errors).join(" ") : data.message;
    if (status !== 200) {
      commit("SET_ERR", message);
    }

    return data;
  },
};
