export default [
  {
    path: "dashboard",
    name: "dashboard",
    component: () => import("../Reports.vue"),
    meta: {
      title: "Reports",
      description: "Reports",
      sort: 0,
      authenticatable: true,
      permission: false,
      icon: "inquiries",
      roles: ["admin", "dealer"]
    },
  }
];
