import apiDealerGroupsService from "@/services/api/modules/dealerGroupsService";

export default {
  setDealerGroup({ commit }, group) {
    commit("SET_DEALER_GROUPS", group);
  },
  async getDealerGroups({ commit }, param = {}) {
    const { data } = await apiDealerGroupsService.getGroups(
      null, 
      null, 
      null, 
      param.region_types,
      param.zones
    );

    var groups = [];
    let dealer_groups = data.data;
    for (var i = 0; i < dealer_groups.length; i++) {
      groups.push({
        text: dealer_groups[i].name,
        value: dealer_groups[i].id,
      });
    }

    commit("SET_DEALER_GROUPS", groups);
  },
};
