<template>
  <svg
    id="Layer_1"
    data-name="Layer 1"
    :height="height"
    :width="width"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 407.22 332.41"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="154.44"
        y1="220.63"
        x2="204.69"
        y2="239.78"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.01" />
        <stop offset="0.12" stop-opacity="0.75" />
        <stop offset="1" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-2"
        x1="128.92"
        y1="276.06"
        x2="113.67"
        y2="184.09"
        xlink:href="#linear-gradient"
      />
      <linearGradient
        id="linear-gradient-3"
        x1="134.36"
        y1="270.94"
        x2="159.48"
        y2="214.41"
        xlink:href="#linear-gradient"
      />
      <linearGradient
        id="linear-gradient-4"
        x1="262.98"
        y1="213.1"
        x2="219.92"
        y2="357.78"
        xlink:href="#linear-gradient"
      />
      <linearGradient
        id="linear-gradient-5"
        x1="85.31"
        y1="219.96"
        x2="127.03"
        y2="417.81"
        xlink:href="#linear-gradient"
      />
      <linearGradient
        id="linear-gradient-6"
        x1="264.92"
        y1="268.06"
        x2="265.51"
        y2="240.84"
        gradientTransform="translate(530.39 510.8) rotate(180)"
        xlink:href="#linear-gradient"
      />
      <linearGradient
        id="linear-gradient-7"
        x1="307.69"
        y1="284.87"
        x2="311.73"
        y2="182.58"
        xlink:href="#linear-gradient"
      />
    </defs>
    <title>Web Developer</title>
    <rect
      x="36.21"
      y="12.31"
      width="370.4"
      height="237.25"
      rx="8.95"
      :fill="iconColor"
      opacity="0.48"
      style="isolation: isolate"
    />
    <path
      d="M404.17,279.66c-.27,7.77-2.05,15.68-6.4,22.12-5.48,8.11-14.38,13.13-23,17.85l-24.44,13.45c-10.71,5.9-22,11.94-34.21,12.11-8.64.13-17-2.72-25.31-5.11C252,328.88,211.12,327.31,171,330.14c-20.55,1.45-41,4-61.36,7.07-16.29,2.42-35.84,10.47-50.53.94-16-10.4-25.82-27.24-34.86-43.59C16.38,280.37,8.4,265.83,5.8,249.82c-4-24.74,5.58-50.15,20.57-70.25a161.15,161.15,0,0,1,14.73-17V253.4a9,9,0,0,0,8.95,9H402.54A70.17,70.17,0,0,1,404.17,279.66Z"
      transform="translate(-4.89 -12.78)"
      :fill="iconColor"
      opacity="0.18"
      style="isolation: isolate"
    />
    <path
      d="M272.47,25.1H178.22a56.62,56.62,0,0,1,11.48-6.76c7.45-3.25,15.57-4.7,23.67-5.28C233.75,11.59,254.19,16.09,272.47,25.1Z"
      transform="translate(-4.89 -12.78)"
      :fill="iconColor"
      opacity="0.18"
      style="isolation: isolate"
    />
    <path
      d="M186.23,232.48a14.62,14.62,0,0,0,3.27,3.16,15.28,15.28,0,0,0,6,1.78l31.92,4.81a1.85,1.85,0,0,1,.89.3,1.82,1.82,0,0,1,.47,1.44,56.17,56.17,0,0,1-2.39,17.66,2.44,2.44,0,0,1-.65,1.24,2.56,2.56,0,0,1-1.46.44c-10.74.91-21.53.62-32.3.33a22,22,0,0,1-5.38-.59c-3-.84-5.45-2.89-7.71-5-4.85-4.51-24.48-22.64-25.67-25.1a67.19,67.19,0,0,1-3.8-10.92c-1-3.6,6.31-4.05,6.16-7.79a24.17,24.17,0,0,1,.92-7.66,13.08,13.08,0,0,1,3.2-5.92c1.1-1-.6-7.78.92-7.86,1.76,6,9.72,18,13.18,23.14A193.68,193.68,0,0,0,186.23,232.48Z"
      transform="translate(-4.89 -12.78)"
      :fill="iconColor"
    />
    <path
      d="M186.23,232.48a14.62,14.62,0,0,0,3.27,3.16,15.28,15.28,0,0,0,6,1.78l31.92,4.81a1.85,1.85,0,0,1,.89.3,1.82,1.82,0,0,1,.47,1.44,56.17,56.17,0,0,1-2.39,17.66,2.44,2.44,0,0,1-.65,1.24,2.56,2.56,0,0,1-1.46.44c-10.74.91-21.53.62-32.3.33a22,22,0,0,1-5.38-.59c-3-.84-5.45-2.89-7.71-5-4.85-4.51-24.48-22.64-25.67-25.1a67.19,67.19,0,0,1-3.8-10.92c-1-3.6,6.31-4.05,6.16-7.79a24.17,24.17,0,0,1,.92-7.66,13.08,13.08,0,0,1,3.2-5.92c1.1-1-.6-7.78.92-7.86,1.76,6,9.72,18,13.18,23.14A193.68,193.68,0,0,0,186.23,232.48Z"
      transform="translate(-4.89 -12.78)"
      fill="url(#linear-gradient)"
    />
    <path
      d="M105.46,181.82a58.54,58.54,0,0,0-6,6.2c-4.42,5.43-7.36,11.89-10.1,18.33-7.29,17.14-13.64,35.47-11.75,54a7,7,0,0,0,.88,3.18,6.19,6.19,0,0,0,3.43,2.21c3.51,1.12,7.26,1.17,11,1.2,14.41.13,28.84.26,43.24-.44,2.23-.11,4.59-.28,6.44-1.54a10.44,10.44,0,0,0,3.14-3.93c3.87-7.28,5-15.7,8-23.36,4-10,15-19.09,12.68-29.63C156.5,163.29,110,177.69,105.46,181.82Z"
      transform="translate(-4.89 -12.78)"
      :fill="iconColor"
    />
    <path
      d="M105.46,181.82a58.54,58.54,0,0,0-6,6.2c-4.42,5.43-7.36,11.89-10.1,18.33-7.29,17.14-13.64,35.47-11.75,54a7,7,0,0,0,.88,3.18,6.19,6.19,0,0,0,3.43,2.21c3.51,1.12,7.26,1.17,11,1.2,14.41.13,28.84.26,43.24-.44,2.23-.11,4.59-.28,6.44-1.54a10.44,10.44,0,0,0,3.14-3.93c3.87-7.28,5-15.7,8-23.36,4-10,15-19.09,12.68-29.63C156.5,163.29,110,177.69,105.46,181.82Z"
      transform="translate(-4.89 -12.78)"
      fill="url(#linear-gradient-2)"
    />
    <path
      d="M170.58,144.75c-1.69,8.21-3.46,16.64-8,23.71a18.36,18.36,0,0,1-6.93,6.82c-2.93,1.47-6.65,1.67-9.39-.13-1.37-.89-2.87-2.29-4.36-1.63-1.08.49-1.43,1.83-1.58,3a30.2,30.2,0,0,0,.12,8.65,7.31,7.31,0,0,1,.14,2.46,4.06,4.06,0,0,1-3.25,3,7.75,7.75,0,0,1-4.53-.71,14.64,14.64,0,0,1-8.44-10.82,35.06,35.06,0,0,1-.3-4.8c-.19-7.12-1.32-14.21-1.09-21.32s2-14.51,6.83-19.76a31.12,31.12,0,0,1,9.76-6.76c5.45-2.6,11.53-4.43,17.49-3.48a16.62,16.62,0,0,1,11.57,7.72C171.74,135.49,171.75,139.06,170.58,144.75Z"
      transform="translate(-4.89 -12.78)"
      fill="#ffdad2"
    />
    <path
      d="M185.47,126.66A16.55,16.55,0,0,0,176.72,110c-2.65-1.32-5.6-1.88-8.46-2.64-6.7-1.8-13.13-4.78-20-5.42s-14.77,1.77-17.83,8c-.56,1.14-1.11,2.52-2.34,2.82a5.13,5.13,0,0,1-2-.18c-4.59-.81-8.72,3.11-10.79,7.28-3.34,6.75-3.51,14.62-2.78,22.11.67,6.93,2.44,14.42,8,18.64.55.42,1.36.8,1.89.35a1.58,1.58,0,0,0,.43-.93,30.4,30.4,0,0,0,.62-8c-.11-2-.43-4.12.09-6.09s2.19-3.84,4.23-3.78c1.89,0,3.41,1.76,3.88,3.6a13.28,13.28,0,0,1-.21,5.64c.3.75,1.35-.15,1.53-.93,1.38-6.28,1.69-13.31,6.05-18a7.73,7.73,0,0,1,1.88,3.27,5,5,0,0,1,5.15-1.28,12.24,12.24,0,0,1,3.44,2.25C160.12,145,183.24,142.54,185.47,126.66Z"
      transform="translate(-4.89 -12.78)"
      fill="#3f3d56"
    />
    <path
      d="M108.52,192.06c-3.36,2.44-5,6.73-5.1,10.87s4.28,30.41,8.41,37.87c3.2,5.8,9.87,20.94,20,25.12,3,1.24,47.35.72,67.93-1.51-.13-4.87-.37-9.74-.69-14.61a2.77,2.77,0,0,0-.55-1.79c-.52-.54-1.26-4.07-2-4.07-12,.05-24.1,3.72-36.1,4a21.12,21.12,0,0,1-5.21-.31,15,15,0,0,1-4-1.64c-9.26-5.24-14.38-15.53-18-25.54-2.77-7.72-5-15.71-9-22.92-1.42-2.57-2.69-5.07-5.49-6.2C115.43,190,111.57,189.85,108.52,192.06Z"
      transform="translate(-4.89 -12.78)"
      :fill="iconColor"
    />
    <path
      d="M108.52,192.06c-3.36,2.44-5,6.73-5.1,10.87s4.28,30.41,8.41,37.87c3.2,5.8,9.87,20.94,20,25.12,3,1.24,47.35.72,67.93-1.51-.13-4.87-.37-9.74-.69-14.61a2.77,2.77,0,0,0-.55-1.79c-.52-.54-1.26-4.07-2-4.07-12,.05-24.1,3.72-36.1,4a21.12,21.12,0,0,1-5.21-.31,15,15,0,0,1-4-1.64c-9.26-5.24-14.38-15.53-18-25.54-2.77-7.72-5-15.71-9-22.92-1.42-2.57-2.69-5.07-5.49-6.2C115.43,190,111.57,189.85,108.52,192.06Z"
      transform="translate(-4.89 -12.78)"
      fill="url(#linear-gradient-3)"
    />
    <path
      d="M198,243c6.23-.59,13.15.8,17.1,5.65a3,3,0,0,1,.78,1.6,2.68,2.68,0,0,1-.48,1.51c-2.33,3.87-7.39,4.79-11.64,6.32a30.22,30.22,0,0,0-6.29,3.12,10,10,0,0,1-3.76,1.86c-3,.45-5.49-2.23-6.85-4.91-1.56-3-4-9.16-1-12C188.31,243.9,194.92,243.28,198,243Z"
      transform="translate(-4.89 -12.78)"
      fill="#ffdad2"
    />
    <path
      d="M249.59,241.46a8.53,8.53,0,0,1,2.79,2.55,3.67,3.67,0,0,1,.26,3.63,5.05,5.05,0,0,1-1.87,1.68c-5.53,3.29-12.31,3.88-17.91,7A9.73,9.73,0,0,1,229,258a5.33,5.33,0,0,1-4.28-2,13.6,13.6,0,0,1-2.31-4.27C215.86,234.53,240.17,235.46,249.59,241.46Z"
      transform="translate(-4.89 -12.78)"
      fill="#ffdad2"
    />
    <path
      d="M86.23,221h25.91a0,0,0,0,1,0,0v21.28a7.5,7.5,0,0,1-7.5,7.5H93.73a7.5,7.5,0,0,1-7.5-7.5V221A0,0,0,0,1,86.23,221Z"
      fill="#fff"
    />
    <path
      d="M110.39,226.16h7.85a6.25,6.25,0,0,1,6.25,6.25V235a6.25,6.25,0,0,1-6.25,6.25h-7.85a0,0,0,0,1,0,0V226.16A0,0,0,0,1,110.39,226.16Z"
      fill="none"
      stroke="#fff"
      stroke-miterlimit="10"
      stroke-width="3.3"
    />
    <rect x="45.12" y="249.32" width="362.1" height="11.11" rx="5.56" :fill="iconColor" />
    <rect x="138.75" y="260.6" width="198.39" height="68.81" :fill="iconColor" />
    <rect x="62.17" y="260.6" width="77.93" height="68.81" :fill="iconColor" />
    <rect x="140.06" y="260.6" width="197.09" height="68.81" fill="url(#linear-gradient-4)" />
    <rect x="62.17" y="260.6" width="77.93" height="68.81" fill="url(#linear-gradient-5)" />
    <path
      d="M411.49,34.05v43.8H41.1V34.05a9,9,0,0,1,8.95-9H402.54A9,9,0,0,1,411.49,34.05Z"
      transform="translate(-4.89 -12.78)"
      :fill="iconColor"
      opacity="0.18"
      style="isolation: isolate"
    />
    <rect
      x="194.46"
      y="248.88"
      width="141.48"
      height="13.04"
      rx="6.52"
      transform="translate(525.5 498.01) rotate(-180)"
      :fill="iconColor"
    />
    <rect
      x="194.46"
      y="248.88"
      width="141.48"
      height="13.04"
      rx="6.52"
      transform="translate(525.5 498.01) rotate(-180)"
      fill="url(#linear-gradient-6)"
    />
    <path
      d="M245.51,261.93h98.57l26-65.93a5.8,5.8,0,0,0-5.39-7.92H287.34A18.68,18.68,0,0,0,270,199.9Z"
      transform="translate(-4.89 -12.78)"
      :fill="iconColor"
    />
    <path
      d="M250.52,262h93.62l24.69-62.63a5.5,5.5,0,0,0-5.12-7.52H290.25a17.73,17.73,0,0,0-16.5,11.22Z"
      transform="translate(-4.89 -12.78)"
      :fill="iconColor"
    />
    <path
      d="M250.52,262h93.62l24.69-62.63a5.5,5.5,0,0,0-5.12-7.52H290.25a17.73,17.73,0,0,0-16.5,11.22Z"
      transform="translate(-4.89 -12.78)"
      fill="url(#linear-gradient-7)"
    />
    <rect x="213.02" y="154.61" width="138.6" height="7.8" fill="#fff" opacity="0.51" />
    <rect x="213.02" y="141.26" width="98.53" height="7.8" fill="#fff" />
    <rect x="325.02" y="141.26" width="65.96" height="7.8" fill="#fff" />
    <rect x="289.34" y="91.88" width="101.41" height="6.93" fill="#fff" />
    <rect x="268.1" y="104.19" width="108.49" height="6.93" fill="#fff" opacity="0.51" />
    <rect x="214.9" y="91.88" width="66.7" height="6.93" fill="#fff" />
    <rect x="244.16" y="116.13" width="146.59" height="6.93" fill="#fff" />
    <rect x="214.9" y="116.13" width="21.52" height="6.93" fill="#fff" opacity="0.51" />
    <rect x="293.99" y="128.44" width="96.76" height="6.93" fill="#fff" opacity="0.51" />
    <rect x="214.9" y="128.44" width="66.7" height="6.93" fill="#fff" />
    <rect x="214.9" y="103.9" width="42.89" height="6.93" fill="#fff" />
    <rect x="252.25" y="68.55" width="101.41" height="6.93" fill="#fff" />
    <rect x="231.01" y="80.86" width="108.49" height="6.93" fill="#fff" opacity="0.51" />
    <rect x="177.82" y="68.55" width="66.7" height="6.93" fill="#fff" />
    <rect x="251.35" y="54.2" width="101.41" height="6.93" fill="#fff" />
    <rect x="176.92" y="41.18" width="66.7" height="6.93" fill="#fff" />
    <rect x="176.92" y="29.3" width="66.7" height="6.93" fill="#fff" />
    <rect x="177.82" y="80.57" width="42.89" height="6.93" fill="#fff" />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 300,
    },
    height: {
      type: [Number, String],
      default: 300,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>

<style scoped>
svg {
  max-width: 100%;
}
</style>
