import apiSalesConsultantsService from "@/services/api/modules/salesConsultantsService";

export default {
  async getSalesConsultants({ commit }, payload) {
    commit("SET_SALES_CONSULTANTS", []);

    const { status, data } = await apiSalesConsultantsService.list(payload);

    if (status !== 200) {
      const errorMessage = data.errors ? Object.values(data.errors).join(" ") : data.message;
      dispatch(
        "snackbar/show",
        {
          text: errorMessage,
          timeout: 10000,
        },
        {
          root: true,
        }
      );
    } else {
      commit("SET_SALES_CONSULTANTS", data.data);
    }
  },
  setSalesConsultant({ commit }, type) {
    commit("SET_SALES_CONSULTANT", type);
  },
};
