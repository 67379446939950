import units from "../routes/children";

let children = null;

if (localStorage.user) {
  const user = JSON.parse(localStorage.user);
  if (user.is_admin) {
    children = [].concat(units);
  }
}

export default [
  {
    name: "dashboard",
    meta: {
      title: "Reports",
      sort: 10,
      authenticatable: true,
      description: "Inquiry",
      icon: "mdi-chart-box-outline",
      permission: false,
      roles: ["admin"],
    },
    children: children,
  },
];