export default {
  surveys: [],
  cdcs: [],
  cdc: [],
  notifications: [],
  results: [],
  result: null,
  textblast_logs: [],
  categories: [],
  factors: [],
};
