import api from "@/services/api/baseService";

class ApiSurveysService {
  list(payload) {
    return api
      .get("/surveys", {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
        params: { ...payload },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }

  getSurveyCsOnlineRanking(
    page,
    per_page,
    dates = [],
    region = [],
    zones = [],
    group = [],
    dealer = null,
    orderBy = 'csi_score',
    sortDir = 'desc'
  ) {
    return api
      .get("/survey-csonline-rankings", {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
        params: {
          page,
          per_page,
          dates,
          region,
          zones,
          group,
          dealer,
          order_by: orderBy,
          sort: sortDir
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }

  getSurveyDcsiRanking(
    page,
    per_page,
    dates = [],
    region = [],
    zones = [],
    groups = [],
    dealer = null,
    orderBy = 'csi_score',
    sortDir = 'desc'
  ) {
    return api
      .get("/survey-dcsi-rankings", {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
        params: {
          per_page,
          page,
          dates,
          region,
          zones,
          groups,
          dealer,
          order_by: orderBy,
          sort: sortDir
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }

  getSurveyDssiRanking(
    page,
    per_page,
    dates = [],
    region = [],
    zones = [],
    groups = [],
    dealer = null,
    orderBy = 'csi_score',
    sortDir = 'desc'
  ) {
    return api
      .get("/survey-dssi-rankings", {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
        params: {
          per_page,
          page,
          dates,
          region,
          zones,
          groups,
          dealer,
          order_by: orderBy,
          sort: sortDir
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }

  downloadDcsiReport(dates = [], region = [], zones = [], groups = [], dealer = null) {
    return api
      .get("/survey-dcsi-rankings/export/ranking", {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
        params: {
          dates,
          region,
          zones,
          groups,
          dealer,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }

  downloadDssiReport(dates = [], region = [], zones = [], groups = [], dealer = null) {
    return api
      .get("/survey-dssi-rankings/export/ranking", {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
        params: {
          dates,
          region,
          zones,
          groups,
          dealer,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }

  downloadCsReport(dates = [], region = [], zones = [], groups = [], dealer = null) {
    return api
      .get("/survey-csonline-rankings/export/ranking", {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
        params: {
          dates,
          region,
          zones,
          groups,
          dealer,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }

  getEmailNotifications(params) {
    return api
      .get("/survey-email-logs", {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
        params: {
          page: params.page,
          per_page: params.per_page,
          q: params.q,
          date: params.date,
          sort: params.sort,
          order_by: params.order_by,
          dealers: params.dealers,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }

  downloadEmailNotificationReport(q = "", date = []) {
    return api
      .get("/survey-email-logs/export", {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
        params: {
          q,
          date,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }

  find(id) {
    return api
      .get("/surveys/" + id, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }

  create(survey) {
    return api
      .post("/surveys", survey, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }

  updateSurveys(payload) {
    return api
      .put(
        "/surveys-bulk-update",
        {
          ids: payload.ids,
          status: payload.status,
        },
        {
          headers: {
            // Authorization: `Bearer ${localStorage.token}`,
          },
        }
      )
      .then((response) => response)
      .catch((error) => error.response);
  }

  deactivateSurveys(payload) {
    return api
      .put(
        "/surveys-bulk-update",
        {
          ids: payload.ids,
          status: 0,
        },
        {
          headers: {
            // Authorization: `Bearer ${localStorage.token}`,
          },
        }
      )
      .then((response) => response)
      .catch((error) => error.response);
  }

  deleteSurveys(payload) {
    return api
      .delete("/surveys-bulk-delete", {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
        data: {
          ids: payload.ids,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }

  update(survey, id) {
    return api
      .put("/surveys/" + id, survey, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }

  getResultsBySurveyId(page, per_page, q, survey_id, date, regions, zones, groups, dealers) {
    return api
      .get("/survey-results", {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
        params: {
          page,
          per_page,
          q,
          survey_id,
          date,
          regions,
          zones,
          groups,
          dealers,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }

  getCdcsPerSurvey(payload) {
    return api
      .get("/surveys-cdcs", {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
        params: { ...payload },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }

  getCSCdcs(payload) {
    return api
      .get("/survey-csonline-cdcs", {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
        params: { ...payload },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }

  getDSSICdcs(payload) {
    return api
      .get("/survey-dssi-cdcs", {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
        params: { ...payload },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }

  findResult(id) {
    return api
      .get("/survey-results/" + id, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }

  findCdc(id) {
    return api
      .get("/surveys-cdcs/" + id, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }

  downloadSurveyResult(q, survey_id, date, regions, zones, groups, dealers) {
    return api
      .get(`/survey-results/export/result`, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
        params: {
          q,
          survey_id,
          date,
          regions,
          zones,
          groups,
          dealers,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }

  downloadDcsiRaw(q, survey_id, date, regions, zones, groups, dealers) {
    return api
      .get(`/survey-dcsi-report/export`, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
        params: {
          q,
          survey_id,
          date,
          regions,
          zones,
          groups,
          dealers,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }

  getTextBlastLogs(payload) {
    return api.get("survey-sms-blasts/logs", {
      headers: {
        // Authorization: `Bearer ${localStorage.token}`,
      },
      params: { ...payload },
    });
  }

  downloadTextBlastLogs(id, q, date = []) {
    return api.get("survey-sms-blasts/export?survey_sms_blast_id=" + id, {
      headers: {
        // Authorization: `Bearer ${localStorage.token}`,
      },
      params: {
        id,
        q,
        date,
      },
    });
  }

  downloadCdc(q, survey_id, date, regions, zones, groups, dealers) {
    return api.get(`/surveys-cdcs/export/result`, {
      headers: {
        // Authorization: `Bearer ${localStorage.token}`,
      },
      params: {
        q,
        survey_id,
        date,
        regions,
        zones,
        groups,
        dealers,
      },
    });
  }

  downloadCSCdc(q, date, regions, zones, groups, dealers) {
    return api.get(`/survey-csonline-cdcs/export/result`, {
      headers: {
        // Authorization: `Bearer ${localStorage.token}`,
      },
      params: {
        q,
        date,
        regions,
        zones,
        groups,
        dealers,
      },
    });
  }

  downloadDSSICdc(q, date, regions, zones, groups, dealers) {
    return api.get(`/survey-dssi-cdcs/export/result`, {
      headers: {
        // Authorization: `Bearer ${localStorage.token}`,
      },
      params: {
        q,
        date,
        regions,
        zones,
        groups,
        dealers,
      },
    });
  }

  surveyCategories(payload) {
    return api.get("/survey-categories", {
      headers: {
        // Authorization: `Bearer ${localStorage.token}`,
      },
      params: { ...payload },
    });
  }

  getFactorsByCategory(category) {
    return api
      .get("/survey-factors", {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
        params: {
          category,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
}

export default new ApiSurveysService();
