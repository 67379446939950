export default [
  {
    path: "survey/infobip-account",
    name: "infobip-account.index",
    component: () => import("../Index.vue"),
    meta: {
      title: "Infobip Accounts",
      description: "Infobip Accounts",
      sort: 0,
      authenticatable: true,
      permission: false,
    },
  },
  {
    path: "survey/infobip-account/create",
    name: "infobip-account.create",
    component: () => import("../Create.vue"),
    meta: {
      title: "Create Infobip Account",
      description: "Create Infobip Account",
      sort: 0,
      authenticatable: true,
      permission: false,
    },
  },
  {
    path: "survey/infobip-account/:id/edit",
    name: "infobip-account.edit",
    component: () => import("../Edit.vue"),
    meta: {
      title: "Edit Infobip Account",
      description: "Edit Infobip Account",
      sort: 0,
      authenticatable: true,
      permission: false,
    },
  },
];
