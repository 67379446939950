import apiDealerUserService from "@/services/api/modules/dealerUserService";

export default {
  async getDealerUsers({ commit }, page = {}) {
    commit("SET_DEALER_USERS", []);
    const { status, data } = await apiDealerUserService.getDealerUsers(
      page.page,
      page.per_page,
      page.q,
      page.dealer_id
    );

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
          timeout: 10000,
        },
        {
          root: true,
        }
      );
    } else {
      let responseData = Object.keys(page).length === 0 ? data.data : data;
      
      commit("SET_DEALER_USERS", responseData);
    }
  },
  async getDealerUser({ commit }, id) {
    commit("SET_DEALER_USER", null);
    const { status, data } = await apiDealerUserService.getDealerUser(id);

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
          timeout: 10000,
        },
        {
          root: true,
        }
      );
    } else {
      commit("SET_DEALER_USER", data.data);
    }
  },
  async save({ dispatch, commit }, details) {
    if (details.id) {
      var { status, data } = await apiDealerUserService.update(details.id, details.data);
    } else {
      var { status, data } = await apiDealerUserService.create(details.data);
    }

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
          timeout: 10000,
        },
        {
          root: true,
        }
      );
    } else {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
          timeout: 10000,
        },
        {
          root: true,
        }
      );

      commit("SET_DEALER_USER", data.data);
    }
  },
  async delete({ dispatch }, id) {
    const { status, data } = await apiDealerUserService.delete(id);

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
          timeout: 10000,
        },
        {
          root: true,
        }
      );
    } else {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
          timeout: 10000,
        },
        {
          root: true,
        }
      );
    }
  },
  async resendEmalVerificationDealerUser({ dispatch }, payload) {
    const { status, data } = await apiDealerUserService.resendEmailVerification(payload);

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
          timeout: 10000,
        },
        {
          root: true,
        }
      );
    } else {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
          timeout: 10000,
        },
        {
          root: true,
        }
      );
    }
  }
};
