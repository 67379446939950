<template v-slot:activator="{ on, attrs }">
  <div>
    <v-row>
      <v-col cols="12">
        <v-autocomplete
          v-if="!is_edit"
          append-icon="mdi-chevron-down"
          :items="regions"
          item-text="name"
          item-value="name"
          label="Region"
          outlined
          clearable
          return-object
          hide-details
          clear-icon="mdi-close-circle-outline"
          background-color="selects"
          prepend-inner-icon="mdi-account-outline"
          v-model="form.region"
          @change="updateRegion"
        >
          <template v-slot:no-data>
            <v-card-text class="pa-2">
              {{ "No data found" }}
            </v-card-text>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12">
        <v-autocomplete
          append-icon="mdi-chevron-down"
          :items="provinces"
          item-text="name"
          item-value="name"
          label="Province"
          outlined
          clearable
          return-object
          hide-details
          clear-icon="mdi-close-circle-outline"
          background-color="selects"
          prepend-inner-icon="mdi-map-marker-multiple-outline"
          v-model="form.province"
          @change="updateProvince"
          :rules="rules"
        >
          <template v-slot:no-data>
            <v-card-text class="pa-2">
              {{ "No data found" }}
            </v-card-text>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12">
        <v-autocomplete
          append-icon="mdi-chevron-down"
          :items="cities"
          item-text="name"
          item-value="name"
          label="City / Municipality"
          outlined
          clearable
          return-object
          hide-details
          clear-icon="mdi-close-circle-outline"
          background-color="selects"
          prepend-inner-icon="mdi-office-building-marker-outline"
          v-model="form.city"
          @change="updateCities"
          :rules="rules"
        >
          <template v-slot:no-data>
            <v-card-text class="pa-2">
              {{ "No data found" }}
            </v-card-text>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12">
        <v-autocomplete
          append-icon="mdi-chevron-down"
          :items="barangays"
          item-text="name"
          item-value="name"
          label="Barangay"
          outlined
          clearable
          return-object
          hide-details
          clear-icon="mdi-close-circle-outline"
          background-color="selects"
          prepend-inner-icon="mdi-layers-outline"
          v-model="form.barangay"
          @change="updateBarangay"
          :rules="rules"
        >
          <template v-slot:no-data>
            <v-card-text class="pa-2">
              {{ "No data found" }}
            </v-card-text>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12">
        <v-text-field
          label="Zipcode"
          outlined
          clearable
          hide-details
          background-color="selects"
          v-model="form.zipcode"
          disabled
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { find } from "lodash";
import { mapActions, mapGetters } from "vuex";

export default {
  required: {
    type: Boolean,
    default: true,
  },
  props: {
    details: {
      required: true,
      type: Object,
    },
    is_edit: {
      required: false,
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      selectedRegion: null,
      rules: [],
      form: {
        region: null,
        barangay: null,
        city: null,
        province: null,
        zipcode: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      regions: "addresses/GET_REGIONS",
      provinces: "addresses/GET_PROVINCES",
      cities: "addresses/GET_CITIES",
      barangays: "addresses/GET_BARANGAYS",
    }),
  },
  created() {
    if (this.required) {
      this.rules = [(value) => !!value || 'Field is required'];
    }
  },
  async mounted() {
    // await this.getRegions();
    await this.getAllProvinces();
    await this.setProvince();
  },
  methods: {
    ...mapActions({
      getRegions: "addresses/getRegions",
      getProvinces: "addresses/getProvinces",
      getAllProvinces: "addresses/getAllProvinces",
      getProvinces: "addresses/getProvinces",
      getAllCities: "addresses/getAllCities",
      getCities: "addresses/getCities",
      getBarangays: "addresses/getBarangays",
    }),
    async setProvince() {
      const province = this.details.province;
      
      if (province) {
        this.form.province = await find(this.provinces, { name: province});
        await this.setCity();
      }
    },

    async setCity() {
      await this.getAllCities();
      this.form.city = await find(this.cities, { name: this.details.municipality });

      if (this.form.city) {
        await this.setBarangay();
      }
    },

    async setBarangay() {
      await this.getBarangays(this.form.city.id);
      this.form.barangay = await find(this.barangays, { name: this.details.barangay });

      if (this.form.barangay) {
        this.form.zipcode = this.form.barangay.code;
      }
    },

    async updateRegion() {
      await this.getProvinces(this.form.region.id);
      await this.emitAddress();
    },
    async updateProvince() {
      await this.getCities(this.form.province.id);
      await this.emitAddress();
    },
    async updateCities() {
      await this.getBarangays(this.form.city.id);
      await this.emitAddress();
    },
    async updateBarangay() {
      this.form.zipcode = this.form.barangay.code;
      await this.emitAddress();
    },
    emitAddress() {
      this.$emit("change:address", this.form);
    },
  },
};
</script>
