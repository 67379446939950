import apiReservationsService from "@/services/api/modules/reservationsService";
import apiStatusesService from "@/services/api/modules/statusesService";
import apiActionsService from "@/services/api/modules/actionsService";

export default {
  async list({ commit, dispatch }, page = {}) {
    commit("SET_RESERVATIONS", []);
    const { status, data } = await apiReservationsService.list(page);

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
        },
        {
          root: true,
        }
      );
    } else {
      let responseData = Object.keys(page).length === 0 ? data.data : data;

      commit("SET_RESERVATIONS", responseData);
    }
  },
  async getReservationStatuses({ commit, dispatch }) {
    commit("SET_RESERVATION_STATUSES", []);
    const { status, data } = await apiStatusesService.list("onlinereservation");

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
          timeout: 10000,
        },
        {
          root: true,
        }
      );
    } else {
      commit("SET_RESERVATION_STATUSES", data.data);
    }
  },
  async getReservationActions({ commit, dispatch }) {
    commit("SET_RESERVATION_ACTIONS", []);
    const { status, data } = await apiActionsService.list("onlinereservation");

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
          timeout: 10000,
        },
        {
          root: true,
        }
      );
    } else {
      commit("SET_RESERVATION_ACTIONS", data.data);
    }
  },
  async getReservation({ commit, dispatch }, id) {
    commit("SET_RESERVATION", null);
    const { status, data } = await apiReservationsService.getReservation(id);

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
          timeout: 10000,
        },
        {
          root: true,
        }
      );
    } else {
      commit("SET_RESERVATION", data.data);
    }
  },
  async save({ commit, dispatch }, payload) {
    if (payload.id) {
      var { status, data } = await apiReservationsService.update(payload.data, payload.id);
    } else {
      var { status, data } = await apiReservationsService.create(payload.data);
    }
    const message = data.errors ? Object.values(data.errors).join(" ") : data.message;
    dispatch(
      "snackbar/show",
      {
        text: message,
      },
      {
        root: true,
      }
    );
    if (status == 200) {
      commit("SET_RESERVATION", data.data);
    }
  },
  async uploadReservation({ commit, dispatch }, payload) {
    const { status, data } = await apiReservationsService.upload(payload);
    const message = data.errors ? Object.values(data.errors).join(" ") : data.message;
    dispatch(
      "snackbar/show",
      {
        text: message,
      },
      {
        root: true,
      }
    );
  },
  async download({ commit, dispatch }) {
    const { status, data } = await apiReservationsService.download();
    const errorMessage = data.errors
      ? Object.values(data.errors).join(" ")
      : "Failed to download reservations.";
    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: errorMessage,
        },
        {
          root: true,
        }
      );
    } else {
      const url = data.data.url;
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", data.data.filename);
      document.body.appendChild(link);
      link.click();
    }
  },
};
