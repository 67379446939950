import apiDealerPartService from "@/services/api/modules/dealerPartService";
import { DUMMY_GET_MASTERLIST_API_RESPONSE } from "../../statics";

export default {
  async getMasterlist({ commit, dispatch }, params = {}) {
    commit("SET_MASTERLIST", []);
    const { status, data } = await apiDealerPartService.getMasterlist(params);

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
          timeout: 10000,
        },
        {
          root: true,
        }
      );

      /**
       * only for testing
       */
      commit("SET_MASTERLIST", DUMMY_GET_MASTERLIST_API_RESPONSE);
    } else {
      
      let responseData = Object.keys(params).length === 0 ? data.data : data;

      commit("SET_MASTERLIST", responseData);
    }
  },

  async uploadMasterList({ commit, dispatch }, file) {
    const { status, data } = await apiDealerPartService.uploadMasterlist(file);
    const message = data.errors ? Object.values(data.errors).join(" ") : data.message;

    dispatch(
      "snackbar/show",
      {
        text: message,
        timeout: 10000,
      },
      {
        root: true,
      }
    );

    return data.success;
  },

  async getMasterlistItem({ commit, dispatch }, id) {
    commit("SET_MASTERLIST_ITEM", []);
    const { status, data } = await apiDealerPartService.getMasterlistItem(id);

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
          timeout: 10000,
        },
        {
          root: true,
        }
      );

      /**
       * only for testing
       */
      commit("SET_MASTERLIST_ITEM", DUMMY_GET_MASTERLIST_API_RESPONSE.data[0]);
    } else {
      
      commit("SET_MASTERLIST_ITEM", data.data);
    }
  },
};
