export default [
  {
    path: "/admin/dealer-parts/inventory",
    name: "admin.dealerParts.inventory",
    redirect: { name: "dealerParts.inventory.index" },
    component: () => import("@/App.vue"),
    meta: {
      title: "Inventory",
      sort: 13,
      authenticatable: false,
      icon: "mdi-package-variant-closed",
      roles: ["admin_parts", "dealer_parts"]
    },
    children: [
      {
        path: "/admin/dealer-parts/inventory",
        name: "dealerParts.inventory.index",
        component: () => import("../Index.vue"),
        meta: {
          title: "Inventory",
          description: "Dealer Parts Inventory",
          sort: 0,
          authenticatable: true,
          permission: false,
          icon: "mdi-package-variant-closed",
          roles: ["admin_parts", "dealer_parts"]
        },
      },
      {
        path: "/admin/dealer-parts/inventory/upload",
        name: "dealerParts.inventory.upload",
        component: () => import("../Upload.vue"),
        meta: {
          title: "Upload Inventory",
          description: "Upload Inventory",
          sort: 0,
          authenticatable: true,
          permission: false,
          icon: "mdi-package-variant-closed",
          roles: ["admin_parts", "dealer_parts"]
        },
      }
    ],
  },
];
