import surveyCdcService from "@/services/api/modules/surveyCdcService";

export default {
  async getImmediateMeasures({ commit, dispatch }, id) {
    commit("SET_IMMEDIATE_MEASURES", []);
    const { status, data } = await surveyCdcService.getImmediateMeasures();

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
        },
        {
          root: true,
        }
      );
    } else {
      commit("SET_IMMEDIATE_MEASURES", data.data);
    }
  },
  async getFactors({ commit, dispatch }, id) {
    commit("SET_FACTORS", []);
    const { status, data } = await surveyCdcService.getFactors();

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
        },
        {
          root: true,
        }
      );
    } else {
      commit("SET_FACTORS", data.data);
    }
  },
  async getAttributes({ commit, dispatch }, factor_id) {
    commit("SET_ATTRIBUTES", []);
    const { status, data } = await surveyCdcService.getAttributes(factor_id);

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
        },
        {
          root: true,
        }
      );
    } else {
      commit("SET_ATTRIBUTES", data.data);
    }
  },
  async getRootCauses({ commit, dispatch }, attribute_id) {
    commit("SET_ROOT_CAUSES", []);
    const { status, data } = await surveyCdcService.getRootCauses(attribute_id);

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
        },
        {
          root: true,
        }
      );
    } else {
      commit("SET_ROOT_CAUSES", data.data);
    }
  },
  async getDealerPreventiveMeasures({ commit, dispatch }, attribute_id) {
    commit("SET_DEALER_PREVENTIVE_MEASURES", []);
    const { status, data } = await surveyCdcService.getDealerPreventiveMeasures(attribute_id);

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
        },
        {
          root: true,
        }
      );
    } else {
      commit("SET_DEALER_PREVENTIVE_MEASURES", data.data);
    }
  },
  async save({ commit, dispatch }, payload) {
    commit("SET_SURVEY_RESULT_CDC", []);
    var { status, data } = await surveyCdcService.update(payload.id, payload.data);

    const message = data.errors ? Object.values(data.errors).join("<br>") : data.message;

    dispatch(
      "snackbar/show",
      {
        text: message,
      },
      {
        root: true,
      }
    );
    if (status === 200) {
      commit("SET_SURVEY_RESULT_CDC", data.data);
    }
  },
  async getFactorsCount(context, payload) {
    const { status, data } = await surveyCdcService.getFactorsCount(payload);

    if (status === 200) {
      return data.data
    }

    return null
  },
  async getCSFactorsCount(context, payload) {
    const { status, data } = await surveyCdcService.getCSFactorsCount(payload);

    if (status === 200) {
      return data.data
    }

    return null
  },
  async getDSSIFactorsCount(context, payload) {
    const { status, data } = await surveyCdcService.getDSSIFactorsCount(payload);

    if (status === 200) {
      return data.data
    }

    return null
  },
};
