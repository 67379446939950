import apiAddressesService from "@/services/api/modules/addressesService";

export default {
  async getRegions({ commit }) {
    commit("SET_REGIONS", []);
    const { status, data } = await apiAddressesService.list();

    if (status == 200) {
      commit("SET_REGIONS", data.data);
    }
  },
  async getAllProvinces({ commit }) {
    commit("SET_PROVINCES", []);
    var { status, data } = await apiAddressesService.getAllProvinces();
    if (status == 200) {
      commit("SET_PROVINCES", data.data);
    }
  },
  async getProvinces({ commit }, payload) {
    commit("SET_PROVINCES", []);
    var { status, data } = await apiAddressesService.getProvinces(payload);
    if (status == 200) {
      commit("SET_PROVINCES", data.data);
    }
  },
  async getAllCities({ commit }) {
    commit("SET_CITIES", []);
    var { status, data } = await apiAddressesService.getAllCities();
    if (status == 200) {
      commit("SET_CITIES", data.data);
    }
  },
  async getCities({ commit }, payload) {
    commit("SET_CITIES", []);
    var { status, data } = await apiAddressesService.getCities(payload);
    if (status == 200) {
      commit("SET_CITIES", data.data);
    }
  },
  async getBarangays({ commit }, payload) {
    commit("SET_BARANGAYS", []);
    var { status, data } = await apiAddressesService.getBarangays(payload);

    if (status == 200) {
      commit("SET_BARANGAYS", data.data);
    }
  },
};
