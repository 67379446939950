import apiGraphService from "@/services/api/modules/graphService";

export default {
  async getGraphData({ commit, dispatch }, filter = {}) {
    commit("SET_GRAPH_DATA", []);
    const { status, data } = await apiGraphService.graph(
      filter.dealer_zone,
      filter.dealer_group,
      filter.dealer_id,
      filter.date
    );

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
        },
        {
          root: true,
        }
      );
    } else {
      commit("SET_GRAPH_DATA", data);
    }
  },
};
