export default [
  {
    name: "news.index",
    meta: {
      title: "News",
      sort: 1,
      authenticatable: true,
      description: "Manage news",
      icon: "mdi-bullhorn-variant-outline",
      permission: false,
      roles: ["admin"],
    },
  },
];
