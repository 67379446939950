import api from "@/services/api/baseService";

class apiDealerUserService {
  getDealerUsers(page, per_page, q, dealer_id) {
    return api
      .get("/dealer/users", {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
        params: {
          per_page,
          page,
          q,
          dealer_id,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  getDealerUser(id) {
    return api
      .get(`/dealer/users/${id}`, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  create(data) {
    return api
      .post(`/dealer/users`, data, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  update(id, data) {
    return api
      .put(`/dealer/users/${id}`, data, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  delete(id) {
    return api
      .delete(`/dealer/users/${id}`, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  resendEmailVerification(data) {
    return api
      .post('/dealer/users/resend/email-verification', data, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
}

export default new apiDealerUserService();
