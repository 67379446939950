import units from "../routes/children";

export default [
  {
    name: "dealerparts.index",
    meta: {
      title: "Dealer Parts",
      sort: 13,
      authenticatable: true,
      description: "Manage Dealer Parts",
      icon: "mdi-hammer-wrench",
      permission: false,
      roles: ["admin_parts", "dealer_parts"],
    },
    children: [].concat(units),
  },
];
