export default {
  toolbar: {
    model: "",
    title: "",
    tooltipName: "",
    filter: false,
    sort: false,
    listGridView: true,
    toggleview: true,
    showBulk: false,
    verticaldiv: true,
    actions: [],
    statuses: [],
  },
};
