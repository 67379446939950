import apiStatusesService from "@/services/api/modules/statusesService";
import apiActionsService from "@/services/api/modules/actionsService";

export default {
  update: ({ commit }, payload) => {
    commit("UPDATE", payload);
  },
  getStatuses: async ({ commit }, type) => {
    commit("UPDATE", { statuses: [] });
    const { status, data } = await apiStatusesService.list(type);

    if (status !== 200) {
      const errorMessage = data.errors ? Object.values(data.errors).join(" ") : data.message;
      dispatch(
        "snackbar/show",
        {
          text: errorMessage,
          timeout: 10000,
        },
        {
          root: true,
        }
      );
    } else {
      commit("UPDATE", { statuses: data.data });
    }
  },
  getActions: async ({ commit }, type) => {
    commit("UPDATE", { actions: [] });
    const { status, data } = await apiActionsService.list(type);

    if (status !== 200) {
      const errorMessage = data.errors ? Object.values(data.errors).join(" ") : data.message;
      dispatch(
        "snackbar/show",
        {
          text: errorMessage,
          timeout: 10000,
        },
        {
          root: true,
        }
      );
    } else {
      commit("UPDATE", { actions: data.data });
    }
  },
};
