export const DUMMY_GET_MASTERLIST_API_RESPONSE = {
  "data": [
    {
      "id": 23,
      "part_number": "04646SWJF00ZZ",
      "dealer": null,
      "description": "CLIP, FR. WSHLD UPR",
      "function_type": "General Repair",
      "functions": "Hose, Nut, Bolt",
      "model": "CR-V",
      "year": "2007",
      "reference_id": "A401CR-V2007"
    },
    {
      "id": 24,
      "part_number": "04646SWJF00ZZ",
      "dealer": null,
      "description": "CLIP, FR. WSHLD UPR",
      "function_type": "General Repair",
      "functions": "Hose, Nut, Bolt",
      "model": "CR-V",
      "year": "2008",
      "reference_id": "A401CR-V2008"
    },
    {
      "id": 25,
      "part_number": "04646SWJF00ZZ",
      "dealer": null,
      "description": "CLIP, FR. WSHLD UPR",
      "function_type": "General Repair",
      "functions": "Hose, Nut, Bolt",
      "model": "CR-V",
      "year": "2009",
      "reference_id": "A401CR-V2009"
    },
    {
      "id": 26,
      "part_number": "04646SWJF00ZZ",
      "dealer": null,
      "description": "CLIP, FR. WSHLD UPR",
      "function_type": "General Repair",
      "functions": "Hose, Nut, Bolt",
      "model": "CR-V",
      "year": "2010",
      "reference_id": "A401CR-V2010"
    },
    {
      "id": 27,
      "part_number": "04646SWJF00ZZ",
      "dealer": null,
      "description": "CLIP, FR. WSHLD UPR",
      "function_type": "General Repair",
      "functions": "Hose, Nut, Bolt",
      "model": "CR-V",
      "year": "2011",
      "reference_id": "A401CR-V2011"
    },
    {
      "id": 28,
      "part_number": "04674TM0T00ZZ",
      "dealer": null,
      "description": "SCREW, TAPPING, 4X12",
      "function_type": "General Repair",
      "functions": "Hose, Nut, Bolt",
      "model": "City",
      "year": "2009",
      "reference_id": "A401City2009"
    },
    {
      "id": 29,
      "part_number": "04674TM0T00ZZ",
      "dealer": null,
      "description": "SCREW, TAPPING, 4X12",
      "function_type": "General Repair",
      "functions": "Hose, Nut, Bolt",
      "model": "City",
      "year": "2010",
      "reference_id": "A401City2010"
    },
    {
      "id": 30,
      "part_number": "04674TM0T00ZZ",
      "dealer": null,
      "description": "SCREW, TAPPING, 4X12",
      "function_type": "General Repair",
      "functions": "Hose, Nut, Bolt",
      "model": "City",
      "year": "2011",
      "reference_id": "A401City2011"
    },
    {
      "id": 34,
      "part_number": "04711TF0G10ZZ",
      "dealer": null,
      "description": "CLIP, FR. CORN WSHLD",
      "function_type": "General Repair",
      "functions": "Hose, Nut, Bolt",
      "model": "Jazz",
      "year": "2012",
      "reference_id": "A401Jazz2012"
    },
    {
      "id": 35,
      "part_number": "04711TF0G10ZZ",
      "dealer": null,
      "description": "CLIP, FR. CORN WSHLD",
      "function_type": "General Repair",
      "functions": "Hose, Nut, Bolt",
      "model": "Jazz",
      "year": "2013",
      "reference_id": "A401Jazz2013"
    }
  ],
  "links": {
    "first": "http://127.0.0.1:8000/api/v1/parts/master-list?per_page=10&page=1",
    "last": "http://127.0.0.1:8000/api/v1/parts/master-list?per_page=10&page=114",
    "prev": "http://127.0.0.1:8000/api/v1/parts/master-list?per_page=10&page=2",
    "next": "http://127.0.0.1:8000/api/v1/parts/master-list?per_page=10&page=4"
  },
  "meta": {
    "current_page": 3,
    "from": 21,
    "last_page": 114,
    "links": [
      {
        "url": "http://127.0.0.1:8000/api/v1/parts/master-list?per_page=10&page=2",
        "label": "&laquo; Previous",
        "active": false
      },
      {
        "url": "http://127.0.0.1:8000/api/v1/parts/master-list?per_page=10&page=1",
        "label": "1",
        "active": false
      },
      {
        "url": "http://127.0.0.1:8000/api/v1/parts/master-list?per_page=10&page=2",
        "label": "2",
        "active": false
      },
      {
        "url": "http://127.0.0.1:8000/api/v1/parts/master-list?per_page=10&page=3",
        "label": "3",
        "active": true
      },
      {
        "url": "http://127.0.0.1:8000/api/v1/parts/master-list?per_page=10&page=4",
        "label": "4",
        "active": false
      },
      {
        "url": "http://127.0.0.1:8000/api/v1/parts/master-list?per_page=10&page=5",
        "label": "5",
        "active": false
      },
      {
        "url": "http://127.0.0.1:8000/api/v1/parts/master-list?per_page=10&page=6",
        "label": "6",
        "active": false
      },
      {
        "url": "http://127.0.0.1:8000/api/v1/parts/master-list?per_page=10&page=7",
        "label": "7",
        "active": false
      },
      {
        "url": "http://127.0.0.1:8000/api/v1/parts/master-list?per_page=10&page=8",
        "label": "8",
        "active": false
      },
      {
        "url": "http://127.0.0.1:8000/api/v1/parts/master-list?per_page=10&page=9",
        "label": "9",
        "active": false
      },
      {
        "url": "http://127.0.0.1:8000/api/v1/parts/master-list?per_page=10&page=10",
        "label": "10",
        "active": false
      },
      {
        "url": null,
        "label": "...",
        "active": false
      },
      {
        "url": "http://127.0.0.1:8000/api/v1/parts/master-list?per_page=10&page=113",
        "label": "113",
        "active": false
      },
      {
        "url": "http://127.0.0.1:8000/api/v1/parts/master-list?per_page=10&page=114",
        "label": "114",
        "active": false
      },
      {
        "url": "http://127.0.0.1:8000/api/v1/parts/master-list?per_page=10&page=4",
        "label": "Next &raquo;",
        "active": false
      }
    ],
    "path": "http://127.0.0.1:8000/api/v1/parts/master-list",
    "per_page": 10,
    "to": 30,
    "total": 1139
  },
  "success": true,
  "error_code": null,
  "message": null
}

export const DUMMY_GET_INQUIRIES_API_RESPONSE = {
  "data": [{
    "id": 9562,
    "first_name": "John",
    "last_name": "Doe",
    "mobile_number": "629999844441",
    "email": "developer@test.com",
    "message": "This is a test inquiry.",
    "total_quantity": 12,
    "total_amount": 1641.00,
    "status": "pending",
    "updated_by_user_id": 1,
    "updated_at": "2022-10-04T09:38:34.000000Z",
    "created_at": "2022-09-29T02:29:49.000000Z"
  }],
  "links": {
    "first": "http://hondadealerportalapi.sdisend.com/api/v1/leads?per_page=5&page=1",
    "last": "http://hondadealerportalapi.sdisend.com/api/v1/leads?per_page=5&page=25",
    "prev": null,
    "next": "http://hondadealerportalapi.sdisend.com/api/v1/leads?per_page=5&page=2"
  },
  "meta": {
    "current_page": 1,
    "from": 1,
    "last_page": 25,
    "links": [{
      "url": "http://hondadealerportalapi.sdisend.com/api/v1/leads?per_page=5&page=1",
      "label": "1",
      "active": true
    }],
    "path": "http://hondadealerportalapi.sdisend.com/api/v1/leads",
    "per_page": 5,
    "to": 5,
    "total": 121
  },
  "success": true,
  "error_code": null,
  "message": null
}