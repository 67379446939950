export default [
  {
    path: "notifications",
    name: "notifications",
    component: () => import("../Notifications.vue"),
    meta: {
      title: "Notifications",
      description: "Notifications",
      sort: 0,
      authenticatable: true,
      permission: false,
      icon: "notifications",
    },
  },
];
