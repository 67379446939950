export default [
    {
      path: "/admin/dealer-parts/form-inquiries",
      name: "admin.dealerParts.form.inquries",
      redirect: { name: "dealerParts.form.inquiries.index" },
      component: () => import("@/App.vue"),
      meta: {
        title: "Form Inquiries",
        sort: 13,
        authenticatable: false,
        icon: "mdi-format-list-numbered-rtl",
      },
      children: [
        {
          path: "/admin/dealer-parts/form-inquiries",
          name: "dealerParts.form.inquiries.index",
          component: () => import("../Index.vue"),
          meta: {
            title: "Form Inquiries",
            description: "Dealer Parts Form Inquiries",
            sort: 0,
            authenticatable: true,
            permission: false,
            icon: "mdi-format-list-numbered-rtl",
          }
        }
      ],
    },
  ];
  