const DEFAULT_DAYS_TO_EXPIRE = ((20 / 60) / 24) // 20 mins
// const DEFAULT_DAYS_TO_EXPIRE = 1 // 1 day

export function setCookie(name, value, daysToExpires = null) {
    if (!daysToExpires) {
        daysToExpires = DEFAULT_DAYS_TO_EXPIRE
    }
    
    const date = new Date();
    date.setTime(date.getTime() + (daysToExpires * 24 * 60 * 60 * 1000)); // milliseconds

    let expires = `expires= ${date.toUTCString()}`;
    const path = "path= /"

    // document.cookie = `${name}=${value}; ${expires}; ${path}; secure`
}

export function getCookie(name) {
    const cookieDecoded = decodeURIComponent(document.cookie)
    const cookies = cookieDecoded.split("; ");
    let result = null;

    cookies.forEach(item => {
        if (item.indexOf(name) == 0) {
            result = item.substring(name.length + 1)
        }
    })

    return result;
}

export function removeCookie(name) {
    setCookie(name, null, null)
}

export function moveLocalStorageTokenToCookie() {
    if (localStorage.token) {
        localStorage.removeItem("token");
    }
}