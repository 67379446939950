export default [
    {
        name: "dealerParts.masterlist.index",
        meta: {
          title: "Master List",
          sort: 0,
          authenticatable: true,
          description: "Inventory Master List",
          icon: "mdi-format-list-bulleted",
          permission: false,
          roles: ["admin_parts"],
        },
      },
      {
        name: "dealerParts.inventory.index",
        meta: {
          title: "Inventory",
          sort: 0,
          authenticatable: true,
          description: "Manage Dealer Inventory",
          icon: "mdi-package-variant-closed",
          permission: false,
          roles: ["admin_parts", "dealer_parts"],
        },
      },
      {
        name: "dealerParts.cart.inquiries.index",
        meta: {
          title: "Cart Inquiries",
          sort: 0,
          authenticatable: true,
          description: "Manage Dealer Cart Inquiries",
          icon: "mdi-format-list-numbered-rtl",
          permission: false,
          roles: ["dealer_parts"],
        },
      },
      {
        name: "dealerParts.cart.inquiries.admin.index",
        meta: {
          title: "Cart Inquiries",
          sort: 0,
          authenticatable: true,
          description: "Manage Dealer Cart Inquiries",
          icon: "mdi-format-list-numbered-rtl",
          permission: false,
          roles: ["admin_parts"],
        },
      },
      {
        name: "dealerParts.form.inquiries.index",
        meta: {
          title: "Form Inquiries",
          sort: 0,
          authenticatable: true,
          description: "Manage Dealer Form Inquiries",
          icon: "mdi-format-list-numbered-rtl",
          permission: false,
          roles: ["admin_parts", "dealer_parts"],
        },
      },
];