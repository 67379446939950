import api from "@/services/api/baseService";

class apiDealerSalesConsultantService {
  getDealers(page, per_page, q, region_types = [], zones = [], groups = []) {
    return api
      .get("/dealers", {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
        params: {
          per_page,
          page,
          q,
          region_types,
          zones,
          groups,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }

  getDealer(id) {
    return api
      .get(`/dealers/${id}`, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  create(data) {
    return api
      .post(`/dealers`, data, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  update(id, data) {
    return api
      .put(`/dealers/${id}`, data, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  delete(id) {
    return api
      .delete(`/dealers/${id}`, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  getDealers2(payload) {
    return api
      .get("/dealers", {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
        params: { ...payload },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
}

export default new apiDealerSalesConsultantService();
