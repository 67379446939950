export default [
  {
    path: "online-reservations",
    name: "online-reservations",
    component: () => import("../OnlineReservations.vue"),
    meta: {
      title: "Online Reservations",
      description: "Online Reservations",
      sort: 0,
      authenticatable: true,
      permission: false,
      icon: "mdi-clipboard-text-outline",
      roles: ["admin", "dealer"]
    },
  },
  {
    path: "/admin/online-reservations/upload",
    name: "online-reservations.upload",
    component: () => import("../Upload.vue"),
    meta: {
      title: "Upload Reservations",
      description: "Upload Reservations",
      sort: 0,
      authenticatable: true,
      permission: false,
      icon: "mdi-clipboard-text-outline",
      roles: ["admin", "dealer"]
    },
  },
];
