export default {
  SET_INQUIRIES: (state, data) => {
    state.inquiries = data;
  },
  SET_INQUIRY: (state, data) => {
    state.inquiry = data;
  },
  SET_INQUIRY_ITEMS: (state, data) => {
    state.inquiryItems = data;
  },
  SET_BASE_INQUIRIES : (state, data) => {
    state.baseInquiries = data;
  },
  SET_BASE_INQUIRY_INQUIRIES : (state, data) => {
    state.baseInquiryInquiries = data;
  },
};
