var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-no-wrap"},[(_vm.trashed)?[_c('div',{staticClass:"text-no-wrap"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.emitRestore}},on),[(_vm.dataset.loading)?_c('v-icon',{staticClass:"mdi-spin",attrs:{"small":""}},[_vm._v("mdi-loading")]):_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-restore")])],1)]}}],null,false,3482030756)},[_c('span',{domProps:{"textContent":_vm._s(_vm.trans('Restore', 1))}})]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.emitDelete}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete-forever-outline")])],1)]}}],null,false,736175547)},[_c('span',{domProps:{"textContent":_vm._s(_vm.trans('Permanently delete', 1))}})])],1)]:(_vm.deleted)?[_c('div',{staticClass:"text-no-wrap"},[(_vm.hasPreview)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-3",attrs:{"to":{ name: (_vm.name + ".show"), params: { id: _vm.dataset.id } },"icon":"","text":""}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-open-in-new")])],1)]}}],null,false,92517669)},[_c('span',{domProps:{"textContent":_vm._s(_vm.trans('Show Details'))}})])]:_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":""},on:{"click":_vm.emitDelete}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete-forever-outline")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.trans_choice("Permanently delete", 1)))])])],2)]:(_vm.onlyDetails)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-3",attrs:{"to":{
            name: (_vm.name + ".show"),
            params: { id: _vm.dataset.id },
            query: { from: _vm.$route.fullPath },
          },"icon":"","text":""}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-open-in-new")])],1)]}}])},[_c('span',{domProps:{"textContent":_vm._s(_vm.trans('Show Details'))}})])]:[_vm._t("start"),(_vm.details)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-3",attrs:{"to":{
              name: (_vm.name + ".show"),
              params: { id: _vm.dataset.id },
              query: { from: _vm.$route.fullPath },
            },"icon":"","text":""}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-open-in-new")])],1)]}}],null,false,570736402)},[_c('span',{domProps:{"textContent":_vm._s(_vm.trans('Show Details'))}})])]:_vm._e(),_vm._t("middle"),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":""},on:{"click":_vm.emitDestroy}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete-outline")])],1)]}}])},[_c('span',{domProps:{"textContent":_vm._s(_vm.trans('Move to trash'))}})]),_vm._t("end")]],2)}
var staticRenderFns = []

export { render, staticRenderFns }