export default [
  {
    name: "brand-asset",
    meta: {
      title: "Brand Assets",
      sort: 11,
      authenticatable: true,
      description: "",
      icon: "mdi-image-multiple-outline",
      permission: false,
      roles: ["admin", "dealer"],
    },
    children: [
      {
        name: "brand-asset",
        meta: {
          title: "Brand Assets",
          sort: 0,
          authenticatable: true,
          description: "Manage Brand Assets",
          icon: "mdi-image-multiple-outline",
          permission: false,
          roles: ["admin", "dealer"],
        },
      },
      {
        name: "brand-asset.categories.index",
        meta: {
          title: "Categories",
          sort: 0,
          authenticatable: true,
          description: "Manage Brand Asset Categories",
          icon: "mdi-view-grid-outline",
          permission: false,
          roles: ["admin"],
        },
      },
      {
        name: "brand-asset.folders.index",
        meta: {
          title: "Folders",
          sort: 0,
          authenticatable: true,
          description: "Manage Brand Asset Folders",
          icon: "mdi-folder-multiple-outline",
          permission: false,
          roles: ["admin"],
        },
      },
    ],
  },
];
