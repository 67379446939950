export default {
  SET_TEST_DRIVES: (state, data) => {
    state.test_drives = data;
  },
  SET_TEST_DRIVE_UNITS: (state, data) => {
    state.test_drive_units = data;
  },
  SET_TEST_DRIVE: (state, data) => {
    state.test_drive = data;
  },
  SET_STATUSES: (state, data) => {
    state.statuses = data;
  },
  SET_ACTIONS: (state, data) => {
    state.actions = data;
  },
  SET_MODELS: (state, data) => {
    state.models = data;
  },
  SET_DEALERS: (state, data) => {
    state.dealers = data;
  },
};
