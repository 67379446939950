import infobipAccountsService from "@/services/api/modules/infobipAccountsService";

export default {
  async list({ commit, dispatch }, page = {}) {
    commit("SET_INFOBIP_ACCOUNTS", []);
    const { status, data } = await infobipAccountsService.list(
      page.page,
      page.per_page,
      page.q,
      page.dealer
    );

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
        },
        {
          root: true,
        }
      );
    } else {
      let responseData = Object.keys(page).length === 0 ? data.data : data;

      commit("SET_INFOBIP_ACCOUNTS", responseData);
    }
  },
  async getInfoBipAccount({ commit, dispatch }, id) {
    commit("SET_INFOBIP_ACCOUNT", null);
    const { status, data } = await infobipAccountsService.edit(id);

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
          timeout: 10000,
        },
        {
          root: true,
        }
      );
    } else {
      commit("SET_INFOBIP_ACCOUNT", data.data);
    }
  },
  async save({ commit, dispatch }, payload) {
    if (payload.id) {
      var { status, data } = await infobipAccountsService.update(payload.id, payload.data);
    } else {
      var { status, data } = await infobipAccountsService.create(payload.data);
    }
    const message = data.errors ? Object.values(data.errors).join(" ") : data.message;

    dispatch(
      "snackbar/show",
      {
        text: message,
      },
      {
        root: true,
      }
    );
    if (status === 200) {
      commit("SET_INFOBIP_ACCOUNT", data.data);
    }
  },
  async delete({ commit, dispatch }, payload) {
    const { status, data } = await infobipAccountsService.delete(payload);
    const message = data.errors ? Object.values(data.errors).join(" ") : data.message;
    dispatch(
      "snackbar/show",
      {
        text: message,
      },
      {
        root: true,
      }
    );
  },
  async getActiveDealerInfobipAccount({ commit, dispatch }) {
    commit("SET_ACTIVE_INFOBIP_ACCOUNT", null);
    const { status, data } = await infobipAccountsService.getActiveDealerInfobipAccount();

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
          timeout: 10000,
        },
        {
          root: true,
        }
      );
    } else {
      if (data.data) {
        commit("SET_ACTIVE_INFOBIP_ACCOUNT", data.data);
      } else {
        dispatch(
          "snackbar/show",
          {
            text: data.message,
            timeout: 10000,
          },
          {
            root: true,
          }
        );
      }
    }
  },
  async getInfobipSenderDetails({ dispatch }, payload) {
    const { status, data } = await infobipAccountsService.getInfobipSenderDetails(payload);

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
          timeout: 10000,
        },
        {
          root: true,
        }
      );
    } else {
      if (data.data) {
        return data.data
      } else {
        dispatch(
          "snackbar/show",
          {
            text: data.message,
            timeout: 10000,
          },
          {
            root: true,
          }
        );
      }
    }

    return null
  }
};
