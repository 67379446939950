import apiNewsService from "@/services/api/modules/newsService";

export default {
  async list({ commit, dispatch }, page = {}) {
    commit("SET_NEWS", []);
    const { status, data } = await apiNewsService.list(page.page, page.per_page, page.q);

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
        },
        {
          root: true,
        }
      );
    } else {
      let responseData = Object.keys(page).length === 0 ? data.data : data;
      commit("SET_NEWS", responseData);
    }
  },
  async getNews({ commit, dispatch }, id) {
    commit("SET_SINGLE_NEWS", null);
    const { status, data } = await apiNewsService.edit(id);

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
          timeout: 10000,
        },
        {
          root: true,
        }
      );
    } else {
      commit("SET_SINGLE_NEWS", data.data);
    }
  },
  async deleteSurveys({ commit, dispatch }, payload) {
    const { status, data } = await apiNewsService.delete(payload);
    if (status !== 200) {
      const errorMessage = data.errors ? Object.values(data.errors).join(" ") : data.message;
      dispatch(
        "snackbar/show",
        {
          text: errorMessage,
        },
        {
          root: true,
        }
      );
    } else {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
        },
        {
          root: true,
        }
      );
    }
  },
};
