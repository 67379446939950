import api from "@/services/api/baseService";

class apiNotificationService {
  list(params) {
    return api
      .get("/notifications", {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
        params
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  bulkOpen(notifications) {
    // notifications._method = 'PUT';
    return api
      .put(`/notifications/open`, notifications, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
}

export default new apiNotificationService();
