import apiQuotationsService from "@/services/api/modules/quotationsService";
import apiStatusesService from "@/services/api/modules/statusesService";
import apiActionsService from "@/services/api/modules/actionsService";

export default {
  async getQuotations({ commit }, page = {}) {
    commit("SET_QUOTATIONS", []);
    const { status, data } = await apiQuotationsService.list(
      page.page,
      page.per_page,
      page.q,
      page.model,
      page.status,
      page.action
    );

    if (status !== 200) {
      const errorMessage = data.errors ? Object.values(data.errors).join(" ") : data.message;
      dispatch(
        "snackbar/show",
        {
          text: errorMessage,
          timeout: 10000,
        },
        {
          root: true,
        }
      );
    } else {
      let responseData = Object.keys(page).length === 0 ? data.data : data;
      commit("SET_QUOTATIONS", responseData);
    }
  },
  async getQuotation({ commit, dispatch }, id) {
    commit("SET_QUOTATION", null);
    const { status, data } = await apiQuotationsService.getQuotation(id);

    if (status !== 200) {
      const errorMessage = data.errors ? Object.values(data.errors).join(" ") : data.message;
      dispatch(
        "snackbar/show",
        {
          text: errorMessage,
          timeout: 10000,
        },
        {
          root: true,
        }
      );
    } else {
      commit("SET_QUOTATION", data.data);
    }
  },
  async getQuotationStatuses({ commit, dispatch }) {
    commit("SET_QUOTATION_STATUSES", null);
    const { status, data } = await apiStatusesService.list("quotation");

    if (status !== 200) {
      const errorMessage = data.errors ? Object.values(data.errors).join(" ") : data.message;
      dispatch(
        "snackbar/show",
        {
          text: errorMessage,
          timeout: 10000,
        },
        {
          root: true,
        }
      );
    } else {
      commit("SET_QUOTATION_STATUSES", data.data);
    }
  },
  async getQuotationActions({ commit, dispatch }) {
    commit("SET_QUOTATION_ACTIONS", null);
    const { status, data } = await apiActionsService.list("quotation");

    if (status !== 200) {
      const errorMessage = data.errors ? Object.values(data.errors).join(" ") : data.message;
      dispatch(
        "snackbar/show",
        {
          text: errorMessage,
          timeout: 10000,
        },
        {
          root: true,
        }
      );
    } else {
      commit("SET_QUOTATION_ACTIONS", data.data);
    }
  },
  async updateQuotation({ commit, dispatch }, payload) {
    var { status, data } = await apiQuotationsService.update(payload.data, payload.id);
    const errorMessage = data.errors ? Object.values(data.errors).join(" ") : data.message;
    dispatch(
      "snackbar/show",
      {
        text: errorMessage,
      },
      {
        root: true,
      }
    );
  },
  async uploadQuotationDocument({ commit, dispatch }, payload) {
    var formData = new FormData();
    formData.append("file", payload.file);
    var { status, data } = await apiQuotationsService.upload(formData, payload.id);
    const errorMessage = data.errors ? Object.values(data.errors).join(" ") : data.message;

    dispatch(
      "snackbar/show",
      {
        text: errorMessage,
      },
      {
        root: true,
      }
    );
  },
};
