export default {
  SET_AUTH: (state, data) => {
    state.auth = {
      ...data,
    };
  },
  SET_USER_TYPE: (state, data) => {
    state.user_type = data;
  },
  SET_ERR: (state, data) => {
    state.err = {
      message: data,
    };
  },
  SET_ROLE: (state, data) => {
    state.role = { ...data }
  },
  SET_ROLES: (state, data) => {
    state.roles = data
  }
};
