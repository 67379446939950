export default {
  survey_cs_online_summary: [],
  survey_cs_online_data: [],
  survey_dcsi_summary: [],
  survey_dcsi_online_data: [],
  survey_dssi_summary: [],
  survey_dssi_online_data: [],
  survey_factors: [],
  survey_factors_score: [],
};
