<template>
  <v-navigation-drawer
    :clipped="sidebar.clipped"
    :mini-variant.sync="sidebar.mini"
    app
    floating
    width="290"
    fixed
    v-model="sidebarmodel"
    class="dt-sidebar workspace"
  >
    <!-- Brand -->
    <v-list height="83" class="workspace">
      <v-list-item class="mt-2">
        <v-list-item>
          <img width="100%" :src="logo" :lazy-src="logo" />
        </v-list-item>
        <v-list-item-content>
          <v-list-item-title
            class="primary--text font-weight-bold"
            v-html="app.title"
          ></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <!-- Brand -->

    <!-- Menu Items -->
    <v-list nav class="workspace">
      <template v-for="(parent, i) in menus">
        <!-- Menu with children -->
        <template v-if="parent.meta.divider">
          <v-divider :key="i" class="my-2"></v-divider>
        </template>
        <template v-else-if="parent.meta.subheader">
          <!-- <v-subheader class="text--muted text-capitalize" :key="i" v-text="$t(parent.meta.title)"></v-subheader> -->
          <v-subheader
            class="text--muted text-capitalize"
            :key="i"
          ></v-subheader>
        </template>
        <template v-else-if="parent.children">
          <can :code="parent.meta.roles" :key="i">
            <v-list-group
              class="mb-2"
              :key="i"
              no-action
              :prepend-icon="parent.meta.icon"
              :value="active(parent)"
            >
              <template v-slot:activator>
                <v-list-item-content :title="parent.meta.description">
                  <v-list-item-title
                    v-text="$t(parent.meta.title)"
                  ></v-list-item-title>
                </v-list-item-content>
              </template>
              <!-- Submenu children -->
              <template v-for="(submenu, j) in parent.children">
                <v-divider v-if="submenu.meta.divider" :key="i"></v-divider>
                <template v-else>
                  <template v-if="submenu.children">
                    <can :code="submenu.meta.roles" :key="j">
                      <v-list-item style="padding-left: 56px;" :key="j" :exact="inactive(submenu)">
                        <v-list-item-icon v-if="submenu.meta.icon">
                          <v-icon small v-text="submenu.meta.icon"></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content :title="submenu.meta.description">
                          <v-menu style="border: 1px solid #f4f5f8 !important;" top :offset-x="offset">
                            <template v-slot:activator="{ on, attrs }">
                              <v-list-item-title
                                v-bind="attrs"
                                v-on="on"
                                v-text="$t(submenu.meta.title)"
                              ></v-list-item-title>
                            </template>
                            <v-list class="py-0">
                              <template  v-for="(subchildren, index) in submenu.children">
                                <can :code="subchildren.meta.roles" :key="index">
                                  <v-list-item
                                    :target="subchildren.meta.external ? '_blank' : null"
                                    :to="{ name: subchildren.name }"
                                    :key="index"
                                  >
                                    <v-list-item-content :title="subchildren.meta.description">
                                      <v-list-item-title v-text="$t(subchildren.meta.title)"></v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                </can>
                              </template>
                            </v-list>
                          </v-menu>
                        </v-list-item-content>
                      </v-list-item>
                    </can>
                  </template>
                  <template v-else-if="submenu.meta.divider">
                    <v-divider :key="j"></v-divider>
                  </template>
                  <template v-else>
                    <can :code="submenu.meta.roles" :key="j">
                      <v-list-item
                        :key="j"
                        :target="submenu.meta.external ? '_blank' : null"
                        :to="{ name: submenu.name }"
                        :exact="inactive(submenu)"
                      >
                        <v-list-item-icon v-if="submenu.meta.icon">
                          <v-icon small v-text="submenu.meta.icon"></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content :title="submenu.meta.description">
                          <v-list-item-title
                            v-text="$t(submenu.meta.title)"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </can>
                  </template>
                </template>
              </template>
            </v-list-group>
          </can>
        </template>
        <!-- Menu with Children -->
        <!-- Menu without Children -->
        <template v-else>
          <can :code="parent.meta.roles" :key="i">
            <v-list-item
              color="primary"
              :key="i"
              link
              :to="{ name: parent.name }"
            >
              <v-list-item-icon>
                <v-icon small v-text="parent.meta.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content :title="parent.meta.description">
                <v-list-item-title
                  v-text="$t(parent.meta.title)"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </can>
        </template>
        <!-- Menu without Children -->
      </template>
    </v-list>
    <!-- Menu Items -->
  </v-navigation-drawer>
</template>

<script>
import app from "@/config/app";
import includes from "lodash/includes";
import menus from "@/config/sidebar";
import { mapGetters, mapActions } from "vuex";
import logo from "@/assets/honda_logo.png";

export default {
  name: "Sidebar",

  computed: {
    ...mapGetters({
      sidebar: "sidebar/sidebar",
      dark: "theme/dark",
      lang: "app/locale",
    }),

    app: function () {
      return app;
    },

    vuetify: function () {
      return this.$vuetify;
    },

    menus: function () {
      return menus;
    },

    sidebarmodel: {
      set(value) {
        this.toggle({ model: value });
      },
      get() {
        return this.sidebar.model;
      },
    },
  },

  data: () => ({
    logo,
    offset: true,
  }),

  methods: {
    ...mapActions({
      toggle: "sidebar/toggle",
      clip: "sidebar/clip",
      toggleTheme: "theme/toggle",
    }),

    inactive(path) {
      return !this.active(path);
    },

    active(path) {
      return includes(path.meta.children, this.$route.name);
    },
  },
};
</script>
