import api from "@/services/api/baseService";

class apiDealerPartService {
  uploadMasterlist(payload) {
    return api
      .post("/parts/upload-parts-master-list", payload, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  getMasterlist(params) {
    return api
      .get("/parts/masterlist", {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
        params
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  getMasterlistItem(id) {
    return api
      .get(`/parts/masterlist/${id}`, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        }
      })
      .then((response) => response)
      .catch((error) => error.response);
  }

  getInquiries(page, per_page, filterData) {
    const { status, q, channel }  = filterData;

    return api
      .get("/parts/inquiries", {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
        params : {
          page,
          per_page,
          channel,
          q,
          status
        }
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  getInquiry(id) {
    return api
      .get(`/parts/inquiries/${id}`, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        }
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  getInquiryItems(inquiryId){
    return api
    .get(`/parts/inquiries/${inquiryId}/items`, {
      headers: {
        // Authorization: `Bearer ${localStorage.token}`,
      }
    })
    .then((response) => response)
    .catch((error) => error.response);
  }
  updateInquiry(request){
    return api
      .patch(`/parts/inquiries/${request.id}`, request, {
        headers: { 
          // Authorization: `Bearer ${localStorage.token}` 
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }

  uploadInquiries(payload) {
    return api
      .post("/parts/inquiries", payload, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }

  getInventories(params) {
    return api
      .get("/parts/inventory", {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
        params
      })
      .then((response) => {
        response.data.data = response.data.data.map(function(data){
          data.savings_percentage = data.savings_percentage * 100 + "%";

          return data
        });

        return response;
      })
      .catch((error) => error.response);
  }
  getInventory(id) {
    return api
      .get(`/parts/inventories/${id}`, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        }
      })
      .then((response) => response)
      .catch((error) => error.response);
  }

  uploadInventories(payload) {
    return api
      .post("/parts/upload-dealer-parts-inventory", payload, {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }

  // Base Inquiries
  getBaseInquiries(page, per_page, filterData) {
    const { q }  = filterData;

    return api
      .get("/parts/inquiry/base", {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
        params : {
          page,
          per_page,
          q,
        }
      })
      .then((response) => response)
      .catch((error) => error.response);
  }

  getBaseInquiryInquries(page, per_page, filterData) {
    const { q, id }  = filterData;
    let url = `/parts/inquiry/base/${id}/inquiries`;
    return api
    .get(url, {
      headers: {
        // Authorization: `Bearer ${localStorage.token}`,
      },
      params : {
        page,
        per_page,
        q,
      }
    })
    .then((response) => response)
    .catch((error) => error.response);
  }

  getBaseInquiryItems(baseId){
    return api
    .get(`/parts/inquiry/base/${baseId}/items`, {
      headers: {
        // Authorization: `Bearer ${localStorage.token}`,
      }
    })
    .then((response) => response)
    .catch((error) => error.response);
  }
}

export default new apiDealerPartService();
