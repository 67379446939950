export default [
  {
    name: "leads",
    meta: {
      title: "Leads",
      sort: 3,
      authenticatable: true,
      description: "Check leads",
      icon: "mdi-filter-outline",
      permission: false,
      roles: ["admin", "dealer"],
    },
  },
];
