import apiDealerGroupsService from "@/services/api/modules/dealerGroupsService";

export default {
  async getGroups({ commit }, page = {}) {
    commit("SET_GROUPS", []);

    const { status, data } = await apiDealerGroupsService.getGroups(
      page.page,
      page.per_page,
      page.q,
    );

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
          timeout: 10000,
        },
        {
          root: true,
        }
      );
    } else {
      let responseData = Object.keys(page).length === 0 ? data.data : data;

      commit("SET_GROUPS", responseData);
    }
  },
  async list({ commit }, page = {}) {
    commit("SET_GROUPS", []);

    const { status, data } = await apiDealerGroupsService.getGroups(page.per_page);

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
          timeout: 10000,
        },
        {
          root: true,
        }
      );
    } else {
      let responseData = Object.keys(page).length === 0 ? data.data : data;

      commit("SET_GROUPS", responseData);
    }
  },
  async getGroup({ commit }, id) {
    commit("SET_GROUP", null);
    const { status, data } = await apiDealerGroupsService.getGroup(id);

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
          timeout: 10000,
        },
        {
          root: true,
        }
      );
    } else {
      commit("SET_GROUP", data.data);
    }
  },
  async save({ dispatch, commit }, details) {
    if (details.id) {
      var { status, data } = await apiDealerGroupsService.update(details.id, details.data);
    } else {
      var { status, data } = await apiDealerGroupsService.create(details.data);
    }

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
          timeout: 10000,
        },
        {
          root: true,
        }
      );
    } else {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
          timeout: 10000,
        },
        {
          root: true,
        }
      );

      commit("SET_GROUP", data.data);
    }
  },
  async delete({ dispatch }, id) {
    const { status, data } = await apiDealerGroupsService.delete(id);

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
          timeout: 10000,
        },
        {
          root: true,
        }
      );
    } else {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
          timeout: 10000,
        },
        {
          root: true,
        }
      );
      dispatch("getItems");
    }
  },
};
