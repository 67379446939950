export default {
  GET_SURVEY_CS_ONLINE_SUMMARY: (state) => state.survey_cs_online_summary,
  GET_SURVEY_CS_ONLINE_DATA: (state) => state.survey_cs_online_data,
  GET_SURVEY_DCSI_SUMMARY: (state) => state.survey_dcsi_summary,
  GET_SURVEY_DCSI_ONLINE_DATA: (state) => state.survey_dcsi_online_data,
  GET_SURVEY_DSSI_SUMMARY: (state) => state.survey_dssi_summary,
  GET_SURVEY_DSSI_ONLINE_DATA: (state) => state.survey_dssi_online_data,
  GET_FACTOR: (state) => state.survey_factors,
  GET_SCORE_FACTOR: (state) => state.survey_factors_score,
};
