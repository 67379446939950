import api from "@/services/api/baseService";

class apiAuthService {
  login(data) {
    return api
      .post("/auth/login", {
        ...data,
      })
      .then((response) => response)
      .catch((error) => error.response);
  }

  logout() {
    return api
      .post(
        "/auth/logout",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            // Authorization: "Bearer " + localStorage.token,
          },
        }
      )
      .then((response) => response)
      .catch((error) => error.response);
  }

  forgotPassword(data) {
    return api
      .post("/auth/forgot-password", {
        ...data,
      })
      .then((response) => response)
      .catch((error) => error.response);
  }

  resetPassword(data) {
    return api
      .post("/auth/reset-password", {
        ...data,
      })
      .then((response) => response)
      .catch((error) => error.response);
  }

  updatePassword(data) {
    return api
      .post("/auth/change-password", {
        ...data,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          // Authorization: "Bearer " + localStorage.token,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }

  changePassword(data) {
    return api
      .post("/auth/force-reset-password", {
        ...data,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          // Authorization: "Bearer " + localStorage.token,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }

  generateXsrfToken() {
    return api.get('/sanctum/csrf-cookie').then((response) => response)
    .catch((error) => error.response);
  }

  myProfile() {
    return api
      .get("/me",
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          // Authorization: "Bearer " + localStorage.token,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }

  verifyEmail(data) {
    return api
      .post("/auth/verify-email", {
        ...data,
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  
  submitOtp(data) {
    return api
      .post("/auth/login/otp", {
        ...data,
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  
  resendOtp(data) {
    return api
      .post("/auth/login/otp-resend", {
        ...data,
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
}
export default new apiAuthService();
