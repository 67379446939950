<template>
  <!-- v-slot:activator="{ on, attrs }" -->
  <!-- <div v-bind="attrs" v-on="on" no-gutter> -->

  <!-- :item-text="`${item.first_name}` `${item.last_name}`" -->
  <v-autocomplete
    append-icon="mdi-chevron-down"
    :items="data"
    item-value="id"
    label="Sales Consultant"
    outlined
    clearable
    hide-details
    clear-icon="mdi-close-circle-outline"
    background-color="selects"
    prepend-inner-icon="mdi-account-outline"
    :value="assigned_sc_user_id"
    v-model="salesConsultant"
    :disabled="assigned_sc_user_id || disabled ? true : false"
    @change="updateSalesConsultant"
    :rules="rules"
  >
    <template slot="selection" slot-scope="data">
      <!-- HTML that describe how select should render selected items -->
      {{ data.item.first_name }} {{ data.item.last_name }}
    </template>
    <template slot="item" slot-scope="data">
      <!-- HTML that describe how select should render items when the select is open -->
      {{ data.item.first_name }} {{ data.item.last_name }}
    </template>
    <template v-slot:no-data>
      <v-card-text class="pa-2">
        {{ "No data found" }}
      </v-card-text>
    </template>
  </v-autocomplete>
  <!-- </div> -->
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    assigned_sc_user_id: {
      type: Number,
      default: 0,
    },
    required: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dealer: {
      required: false,
      type: String,
      default: "",
    }
  },
  data() {
    return {
      rules: [],
      salesConsultant: null,
    };
  },
  computed: {
    ...mapGetters({
      data: "report/GET_SALES_CONSULTANTS",
    }),
  },
  async created() {
    if (this.required) {
      this.rules = [(value) => !!value || 'Sales Consultant is required'];
    }

    if (this.dealer) {
      await this.getSalesConsultants({
        dealer_id: this.dealer,
      });
    } else {
      await this.getSalesConsultants();
    }

    if (this.assigned_sc_user_id) {
      this.salesConsultant = this.assigned_sc_user_id;
      await this.setSalesConsultant(this.assigned_sc_user_id);
    } else {
      await this.setSalesConsultant(null);
    }
  },
  methods: {
    ...mapActions({
      getSalesConsultants: "report/getSalesConsultants",
      setSalesConsultant: "report/setSalesConsultant",
    }),
    async updateSalesConsultant(salesConsultant) {
      await this.setSalesConsultant(salesConsultant);
    },
  },
};
</script>

<style></style>
