export default [
  {
    path: "survey",
    name: "survey",
    component: () => import("../modules/Survey"),
    meta: {
      title: "Surveys",
      description: "Online Surveys",
      sort: 0,
      authenticatable: true,
      permission: false,
      icon: "mdi-clipboard-text-outline",
      roles: ["admin", "admin_survey", "dealer_survey"]
    },
  },
  {
    path: "survey/create",
    name: "survey.create",
    component: () => import("../modules/Create"),
    meta: {
      title: "Create Survey",
      description: "Create Online Survey",
      sort: 0,
      authenticatable: true,
      permission: false,
      roles: ["admin", "admin_survey"]
    }
  },
  {
    path: "survey/:id/edit",
    name: "survey.edit",
    component: () => import("../modules/Edit"),
    meta: {
      title: "Edit Survey",
      description: "Edit Online Survey",
      sort: 0,
      authenticatable: true,
      permission: false,
      roles: ["admin", "admin_survey"]
    }
  },
  {
    path: "survey/reports/cs-target-score-upload",
    name: "survey.cs.upload",
    component: () => import("../modules/Reports/cs/Upload"),
    meta: {
      title: "Survey CS Upload",
      description: "Survey CS Upload",
      sort: 0,
      authenticatable: true,
      permission: false,
      icon: "survey-cs-upload",
      roles: ["admin", "admin_survey"]
    },
  },

  {
    path: "survey/reports/cs-target-factor-upload",
    name: "survey.cs.upload.factor",
    component: () => import("../modules/Reports/cs/UploadFactor"),
    meta: {
      title: "Survey CS Upload Factor",
      description: "Survey CS Upload Factor",
      sort: 0,
      authenticatable: true,
      permission: false,
      icon: "survey-cs-upload-factor",
      roles: ["admin", "admin_survey"]
    },
  },

  {
    path: "text-blast",
    name: "text-blast.index",
    component: () => import("../modules/TextBlast/Index"),
    meta: {
      title: "Text Blasts Batches",
      description: "Text Blasts Batches",
      sort: 1,
      authenticatable: true,
      permission: false,
      roles: ["admin", "admin_survey", "dealer_survey"]
    },
  },
  {
    path: "survey/:id/cdc",
    name: "cdc",
    component: () => import("../modules/cdc/Index.vue"),
    meta: {
      title: "Critically Dissatisfied Customers",
      description: "Critically Dissatisfied Customers",
      sort: 0,
      authenticatable: true,
      permission: false,
      roles: ["admin", "admin_survey", "dealer_survey"]
    }
  },
  {
    path: "survey/cdc",
    name: "cdcs",
    component: () => import("../modules/cdc/Index.vue"),
    meta: {
      title: "Critically Dissatisfied Customers",
      description: "Critically Dissatisfied Customers",
      sort: 0,
      authenticatable: true,
      permission: false,
      roles: ["admin", "admin_survey", "dealer_survey"]
    }
  },
  {
    path: "survey/cdc/email-notifications",
    name: "email_notifications",
    component: () => import("../modules/EmailNotification/Index.vue"),
    meta: {
      title: "Logs",
      description: "Logs",
      sort: 0,
      authenticatable: true,
      permission: false,
      roles: ["admin", "admin_survey", "dealer_survey"]
    }
  },
  {
    path: "survey/cdc/email-notifications/:id/edit",
    name: "email_notification.edit",
    component: () => import("../modules/EmailNotification/Edit.vue"),
    meta: {
      title: "Email Notifications",
      description: "Email Notifications",
      sort: 0,
      authenticatable: true,
      permission: false,
      roles: ["admin", "admin_survey"]
    }
  },
  {
    path: "survey/:id/result/",
    name: "survey.result",
    component: () => import("../modules/Result/Index.vue"),
    meta: {
      title: "Results",
      description: "Results",
      sort: 0,
      authenticatable: true,
      permission: false,
      roles: ["admin", "admin_survey", "dealer_survey"]
    }
  },
  {
    path: "text-blast/create",
    name: "text-blast.create",
    component: () => import("../modules/TextBlast/Create"),
    meta: {
      title: "Text Blast Create",
      description: "Create Text Blast",
      sort: 0,
      authenticatable: true,
      permission: false,
      roles: ["admin", "admin_survey"]
    }
  },
  {
    path: "text-blast/:id/logs",
    name: "text-blast.logs",
    component: () => import("../modules/TextBlast/Logs"),
    meta: {
      title: "Text Blast Logs",
      description: "Text Blast Logs List",
      sort: 0,
      authenticatable: true,
      permission: false,
      roles: ["admin", "admin_survey", "dealer_survey"]
    }
  },
  {
    path: "survey/reports/cs-dashboard",
    name: "survey-reports.cs-dashboard",
    component: () => import("../modules/Reports/cs/CsDashboard"),
    meta: {
      title: "CS Dashboard",
      description: "CS Dashboard",
      sort: 0,
      authenticatable: true,
      permission: false,
      icon: "inquiries",
      roles: ["admin", "admin_survey", "dealer_survey"]
    }
  },
  {
    path: "survey/reports/survey-ranking",
    name: "survey-reports.survey-ranking",
    component: () => import("../modules/Reports/cs/SurveyRanking"),
    meta: {
      title: "CS Dashboard",
      description: "CS Dashboard",
      sort: 0,
      authenticatable: true,
      permission: false,
      icon: "inquiries",
      roles: ["admin", "admin_survey"]
    }
  },
  {
    path: "survey/reports/survey-summary",
    name: "survey-reports.survey-summary",
    component: () => import("../modules/Reports/cs/SurveySummary"),
    meta: {
      title: "CS Dashboard",
      description: "CS Dashboard",
      sort: 0,
      authenticatable: true,
      permission: false,
      icon: "inquiries",
      roles: ["admin", "admin_survey", "dealer_survey"]
    }
  },
  {
    path: "survey/reports/cdc-summary",
    name: "survey-reports.cdc-summary",
    component: () => import("../modules/Reports/cs/CdcSummary"),
    meta: {
      title: "CS CDC Summary",
      description: "CS CDC Summary",
      sort: 0,
      authenticatable: true,
      permission: false,
      icon: "inquiries",
      roles: ["admin", "admin_survey", "dealer_survey"]
    }
  },
  {
    path: "survey/reports/dealer-action-plan",
    name: "survey-reports.dealer-action-plan",
    component: () => import("../modules/Reports/cs/DealerActionPlan"),
    meta: {
      title: "CS Dealer Action Plan",
      description: "CS Dealer Action Plan",
      sort: 0,
      authenticatable: true,
      permission: false,
      icon: "inquiries",
      roles: ["admin", "admin_survey", "dealer_survey"]
    }
  },

  /**
   * DCSI
   */
  {
    path: "survey/reports/dcsi/dashboard",
    name: "survey-reports.dcsi.dashboard",
    component: () => import("../modules/Reports/dcsi/Dashboard"),
    meta: {
      title: "D-CSI Dashboard",
      description: "D-CSI Dashboard",
      sort: 0,
      authenticatable: true,
      permission: false,
      icon: "inquiries",
      roles: ["admin", "admin_survey", "dealer_survey"]
    }
  },
  {
    path: "survey/reports/dcsi/target-score-trend-upload",
    name: "survey.dcsi.upload",
    component: () => import("../modules/Reports/dcsi/Upload"),
    meta: {
      title: "Survey DCSI Upload",
      description: "Survey DCSI Upload",
      sort: 0,
      authenticatable: true,
      permission: false,
      icon: "survey-dcsi-upload",
      roles: ["admin", "admin_survey"]
    },
  },
  {
    path: "survey/reports/dcsi/target-score-factor-upload",
    name: "survey.dcsi.upload.factor",
    component: () => import("../modules/Reports/dcsi/UploadFactor"),
    meta: {
      title: "Survey DCSI Upload Factor",
      description: "Survey DCSI Upload Factor",
      sort: 0,
      authenticatable: true,
      permission: false,
      icon: "survey-dcsi-upload-factor",
      roles: ["admin", "admin_survey"]
    },
  },
  {
    path: "survey/reports/dcsi/factor-scores",
    name: "survey-reports.dcsi.factor-scores",
    component: () => import("../modules/Reports/dcsi/FactorScore"),
    meta: {
      title: "D-CSI Dashboard",
      description: "D-CSI Dashboard",
      sort: 0,
      authenticatable: true,
      permission: false,
      icon: "inquiries",
      roles: ["admin", "admin_survey", "dealer_survey", "dealer_survey"]
    }
  },
  {
    path: "survey/reports/dcsi/dealer-scores",
    name: "survey-reports.dcsi.dealer-scores",
    component: () => import("../modules/Reports/dcsi/DealerScore"),
    meta: {
      title: "D-CSI Dashboard",
      description: "D-CSI Dashboard",
      sort: 0,
      authenticatable: true,
      permission: false,
      icon: "inquiries",
      roles: ["admin", "admin_survey", "dealer_survey"]
    }
  },

  /**
   * DSSI
   */
  {
    path: "survey/reports/dssi/dashboard",
    name: "survey-reports.dssi.dashboard",
    component: () => import("../modules/Reports/dssi/Dashboard"),
    meta: {
      title: "D-SSI Dashboard",
      description: "D-SSI Dashboard",
      sort: 0,
      authenticatable: true,
      permission: false,
      icon: "inquiries",
      roles: ["admin", "admin_survey", "dealer_survey"]
    }
  },

  {
    path: "survey/reports/dssi/target-score-trend-upload",
    name: "survey.dssi.upload",
    component: () => import("../modules/Reports/dssi/Upload"),
    meta: {
      title: "Survey DSSI Upload",
      description: "Survey DSSI Upload",
      sort: 0,
      authenticatable: true,
      permission: false,
      icon: "survey-dssi-upload",
      roles: ["admin", "admin_survey"]
    },
  },
  {
    path: "survey/reports/dssi/target-score-factor-upload",
    name: "survey.dssi.upload.factor",
    component: () => import("../modules/Reports/dssi/UploadFactor"),
    meta: {
      title: "Survey DSSI Upload Factor",
      description: "Survey DSSI Upload Factor",
      sort: 0,
      authenticatable: true,
      permission: false,
      icon: "survey-dssi-upload-factor",
      roles: ["admin", "admin_survey"]
    },
  },

  {
    path: "survey/reports/dssi/factor-scores",
    name: "survey-reports.dssi.factor-scores",
    component: () => import("../modules/Reports/dssi/FactorScore"),
    meta: {
      title: "D-SSI Dashboard",
      description: "D-SSI Dashboard",
      sort: 0,
      authenticatable: true,
      permission: false,
      icon: "inquiries",
      roles: ["admin", "admin_survey", "dealer_survey"]
    }
  },
  {
    path: "survey/reports/dssi/dealer-scores",
    name: "survey-reports.dssi.dealer-scores",
    component: () => import("../modules/Reports/dssi/DealerScore"),
    meta: {
      title: "D-SSI Dashboard",
      description: "D-SSI Dashboard",
      sort: 0,
      authenticatable: true,
      permission: false,
      icon: "inquiries",
      roles: ["admin", "admin_survey", "dealer_survey"]
    }
  },
  {
    path: "survey/reports/dssi/cdc-summary",
    name: "survey-reports.dssi.cdc-summary",
    component: () => import("../modules/Reports/dssi/CdcSummary"),
    meta: {
      title: "D-SSI CDC Summary",
      description: "D-SSI CDC Summary",
      sort: 0,
      authenticatable: true,
      permission: false,
      icon: "inquiries",
      roles: ["admin", "admin_survey", "dealer_survey"]
    }
  },
  {
    path: "survey/reports/dssi/dealer-action-plan",
    name: "survey-reports.dssi.dealer-action-plan",
    component: () => import("../modules/Reports/dssi/DealerActionPlan"),
    meta: {
      title: "D-SSI Dealer Action Plan",
      description: "D-SSI Dealer Action Plan",
      sort: 0,
      authenticatable: true,
      permission: false,
      icon: "inquiries",
      roles: ["admin", "admin_survey", "dealer_survey"]
    }
  },
];