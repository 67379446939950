export default {
  SET_REGIONS: (state, data) => {
    state.regions = data;
  },
  SET_PROVINCES: (state, data) => {
    state.provinces = data;
  },
  SET_CITIES: (state, data) => {
    state.cities = data;
  },
  SET_BARANGAYS: (state, data) => {
    state.barangays = data;
  },
};
