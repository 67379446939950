export default [
  {
    name: "inquiries",
    meta: {
      title: "Inquiries",
      sort: 5,
      authenticatable: true,
      description: "Inquiry",
      icon: "mdi-account-details-outline",
      permission: false,
      roles: ["admin", "dealer"],
    },
  },
];
