export default [
  {
    path: "/admin/news",
    name: "news.index",
    component: () => import("../Index.vue"),
    meta: {
      title: "News",
      description: "Manage News",
      sort: 0,
      authenticatable: true,
      permission: false,
      icon: "news",
      roles: ["admin"],
    },
  },
  {
    path: "/admin/news/create",
    name: "news.create",
    component: () => import("../Create.vue"),
    meta: {
      title: "Add News",
      description: "Create News",
      sort: 0,
      authenticatable: true,
      permission: false,
      icon: "colors",
      roles: ["admin"],
    },
  },
  {
    path: "/admin/news/:id/edit",
    name: "news.edit",
    component: () => import("../Edit.vue"),
    meta: {
      title: "Edit News",
      sort: 0,
      authenticatable: true,
      permission: false,
      roles: ["admin"],
    },
  },
];
