export default [
  {
    path: "/admin/dealer-parts/inquiries",
    name: "admin.dealerParts.cart.inquries",
    redirect: { name: "dealerParts.cart.inquiries.index" },
    component: () => import("@/App.vue"),
    meta: {
      title: "Cart Inquiries",
      sort: 13,
      authenticatable: false,
      icon: "mdi-format-list-numbered-rtl",
      roles: ["admin_parts", "dealer_parts"]
    },
    children: [
      {
        path: "/admin/dealer-parts/inquiries",
        name: "dealerParts.cart.inquiries.index",
        component: () => import("../Index.vue"),
        meta: {
          title: "Cart Inquiries",
          description: "Dealer Parts Cart Inquiries",
          sort: 0,
          authenticatable: true,
          permission: false,
          icon: "mdi-format-list-numbered-rtl",
          roles: ["admin_parts", "dealer_parts"]
        }
      },
      {
        path: "/admin/dealer-parts/admin/:id/inquiries",
        name: "dealerParts.cart.inquiries.admin.preview",
        component: () => import("../AdminPreview.vue"),
        meta: {
          title: "Cart Inquiries",
          description: "Dealer Parts Cart Inquiries",
          sort: 0,
          authenticatable: true,
          permission: false,
          icon: "mdi-format-list-numbered-rtl",
          roles: ["admin_parts", "dealer_parts"]
        }
      },
      {
        path: "/admin/dealer-parts/admin/inquiries",
        name: "dealerParts.cart.inquiries.admin.index",
        component: () => import("../AdminIndex.vue"),
        meta: {
          title: "Cart Inquiries",
          description: "Dealer Parts Cart Inquiries",
          sort: 0,
          authenticatable: true,
          permission: false,
          icon: "mdi-format-list-numbered-rtl",
          roles: ["admin_parts", "dealer_parts"]
        }
      },
      {
        path: "/admin/dealer-parts/inquiries/upload",
        name: "dealerParts.cart.inquiries.upload",
        component: () => import("../Upload.vue"),
        meta: {
          title: "Upload Dealer Parts Inquiries",
          description: "Upload Dealer Parts Inquiries",
          sort: 0,
          authenticatable: true,
          permission: false,
          icon: "mdi-format-list-bulleted",
          roles: ["admin_parts", "dealer_parts"]
        },
      }
    ],
  },
];
