import metatags from "@/routes/middleware/metatags";
import multiguard from "vue-router-multiguard";

const routes = [
  {
    path: "/admin",
    name: "auth",
    component: () => import("@/modules/Auth/Auth.vue"),
    children: [
      {
        name: "auth.login",
        path: "login",
        meta: { title: "Login" },
        component: () => import("@/modules/Auth/Login.vue"),
      },
      {
        name: "auth.forgot.password",
        path: "forgot-password",
        meta: { title: "Forgot Password" },
        component: () => import("@/modules/Auth/ForgotPassword.vue"),
      },
      {
        name: "auth.reset.password",
        path: "reset-password",
        meta: { title: "Reset Password" },
        component: () => import("@/modules/Auth/ResetPassword.vue"),
      },
      {
        name: "auth.force.reset.password",
        path: "force-reset-password",
        meta: { title: "Change Password" },
        component: () => import("@/modules/Auth/ForceChangePassword.vue"),
      },
      {
        name: "auth.email.verify",
        path: "email/verify/:userId",
        meta: { title: "Email Verification" },
        component: () => import("@/modules/Auth/UserVerifyEmail.vue"),
      },
      {
        name: "auth.mfa",
        path: "mfa",
        meta: { title: "Multi Factor Authentication" },
        component: () => import("@/modules/Auth/MFA.vue"),
      },
    ],
  },
];
const requireRoute = require.context(
  // The relative path of the routes folder
  "@/modules",
  // Whether or not to look in subfolders
  true,
  // The regular expression used to match base route filenames
  /routes\/web\.js$/
);

requireRoute.keys().forEach((route) => {
  const routeConfig = requireRoute(route);

  routeConfig.default.forEach((route) => {
    routes.push(route);
  });
});

export default {
  path: "/",
  component: () => import("@/components/Layouts/Public.vue"),
  meta: { title: "Admin", authenticatable: false },
  children: routes,
  redirect: { name: "home" },
  beforeEnter: multiguard([metatags]),
};
