export default [
  {
    path: "/admin/dealers-account-maintenance/dealers/:dealer_id/users",
    name: "dealers-account-maintenance.dealerusers.index",
    component: () => import("../Index.vue"),
    meta: {
      title: "Dealer Users",
      description: "Models",
      sort: 0,
      authenticatable: true,
      permission: false,
      icon: "colors",
      roles: ["admin"]
    },
  },
  {
    path: "/admin/dealers-account-maintenance/dealers/:dealer_id/users/create",
    name: "dealers-account-maintenance.dealerusers.create",
    component: () => import("../Create.vue"),
    meta: {
      title: "Add new Dealer User",
      description: "Create new Dealer Use",
      sort: 0,
      authenticatable: true,
      permission: false,
      icon: "colors",
      roles: ["admin"]
    },
  },
  {
    path: "/admin/dealers-account-maintenance/dealers/:dealer_id/users/:id/edit",
    name: "dealers-account-maintenance.dealerusers.edit",
    component: () => import("../Edit.vue"),
    meta: {
      title: "Edit Dealer User",
      sort: 0,
      authenticatable: true,
      permission: false,
      roles: ["admin"]
    },
  },
];
