import apiSurveySmsBlastService from "@/services/api/modules/surveySmsBlastService";

export default {
  async list({ commit, dispatch }, page = {}) {
    commit("SET_SMS_BLASTS", []);
    const { status, data } = await apiSurveySmsBlastService.list({ ...page });
    const errorMessage = data.errors ? Object.values(data.errors).join(" ") : data.message;

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: errorMessage,
        },
        {
          root: true,
        }
      );
    } else {
      let responseData = Object.keys(page).length === 0 ? data.data : data;

      commit("SET_SMS_BLASTS", responseData);
    }
  },
  async getSurveySmsBlast({ commit, dispatch }, id) {
    commit("SET_SMS_BLAST", null);
    const { status, data } = await apiSurveySmsBlastService.getSurveySmsBlast(id);
    const errorMessage = data.errors ? Object.values(data.errors).join(" ") : data.message;

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: errorMessage,
          timeout: 10000,
        },
        {
          root: true,
        }
      );
    } else {
      commit("SET_SMS_BLAST", data.data);
    }
  },
  async uploadSurveySmsBlast({ commit, dispatch }, payload) {
    const debug = false

    if (debug) {
      const withError = false;
      const data = {
        "success": true,
        "error_code": null,
        "message": "Successfully upload survey sms blast.",
        "data": {
            "filename": "YDN9wA3d8BpK8dBj96UluXJA6a6lPxGzlYUOVvI4.xlsx",
            "original_filename": "Mock Valid.xlsx",
            "status": "pending",
            "details": null,
            "created_by_user_id": 1,
            "updated_at": "2023-04-06T06:20:44.000000Z",
            "created_at": "2023-04-06T06:20:44.000000Z",
            "id": 106,
            "file_uploaded": {
                "headers": [
                    "mobile",
                    "first_name",
                    "last_name",
                    "email"
                ],
                "row_count": 2,
                "errors": withError ? [
                    "Row 2: Unsupported mobile pattern.",
                    "Row 3: Has no mobile number."
                ] : []
            }
        }
      }

      commit("SET_SMS_BLAST_UPLOAD", data.data);

      return;
    }

    const { status, data } = await apiSurveySmsBlastService.upload(payload);
    // const message = data.errors ? Object.values(data.errors).join(" ") : data.message;
    
    // dispatch(
    //   "snackbar/show",
    //   {
    //     text: message,
    //     timeout: 10000,
    //   },
    //   {
    //     root: true,
    //   }
    // );

    if (status == 200) {
      commit("SET_SMS_BLAST_UPLOAD", data.data);
    }
  },
  async save({ commit, dispatch }, payload) {
    if (payload.id) {
      var { status, data } = await apiSurveySmsBlastService.update(payload.data, payload.id);
    } else {
      var { status, data } = await apiSurveySmsBlastService.create(payload.data);
    }
    const message = data.errors ? Object.values(data.errors).join(" ") : data.message;
    dispatch(
      "snackbar/show",
      {
        text: message,
      },
      {
        root: true,
      }
    );
    if (status == 200) {
      commit("SET_SMS_BLAST", data.data[0]);
    }
  },
  async updateSurveySmsBlast({ commit, dispatch }, payload) {
    var { status, data } = await apiSurveySmsBlastService.updateSurveySmsBlast(payload);

    const message = data.errors ? Object.values(data.errors).join(" ") : data.message;
    dispatch(
      "snackbar/show",
      {
        text: message,
      },
      {
        root: true,
      }
    );
  },
  async send({ commit, dispatch }, payload) {
    console.log({ payload })
    const sendMethod = payload.sendMethod;
    // send
    // resend
    // forceSend
    if (sendMethod === 'force-send') {
      sendMethod = 'forceSend'
    }

    let { status, data } = await apiSurveySmsBlastService[sendMethod]({}, payload.id);

    const message = data.errors ? Object.values(data.errors).join(" ") : data.message;
    dispatch(
      "snackbar/show",
      {
        text: message,
      },
      {
        root: true,
      }
    );
  }
};
